import React, { useEffect, useState, useContext } from "react";
import TermsConditionsText from "./TermsConditionsText";
import InputControl from "../InputControl/InputControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setDataToCookiesWithExpirationTime } from "../Cookies/Cookies";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import ParamsContext from "../useContext/paramContext";
import { useNavigate } from "react-router-dom";
import endpoints from "../../config/APIEndpoints.json";
import navigation from "../../config/Navigation.json";
import Constants from "../../config/ProjectConstants.json";
import { faCaretRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { handleScrollToTop } from "../../resuableFunctions/resuableFunctions";
import placeHolderImage from "../../Assets/placeHolderImage.webp";
import BuyerResetPassword from "../../Assets/BuyerResetPassword.webp";
import login_banner from "../../Assets/login_banner.webp";
import loadingImgPath from "../../Assets/rolling.svg";
import Toast from "../ReactToastify/Toast";
import "./login.css";
import "./loginNew.css";
import Navigation from "../../config/Navigation.json";
import logo_p2 from "../../Assets/logo_p2.webp";
import loginBannerImg from "../../Assets/loginBannerImg.png";

import LogInBuyerPageWeb from "../../Assets/Log In_Buyer page_Web View.jpg";
import LogInBuyerPageMobile from "../../Assets/Log In_Buyer page_Mobile.jpg";

import ResetPasswordMOBILE from "../../Assets/ResetPasswordMOBILE.jpg";
import ResetPasswordWEB from "../../Assets/ResetPasswordWEB.jpg";
const validator = require("validator");
const ORG_NAME = process.env.REACT_APP_NAME;
const API_HOST = process.env.REACT_APP_API_HOST;
const loginModelpopupImg = API_HOST + "/app/loginModelpopupImg.webp";
const resetPasswordModalpopupImg =
  API_HOST + "/app/resetPasswordModalpopupImg.webp";

const LoginNewUpdatedModal = (props) => {
  const [maintainanceMode, setMaintainanceMode] = useState(false);
  const { showToast } = Toast();
  const [loginWithOtp, setLoginWithOtp] = useState(false);
  const [showFeatures, setShowFeatures] = useState({
    forgetPassword: true,
    referralCode: true,
  });
  const [webMode, setWebMode] = useState(window.innerWidth > 600);
  useEffect(() => {
    const handleResize = () => {
      // setWindowWidth(window.innerWidth);
      setWebMode(window.innerWidth > 600);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [values, setValues] = useState({
    emailOrPhone: "",
    password: "",
    name: "",
    otp: "",
    new_password: "",
    new_password_confirmation: "",
    referral_code: "",
    showReferral_code: false,
  });
  const navigate = useNavigate();
  const [triggerValidateEmailOrPhone, setTriggerValidateEmailOrPhone] =
    useState(false);
  const [otpToken, setOtpToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showOTP, setShowOTP] = useState(false); // false
  const [forgetPassShowOTP, setForgetPassShowOTP] = useState(false);
  const [showInput, setShowInput] = useState(true); // true
  const [showLogIn, setShowLogIn] = useState(false);
  const [modalTitle, setModalTitle] = useState("Login or Signup");
  const [showSignUp, setShowSignUp] = useState(false);
  const [showForgetPassword, setShowForgetPassword] = useState(false); // false
  const [errorMsgEmail, setErrorMsgEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgLogin, setErrorMsgLogin] = useState("");
  const [errorMsgPassword, setErrorMsgPassword] = useState("");
  const [errorMsgReferralCode, setErrorMsgReferralCode] = useState("");
  const [errorMsgName, setErrorMsgName] = useState("");
  const [errorMsgOTP, setErrorMsgOTP] = useState("");
  const [errorMsgNewPass, setErrorMsgNewPass] = useState("");
  const [errorMsgNewPassCon, setErrorMsgNewPassCon] = useState("");
  const [inputType, setInputType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const contextObj = useContext(ParamsContext);
  const [showResetNewPassword, setShowResetNewPassword] = useState(false);
  const [showResetNewConPassword, setShowResetNewConPassword] = useState(false);

  const handleAddToWishlist = async (productId, token) => {
    const payload = { product_id: productId };
    const addToWishlist = await fetch(
      `${API_HOST}/${endpoints.addToWishList}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const addToWishlistResJson = await addToWishlist.json();

    if (addToWishlistResJson.status === 201) {
      showToast("success", addToWishlistResJson.message);
      contextObj.setInput("productAddedToWishlistId", productId);
      contextObj.setInput("refetchAfterLoginAddWishList", true);
    } else if (addToWishlistResJson.status === 403) {
      showToast("error", addToWishlistResJson.message);
    } else if (addToWishlistResJson.status === 409) {
      showToast("info", addToWishlistResJson.message);
    } else if (addToWishlistResJson.status === 401) {
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${Navigation.navigateToNoRoutesMatched}`);
    } else {
    }
  };

  const handleAddToCart = async (productId, token) => {
    try {
      const payload = {
        product_id: productId,
      };
      const addToCart = await fetch(`${API_HOST}/${endpoints.addToCart}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      const addToCartResJson = await addToCart.json();

      if (addToCartResJson.status == 201 || addToCartResJson.status == 409) {
        if (addToCartResJson.status === 201) {
          contextObj.setInput("cartLength", addToCartResJson.data.total_items);
        }
        if (props.resetFetchDataNow !== undefined) {
          props.resetFetchDataNow(true);
        }
        showToast("success", Constants.alertMessages.alertForAddingInCart);
      } else if (
        addToCartResJson.status == 422 &&
        addToCartResJson.message ==
          Constants.alertMessages.alertForMaxLimitOfCart
      ) {
        showToast("info", Constants.alertMessages.alertForMaxLimitOfCart);
      } else if (addToCartResJson.status === 401) {
        sessionStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("7H3K9R5P2Q");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        contextObj.setInput("isLoggedIn", false);
        navigate(`${Navigation.navigateToHomepage}`);
      }
    } catch (err) {
      console.erroe(err);
    }
  };
  useEffect(() => {
    window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
  }, []);
  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;

    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        forgetPassword: featureFlags.buyer_login_forgotpassword,
        referralCode: featureFlags.buyer_profile_referralcode,
      }));
    }
  }, [contextObj.params.featureFlags]);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };
  // let [forgetPassEmailOrPhone, setForgetPassEmailOrPhone] = useState("");
  const [disabledBtn, setDisabledBtn] = useState({
    loginOrSignUp: false,
    logIn: false,
    signUp: false,
    forgotPassword: false,
    resendOTP: false,
    resetPassword: false,
    requestOTP: false,
    verifyOTP: false,
    requestOTPDeleteAccount: false,
    verifyOTPDeleteAccount: false,
  });
  const isOTPFormat = (otp) => {
    const regex = /^[0-9]+$/;
    return regex.test(otp);
  };
  const isPasswordValid = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_\-+=])[^\s]{6,}$/;
    return passwordRegex.test(password);
  };
  const sanitizeInput = (inputValue) => {
    const sanitizedValue = inputValue.replace(/[^\w\s]/gi, "");

    return sanitizedValue;
  };
  const isValidName = (value) => {
    const regex = /^[a-zA-Z\s]+$/;
    return regex.test(value);
  };
  const CheckSpecialChar = (str) => {
    const regex = /^[a-zA-Z0-9]+$/.test(str);
    return !regex;
  };
  const HandleLoginUser = async () => {
    const payload = {};

    if (inputType == "Email") {
      payload.email = values.emailOrPhone;
    } else {
      payload.phone = values.emailOrPhone;
    }
    payload.password = values.password;
    const response = await fetch(`${API_HOST}/${endpoints.buyerLogin}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();
    setDisabledBtn((prev) => ({
      ...prev,
      loginOrSignUp: false,
      logIn: false,
      signUp: false,
      forgotPassword: false,
      resendOTP: false,
      resetPassword: false,
      requestOTP: false,
      verifyOTP: false,
    }));
    if (
      res.status == 500 ||
      res.status == 501 ||
      res.status == 502 ||
      res.status == 503 ||
      (res.message && res.message.toLowerCase().includes("server error"))
    ) {
      contextObj.setInput("serviceUnavailable", true);
    }
    if (res.status === 200) {
      if (props.updateValueShowPrivacyCookies !== undefined) {
        props.updateValueShowPrivacyCookies(false);
      }
      const expiryDate = new Date();
      expiryDate.setFullYear(expiryDate.getFullYear() + 1);
      setDataToCookiesWithExpirationTime(
        "7H3K9R5P2Q",
        res.data,
        expiryDate,
        "/"
      );
      if (
        contextObj.params.actionAfterLogin !== "" &&
        contextObj.params.productId !== ""
      ) {
        let productId = contextObj.params.productId;
        let action = contextObj.params.actionAfterLogin;
        if (action == "addToCart") {
          await handleAddToCart(productId, res.data);
        } else if (action == "addToWishlist" && productId !== "") {
          await handleAddToWishlist(productId, res.data);
        } else if (action == "buyItNow" && productId !== "") {
          contextObj.setInput("checkoutProducts", [productId]);
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "productDetails");
          navigate("/checkout");
        }
      }

      contextObj.setInput("actionAfterLogin", "");
      contextObj.setInput("productId", "");

      setDataToCookiesWithExpirationTime("isLoggedIn", true, expiryDate, "/");
      handleCloseModal();
      await contextObj.setInput("recentlyViewProducts", null);
      showToast("success", Constants.alertMessages.alertForLogin);
      await contextObj.setInput("isLoggedIn", true);
      // fetchCartData();
      if (contextObj.params.navigateToOrderList == true) {
        navigate("/my-order");
      }
      if (contextObj.params.reDirectToPage == "orders") {
        navigate("/my-order");
      } else if (contextObj.params.reDirectToPage == "userProfile") {
        navigate("/profile-info");
      } else if (contextObj.params.reDirectToPage == "wishlist") {
        navigate("/wish-list");
      } else if (contextObj.params.reDirectToPage == "cart") {
        navigate("/cart");
      }
      contextObj.setInput("reDirectToPage", "");
      handleScrollToTop();
      await contextObj.setInput("reSetFilters", true);
    } else if (res.status === 401) {
      if (inputType == "Email") {
        setErrorMsgPassword(
          Constants.alertMessages.alertForInvalidEmailOrPassword
        );
      } else {
        setErrorMsgPassword(
          Constants.alertMessages.alertForInvalidPhoneOrPassword
        );
      }
    } else if (res.status === 403) {
      setErrorMsgLogin(Constants.alertMessages.alertForAccountBlocked);
    } else {
      setErrorMsgLogin(Constants.alertMessages.alertFoSomethingWrong);
    }
    setLoading(false);
  };

  // login with OTP =======>
  const buyerLoginWithOTPStep1 = async () => {
    setErrorMsgLogin("");
    setValues((prev) => ({
      ...prev,
      password: "",
      name: "",
      otp: "",
      new_password: "",
      new_password_confirmation: "",
      referral_code: "",
      showReferral_code: false,
    }));
    setOtpToken("");
    let payload = {};
    if (inputType == "Email") {
      payload.email = values.emailOrPhone;
    } else {
      payload.phone = values.emailOrPhone;
    }
    payload = JSON.stringify(payload);
    const buyerLoginWithOTPRequestOTP = await fetch(
      `${API_HOST}/${endpoints.buyerLoginWithOTPStep1}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: payload,
      }
    );
    const buyerLoginWithOTPRequestOTPResJson =
      await buyerLoginWithOTPRequestOTP.json();
    if (buyerLoginWithOTPRequestOTPResJson.status == 201) {
      setOtpToken(buyerLoginWithOTPRequestOTPResJson.data);
    }
    if (buyerLoginWithOTPRequestOTPResJson.status == 403) {
      setErrorMsgLogin(Constants.alertMessages.alertForAccountBlocked);
    }
  };
  const buyerLoginWithOTPVerifyOtp = async () => {
    setErrorMsgLogin("");
    setValues((prev) => ({
      ...prev,
      password: "",
      name: "",
      new_password: "",
      new_password_confirmation: "",
      referral_code: "",
      showReferral_code: false,
    }));
    let errorFound = false;
    if (values.emailOrPhone == "") {
      errorFound = true;
    }
    if (values.otp == "" || values.otp.length !== 4) {
      errorFound = true;
      if (values.otp == "") {
        setErrorMsgOTP("OTP is required.");
      } else {
        setErrorMsgOTP("OTP must contain 4 digits.");
      }
    } else {
      let validOTP = isOTPFormat(values.otp);
      if (!validOTP) {
        errorFound = true;
        setErrorMsgOTP("Only numbers are allowed");
      }
    }
    if (otpToken == "") {
      errorFound = true;
    }
    if (errorFound == true) {
      setDisabledBtn((prev) => ({
        ...prev,
        logIn: false,
      }));
      return;
    }
    let payload = {};
    if (inputType == "Email") {
      payload.email = values.emailOrPhone;
    } else {
      payload.phone = values.emailOrPhone;
    }
    payload.otp_verify_token = otpToken;
    payload.otp = values.otp;
    payload = JSON.stringify(payload);
    const buyerLoginWithOTPVerifyOtp = await fetch(
      `${API_HOST}/${endpoints.buyerLoginWithOTPStep2}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: payload,
      }
    );
    const buyerLoginWithOTPVerifyOtpResJson =
      await buyerLoginWithOTPVerifyOtp.json();

    setDisabledBtn((prev) => ({
      ...prev,
      logIn: false,
    }));
    if (buyerLoginWithOTPVerifyOtpResJson.status == 200) {
      const expiryDate = new Date();
      expiryDate.setFullYear(expiryDate.getFullYear() + 1);
      setDataToCookiesWithExpirationTime(
        "7H3K9R5P2Q",
        buyerLoginWithOTPVerifyOtpResJson.data,
        expiryDate,
        "/"
      );
      contextObj.setInput("recentlyViewProducts", null);
      setDataToCookiesWithExpirationTime("isLoggedIn", true, expiryDate, "/");

      handleCloseModal();

      // hideCookieDiv(false);
      if (
        contextObj.params.actionAfterLogin !== "" &&
        contextObj.params.productId !== ""
      ) {
        let productId = contextObj.params.productId;
        let action = contextObj.params.actionAfterLogin;
        if (action == "addToCart") {
          await handleAddToCart(
            productId,
            buyerLoginWithOTPVerifyOtpResJson.data
          );
        } else if (action == "addToWishlist" && productId !== "") {
          await handleAddToWishlist(
            productId,
            buyerLoginWithOTPVerifyOtpResJson.data
          );
        } else if (action == "buyItNow" && productId !== "") {
          contextObj.setInput("checkoutProducts", [productId]);
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "productDetails");
          navigate("/checkout");
        }
      }

      contextObj.setInput("actionAfterLogin", "");
      contextObj.setInput("productId", "");

      showToast("success", Constants.alertMessages.alertForLogin);
      await contextObj.setInput("isLoggedIn", true);
      // fetchCartData();
      if (contextObj.params.reDirectToPage == "orders") {
        navigate("/my-order");
      } else if (contextObj.params.reDirectToPage == "userProfile") {
        navigate("/profile-info");
      } else if (contextObj.params.reDirectToPage == "wishlist") {
        navigate("/wish-list");
      } else if (contextObj.params.reDirectToPage == "cart") {
        navigate("/cart");
      }
      contextObj.setInput("reDirectToPage", "");
      contextObj.setInput("showLoginModel", false);
      handleScrollToTop();
      await contextObj.setInput("reSetFilters", true);
    }
    if (buyerLoginWithOTPVerifyOtpResJson.status == 401) {
      setErrorMsgOTP(buyerLoginWithOTPVerifyOtpResJson.message);
    }
    if (buyerLoginWithOTPVerifyOtpResJson.status == 403) {
      setErrorMsgLogin(Constants.alertMessages.alertForAccountBlocked);
    }
  };
  const buyerLoginWithOTPResendOtp = async () => {
    setValues((prev) => ({
      ...prev,
      password: "",
      name: "",
      otp: "",
      new_password: "",
      new_password_confirmation: "",
      referral_code: "",
      showReferral_code: false,
    }));

    let errorFound = false;
    if (values.emailOrPhone == "") {
      errorFound = true;
    }

    if (otpToken == "") {
      errorFound = true;
    }
    if (errorFound == true) {
      return;
    }
    let payload = {};
    if (inputType == "Email") {
      payload.email = values.emailOrPhone;
    } else {
      payload.phone = values.emailOrPhone;
    }
    payload.otp_verify_token = otpToken;
    payload = JSON.stringify(payload);
    const buyerLoginWithOTPRequestOTP = await fetch(
      `${API_HOST}/${endpoints.buyerLoginWithOTPStep1}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: payload,
      }
    );
    const buyerLoginWithOTPRequestOTPResJson =
      await buyerLoginWithOTPRequestOTP.json();
    if (buyerLoginWithOTPRequestOTPResJson.status == 201) {
      setOtpToken(buyerLoginWithOTPRequestOTPResJson.data);
    }
  };

  // email validation
  const validateEmailOrPhone = async () => {
    if (inputType != "") {
      let payload = {};
      if (inputType == "Email") {
        payload.email = values.emailOrPhone;
      } else if (inputType == "Phone") {
        payload.phone = values.emailOrPhone;
      }
      payload = JSON.stringify(payload);
      const validateRes = await fetch(
        `${API_HOST}/${endpoints.validateEmailPhone}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: payload,
        }
      );
      const validateResJson = await validateRes.json();
      if (
        validateResJson.status == 500 ||
        validateResJson.status == 501 ||
        validateResJson.status == 502 ||
        validateResJson.status == 503 ||
        (validateResJson.message &&
          validateResJson.message.toLowerCase().includes("server error"))
      ) {
        if (validateResJson.status == 503) {
          setMaintainanceMode(true);
        } else {
          setMaintainanceMode(false);
        }
        contextObj.setInput("serviceUnavailable", true);
      } else {
        contextObj.setInput("serviceUnavailable", false);
      }
      setValues((prev) => ({
        ...prev,
        showReferral_code: false,
      }));
      setDisabledBtn((prev) => ({
        ...prev,
        loginOrSignUp: false,
        logIn: false,
        signUp: false,
        forgotPassword: false,
        resendOTP: false,
        resetPassword: false,
        requestOTP: false,
        verifyOTP: false,
      }));
      if (validateResJson.status == 409) {
        setShowInput(false);
        setShowLogIn(true);
        setModalTitle("Login to your account");
        // HandleLoginUser();
      } else if (validateResJson.status == 200) {
        setShowInput(false);
        setShowLogIn(false);
        setShowSignUp(true);
        setModalTitle("Register to Get Started");

        // setShowSetPassword(true);
      } else if (validateResJson.status == 400) {
        setInputType("");
        setValues((prev) => ({
          ...prev,
          password: "",
          name: "",
          otp: "",
        }));
        if (validateResJson.errors.email) {
          setErrorMsgEmail(validateResJson.errors.email[0]);
          return;
        }
        if (validateResJson.errors.phone) {
          setErrorMsgEmail(validateResJson.errors.phone[0]);
          return;
        }
      } else if (validateResJson.status == 404) {
        setErrorMsgEmail(Constants.alertMessages.alertForValidEmail);
        setInputType("");
        setValues((prev) => ({
          ...prev,
          // emailOrPhone: "",
          password: "",
          name: "",
          otp: "",
        }));
      }
    }
  };

  const isPhoneNumber = (phone) => {
    const regex = /^(\+?91[\-\s]?)?[6-9]\d{9}$/.test(phone);
    return regex;
  };

  const handleSubmission = async () => {
    setLoginWithOtp(false);
    setLoading(true);
    setErrorMsg("");
    setErrorMsgEmail("");

    if (values.emailOrPhone == "") {
      setDisabledBtn((prev) => ({
        ...prev,
        loginOrSignUp: false,
        logIn: false,
        signUp: false,
        forgotPassword: false,
        resendOTP: false,
        resetPassword: false,
        requestOTP: false,
        verifyOTP: false,
      }));
      setErrorMsgEmail(Constants.alertMessages.alertForValidPhoneOrEmail);
      setLoading(false);
      return;
    }
    // let checkInputType
    const isEmailValue = validator.isEmail(values.emailOrPhone);
    if (isEmailValue) {
      setInputType("Email");
      setTriggerValidateEmailOrPhone(true);
    }
    let isPhoneNumberValue = false;
    if (values.emailOrPhone.length === 10) {
      isPhoneNumberValue = isPhoneNumber(values.emailOrPhone);
    }
    if (isPhoneNumberValue) {
      setInputType("Phone");
      setTriggerValidateEmailOrPhone(true);
    }
    if (isEmailValue || isPhoneNumberValue) {
      // validateEmailOrPhone();
    } else {
      setErrorMsgEmail(Constants.alertMessages.alertForValidPhoneOrEmail);
      setDisabledBtn((prev) => ({
        ...prev,
        loginOrSignUp: false,
      }));
      return;
    }

    setDisabledBtn((prev) => ({
      ...prev,
      // loginOrSignUp: false,
      logIn: false,
      signUp: false,
      forgotPassword: false,
      resendOTP: false,
      resetPassword: false,
      requestOTP: false,
      verifyOTP: false,
    }));
  };
  useEffect(() => {
    if (triggerValidateEmailOrPhone) {
      setShowOTP(false);
      validateEmailOrPhone();
      setTriggerValidateEmailOrPhone(false);
    }
  }, [inputType, triggerValidateEmailOrPhone]);
  const handlePasswordSubmission = () => {
    if (values.password == "") {
      setDisabledBtn((prev) => ({
        ...prev,
        logIn: false,
      }));
      setErrorMsgPassword(Constants.alertMessages.alertForPasswordRequired);
      return;
    } else {
      HandleLoginUser();
    }
  };
  //

  const handleRequestOTP = async () => {
    if (values.name.trim() == "" && values.password.trim() == "") {
      setErrorMsgName(Constants.alertMessages.alertForName);
      setErrorMsgPassword(Constants.alertMessages.alertForPasswordRequired);
      setDisabledBtn((prev) => ({
        ...prev,
        loginOrSignUp: false,
        logIn: false,
        signUp: false,
        forgotPassword: false,
        resendOTP: false,
        resetPassword: false,
        requestOTP: false,
        verifyOTP: false,
      }));
      return;
    } else if (values.name.trim() == "") {
      setDisabledBtn((prev) => ({
        ...prev,
        loginOrSignUp: false,
        logIn: false,
        signUp: false,
        forgotPassword: false,
        resendOTP: false,
        resetPassword: false,
        requestOTP: false,
        verifyOTP: false,
      }));
      return setErrorMsgName(Constants.alertMessages.alertForName);
    } else if (
      values.name.trim() !== "" &&
      (values.name.trim().length < 2 || values.name.trim().length > 50)
    ) {
      setDisabledBtn((prev) => ({
        ...prev,
        loginOrSignUp: false,
        logIn: false,
        signUp: false,
        forgotPassword: false,
        resendOTP: false,
        resetPassword: false,
        requestOTP: false,
        verifyOTP: false,
      }));
      return setErrorMsgName(Constants.alertMessages.alertForMinName);
    } else if (
      values.password.trim() !== "" &&
      (values.password.trim().length < 8 || values.password.trim().length > 16)
    ) {
      setDisabledBtn((prev) => ({
        ...prev,
        loginOrSignUp: false,
        logIn: false,
        signUp: false,
        forgotPassword: false,
        resendOTP: false,
        resetPassword: false,
        requestOTP: false,
        verifyOTP: false,
      }));
      return setErrorMsgPassword(
        Constants.alertMessages.alertForPasswordLength
      );
    } else if (values.password.trim() == "") {
      setDisabledBtn((prev) => ({
        ...prev,
        loginOrSignUp: false,
        logIn: false,
        signUp: false,
        forgotPassword: false,
        resendOTP: false,
        resetPassword: false,
        requestOTP: false,
        verifyOTP: false,
      }));
      return setErrorMsgPassword(
        Constants.alertMessages.alertForPasswordRequired
      );
    } else if (
      values.name.trim().length < 2 ||
      values.name.trim().length > 50
    ) {
      setDisabledBtn((prev) => ({
        ...prev,
        loginOrSignUp: false,
        logIn: false,
        signUp: false,
        forgotPassword: false,
        resendOTP: false,
        resetPassword: false,
        requestOTP: false,
        verifyOTP: false,
      }));
      return setErrorMsgName(Constants.alertMessages.alertForMinName);
    }

    const validPassword = isPasswordValid(values.password.trim());
    if (!validPassword) {
      setDisabledBtn((prev) => ({
        ...prev,
        loginOrSignUp: false,
        logIn: false,
        signUp: false,
        forgotPassword: false,
        resendOTP: false,
        resetPassword: false,
        requestOTP: false,
        verifyOTP: false,
      }));
      return setErrorMsgPassword(
        Constants.alertMessages.alertForPasswordNotMatchingCriteria
      );
    }

    const payload = {
      name: values.name,
      password: values.password,
      referral_code: values.referral_code,
    };
    if (inputType == "Email") {
      payload.email = values.emailOrPhone;
    } else if (inputType == "Phone") {
      payload.phone = values.emailOrPhone;
    }
    const requestOTPResponse = await fetch(
      `${API_HOST}/${endpoints.buyerRegisterStep1}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const requestOTPResJson = await requestOTPResponse.json();
    if (
      requestOTPResJson.status == 500 ||
      requestOTPResJson.status == 501 ||
      requestOTPResJson.status == 502 ||
      requestOTPResJson.status == 503 ||
      (requestOTPResJson.message &&
        requestOTPResJson.message.toLowerCase().includes("server error"))
    ) {
      if (requestOTPResJson.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    setDisabledBtn((prev) => ({
      ...prev,
      loginOrSignUp: false,
      logIn: false,
      signUp: false,
      forgotPassword: false,
      resendOTP: false,
      resetPassword: false,
      requestOTP: false,
      verifyOTP: false,
    }));
    if (requestOTPResJson.status == 201) {
      setOtpToken(requestOTPResJson.data);

      setShowSignUp(false);
      setShowOTP(true);
      startTimer();
    }
    if (requestOTPResJson.status == 404 || requestOTPResJson.status == 429) {
      setErrorMsg(requestOTPResJson.message);
    }
    if (requestOTPResJson.status == 400) {
      if (requestOTPResJson.errors && requestOTPResJson.errors.referral_code) {
        setErrorMsgReferralCode(requestOTPResJson.errors.referral_code[0]);
      }
    }
  };
  // Set initial state to open the modal
  const handleOTPVerification = async () => {
    if (values.otp == "") {
      setDisabledBtn((prev) => ({
        ...prev,
        loginOrSignUp: false,
        logIn: false,
        signUp: false,
        forgotPassword: false,
        resendOTP: false,
        resetPassword: false,
        requestOTP: false,
        verifyOTP: false,
      }));
      return setErrorMsgOTP(Constants.alertMessages.AlertForOtp);
    }
    const payload = {
      otp_verify_token: otpToken,
      otp: values.otp,
    };
    if (inputType == "Email") {
      payload.email = values.emailOrPhone;
    } else if (inputType == "Phone") {
      payload.phone = values.emailOrPhone;
    }
    const verifyOTPResponse = await fetch(
      `${API_HOST}/${endpoints.buyerRegisterStep2}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const verifyOTPResJson = await verifyOTPResponse.json();
    if (
      verifyOTPResJson.status == 500 ||
      verifyOTPResJson.status == 501 ||
      verifyOTPResJson.status == 502 ||
      verifyOTPResJson.status == 503 ||
      (verifyOTPResJson.message &&
        verifyOTPResJson.message.toLowerCase().includes("server error"))
    ) {
      if (verifyOTPResJson.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    setDisabledBtn((prev) => ({
      ...prev,
      loginOrSignUp: false,
      logIn: false,
      signUp: false,
      forgotPassword: false,
      resendOTP: false,
      resetPassword: false,
      requestOTP: false,
      verifyOTP: false,
    }));
    if (verifyOTPResJson.status == 201) {
      contextObj.setInput("isLoggedIn", true);
      contextObj.setInput("recentlyViewProducts", null);
      const expiryDate = new Date();
      expiryDate.setFullYear(expiryDate.getFullYear() + 1);
      setDataToCookiesWithExpirationTime(
        "7H3K9R5P2Q",
        verifyOTPResJson.data,
        expiryDate,
        "/"
      );
      if (
        contextObj.params.actionAfterLogin !== "" &&
        contextObj.params.productId !== ""
      ) {
        let productId = contextObj.params.productId;
        let action = contextObj.params.actionAfterLogin;
        if (action == "addToCart") {
          await handleAddToCart(productId, verifyOTPResJson.data);
        } else if (action == "addToWishlist" && productId !== "") {
          await handleAddToWishlist(productId, verifyOTPResJson.data);
        } else if (action == "buyItNow" && productId !== "") {
          contextObj.setInput("checkoutProducts", [productId]);
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "productDetails");
          navigate("/checkout");
        }
      }

      contextObj.setInput("actionAfterLogin", "");
      contextObj.setInput("productId", "");

      setDataToCookiesWithExpirationTime("isLoggedIn", true, expiryDate, "/");
      showToast("success", Constants.alertMessages.alertForAccountCreation);
      // fetchCartData();
      if (contextObj.params.reDirectToPage == "orders") {
        navigate("/my-order");
      } else if (contextObj.params.reDirectToPage == "userProfile") {
        navigate("/profile-info");
      } else if (contextObj.params.reDirectToPage == "wishlist") {
        navigate("/wish-list");
      } else if (contextObj.params.reDirectToPage == "cart") {
        navigate("/cart");
      }
      contextObj.setInput("reDirectToPage", "");
      handleCloseModal();
      handleScrollToTop();

      await contextObj.setInput("reSetFilters", true);
    }
    if (verifyOTPResJson.status == 404 || verifyOTPResJson.status == 401) {
      // return setErrorMsgOTP("Invalid OTP");
      return setErrorMsgOTP(verifyOTPResJson.message);
    } else {
      setValues((prev) => ({
        ...prev,
        emailOrPhone: "",
        password: "",
        name: "",
        otp: "",
      }));
    }
  };

  const sendForgetPasswordOTP = async () => {
    if (values.emailOrPhone !== "") {
      setValues((prev) => ({
        ...prev,
        password: "",
        otp: "",
        name: "",
      }));
      const validPhone = isPhoneNumber(values.emailOrPhone);
      const isEmailValue = validator.isEmail(values.emailOrPhone);
      setErrorMsgOTP("");
      setErrorMsgNewPass("");
      setErrorMsgNewPassCon("");
      if (!validPhone && !isEmailValue) {
        setDisabledBtn((prev) => ({
          ...prev,
          requestOTP: false,
        }));
        return setErrorMsgEmail(
          Constants.alertMessages.alertForValidPhoneOrEmail
        );
      }

      const payload = {};
      if (isEmailValue) {
        payload.email = values.emailOrPhone;
        setInputType("Email");
      } else if (validPhone) {
        payload.phone = values.emailOrPhone;
        setInputType("Phone");
      }
      const forgetPassOTPResponse = await fetch(
        `${API_HOST}/${endpoints.buyerForgetPassStep1}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const forgetPassOTPResJson = await forgetPassOTPResponse.json();
      if (
        forgetPassOTPResJson.status == 500 ||
        forgetPassOTPResJson.status == 501 ||
        forgetPassOTPResJson.status == 502 ||
        forgetPassOTPResJson.status == 503 ||
        (forgetPassOTPResJson.message &&
          forgetPassOTPResJson.message.toLowerCase().includes("server error"))
      ) {
        if (forgetPassOTPResJson.status == 503) {
          setMaintainanceMode(true);
        } else {
          setMaintainanceMode(false);
        }
        contextObj.setInput("serviceUnavailable", true);
      } else {
        contextObj.setInput("serviceUnavailable", false);
      }
      setDisabledBtn((prev) => ({
        ...prev,
        loginOrSignUp: false,
        logIn: false,
        signUp: false,
        forgotPassword: false,
        resendOTP: false,
        resetPassword: false,
        requestOTP: false,
        verifyOTP: false,
      }));
      if (forgetPassOTPResJson.status == 201) {
        resetTimer();
        setOtpToken(forgetPassOTPResJson.data);
        startTimer();
        setForgetPassShowOTP(true);
      }
      if (forgetPassOTPResJson.status == 429) {
        setErrorMsgEmail(forgetPassOTPResJson.message);
      }

      if (forgetPassOTPResJson.status == 404) {
        setErrorMsgEmail(forgetPassOTPResJson.message);
        if (validPhone) {
          setErrorMsgEmail(Constants.alertMessages.alertForNoAccountFound);
        } else if (isEmailValue) {
          setErrorMsgEmail(
            Constants.alertMessages.alertForNoAccountFoundByEmail
          );
        }
      }
      if (forgetPassOTPResJson.status == 403) {
        setErrorMsgEmail(forgetPassOTPResJson.message);
      }
      if (forgetPassOTPResJson.status == 400) {
        if (forgetPassOTPResJson.errors.email) {
          setErrorMsgEmail(forgetPassOTPResJson.errors.email[0]);
          return;
        }
        if (forgetPassOTPResJson.errors.phone) {
          setErrorMsgEmail(forgetPassOTPResJson.errors.phone[0]);
          return;
        }
      }
    } else {
      setDisabledBtn((prev) => ({
        ...prev,
        loginOrSignUp: false,
        logIn: false,
        signUp: false,
        forgotPassword: false,
        resendOTP: false,
        resetPassword: false,
        requestOTP: false,
        verifyOTP: false,
      }));
      return setErrorMsgEmail(
        Constants.alertMessages.alertForValidPhoneOrEmail
      );
    }
  };
  const verifyForgetPasswordOTP = async () => {
    if (inputType !== "") {
      const payload = {
        otp: values.otp,
        otp_verify_token: otpToken,
        new_password: values.new_password,
        new_password_confirmation: values.new_password_confirmation,
      };
      if (inputType == "Email") {
        payload.email = values.emailOrPhone;
      } else if (inputType == "Phone") {
        payload.phone = values.emailOrPhone;
      }
      let errorFound = false;
      if (payload.otp_verify_token == "") {
        setDisabledBtn((prev) => ({
          ...prev,
          loginOrSignUp: false,
          logIn: false,
          signUp: false,
          forgotPassword: false,
          resendOTP: false,
          resetPassword: false,
          requestOTP: false,
          verifyOTP: false,
        }));

        showToast("info", Constants.alertMessages.alertForOtpToken);
        return;
      }

      if (payload.otp == "") {
        setErrorMsgOTP(Constants.alertMessages.AlertForOtp);
        errorFound = true;
      }
      if (payload.otp.length < 4) {
        setErrorMsgOTP("Invalid OTP");
        errorFound = true;
      }
      const validFormat = isOTPFormat(payload.otp);
      if (!validFormat) {
        setErrorMsgOTP(Constants.alertMessages.alertForValidOtp);
        errorFound = true;
      }

      if (
        payload.new_password !== payload.new_password_confirmation &&
        payload.new_password !== "" &&
        payload.new_password_confirmation !== ""
      ) {
        setErrorMsgNewPassCon(Constants.alertMessages.alertForPasswrdNotMatch);
        errorFound = true;
      }
      if (payload.new_password.length < 8 || payload.new_password.length > 16) {
        setErrorMsgNewPass(Constants.alertMessages.alertForPasswordLength);
        errorFound = true;
      }
      if (payload.new_password == "") {
        setErrorMsgNewPass(Constants.alertMessages.alertForNewPassword);
        errorFound = true;
      }
      if (payload.new_password_confirmation == "") {
        setErrorMsgNewPassCon(
          Constants.alertMessages.alertForConfirmNewPassword
        );
        errorFound = true;
      }

      // =======>
      if (
        payload.new_password !== "" &&
        payload.new_password == payload.new_password_confirmation &&
        payload.new_password.length < 6
      ) {
        setErrorMsgNewPass(Constants.alertMessages.alertForPasswordCriteria);
        errorFound = true;
      }
      const validNewPassword = isPasswordValid(payload.new_password);
      const validConfimPassword = isPasswordValid(
        payload.new_password_confirmation
      );
      if (!validNewPassword && payload.new_password !== "") {
        setErrorMsgNewPass(Constants.alertMessages.alertForPasswordRequirement);
        errorFound = true;
      }
      if (!validConfimPassword && payload.new_password_confirmation !== "") {
        setErrorMsgNewPassCon(
          Constants.alertMessages.alertForPasswordRequirement
        );
        errorFound = true;
      }
      if (
        payload.new_password !== payload.new_password_confirmation &&
        payload.new_password !== "" &&
        payload.new_password_confirmation !== ""
      ) {
        setErrorMsgNewPassCon(Constants.alertMessages.alertForPasswordNotMatch);
        errorFound = true;
      }
      if (errorFound == true) {
        setDisabledBtn((prev) => ({
          ...prev,
          loginOrSignUp: false,
          logIn: false,
          signUp: false,
          forgotPassword: false,
          resendOTP: false,
          resetPassword: false,
          requestOTP: false,
          verifyOTP: false,
        }));
        return;
      }
      const verifyForgetPassOTPResponse = await fetch(
        `${API_HOST}/${endpoints.buyerForgetPassStep2}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const verifyForgetPassOTPResJson =
        await verifyForgetPassOTPResponse.json();
      setDisabledBtn((prev) => ({
        ...prev,
        loginOrSignUp: false,
        logIn: false,
        signUp: false,
        forgotPassword: false,
        resendOTP: false,
        resetPassword: false,
        requestOTP: false,
        verifyOTP: false,
      }));
      if (
        verifyForgetPassOTPResJson.status == 500 ||
        verifyForgetPassOTPResJson.status == 501 ||
        verifyForgetPassOTPResJson.status == 502 ||
        verifyForgetPassOTPResJson.status == 503 ||
        verifyForgetPassOTPResJson.message
          .toLowerCase()
          .includes("server error")
      ) {
        if (verifyForgetPassOTPResJson.status == 503) {
          setMaintainanceMode(true);
        } else {
          setMaintainanceMode(false);
        }
        contextObj.setInput("serviceUnavailable", true);
      } else {
        contextObj.setInput("serviceUnavailable", false);
      }
      if (verifyForgetPassOTPResJson.status == 200) {
        showToast("success", Constants.alertMessages.alertForPasswodUpdate);
        setValues((prev) => ({
          ...prev,
          emailOrPhone: "",
        }));
        handleResetModel();
      } else if (verifyForgetPassOTPResJson.status == 401) {
        setErrorMsgOTP(verifyForgetPassOTPResJson.message);
      } else if (verifyForgetPassOTPResJson.status == 409) {
        if (
          verifyForgetPassOTPResJson.message ==
          Constants.alertMessages.alertForCreatingNewPassword
        ) {
          return setErrorMsgNewPassCon(
            Constants.alertMessages.alertForCreatingNewPassword
          );
        }
      } else {
        if (verifyForgetPassOTPResJson.message == "Invalid otp") {
          return setErrorMsgOTP(Constants.alertMessages.alertForValidOtp);
        }
      }
    } else {
      setDisabledBtn((prev) => ({
        ...prev,
        resetPassword: false,
      }));

      showToast("info", Constants.alertMessages.alertForInputType);
    }
  };
  const handleCloseModal = (e) => {
    contextObj.setInput("showLoginModel", false);
    contextObj.setInput("reDirectToPage", "");
    setValues((prev) => ({
      ...prev,
      emailOrPhone: "",
    }));
    handleResetModel();
    setIsModalOpen(false);
  };

  const handleResetModel = () => {
    setValues((prev) => ({
      ...prev,
      password: "",
      otp: "",
      name: "",
    }));
    setInputType("");
    setShowOTP(false);
    setShowPassword(false);
    setShowInput(true);
    setShowLogIn(false);
    setShowSignUp(false);
    setErrorMsgEmail("");
    setErrorMsg("");
    setErrorMsgPassword("");
    setErrorMsgName("");
    setErrorMsgOTP("");
    setShowForgetPassword(false);
    setErrorMsgLogin("");
    setLoading(false);
    setDisabledBtn((prev) => ({
      ...prev,
      loginOrSignUp: false,
      logIn: false,
      signUp: false,
      forgotPassword: false,
      resendOTP: false,
      resetPassword: false,
      requestOTP: false,
      verifyOTP: false,
    }));
    setModalTitle("Login or Signup");
  };
  const resendForgotPasswordOTP = async () => {
    setErrorMsgOTP("");
    const payload = {
      otp_verify_token: otpToken,
    };
    if (inputType == "Email") {
      payload.email = values.emailOrPhone;
    } else if (inputType == "Phone") {
      payload.phone = values.emailOrPhone;
    }
    const response = await fetch(
      `${API_HOST}/${endpoints.logInPageForgetPassResendOTP}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const resendOtpRes = await response.json();
    if (
      resendOtpRes.status == 500 ||
      resendOtpRes.status == 501 ||
      resendOtpRes.status == 502 ||
      resendOtpRes.status == 503 ||
      (resendOtpRes.message &&
        resendOtpRes.message.toLowerCase().includes("server error"))
    ) {
      if (resendOtpRes.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }

    if (resendOtpRes.message && resendOtpRes.status == 201) {
      setOtpToken(resendOtpRes.data);
      setIsActive(true);
    } else {
      if (resendOtpRes.message) {
        setErrorMsgOTP(resendOtpRes.message);
      }
    }
  };

  const resendOTP = async () => {
    setErrorMsgOTP("");
    const payload = {
      otp_verify_token: otpToken,
    };
    if (inputType == "Email") {
      payload.email = values.emailOrPhone;
    } else if (inputType == "Phone") {
      payload.phone = values.emailOrPhone;
    }
    const response = await fetch(
      `${API_HOST}/${endpoints.buyerRegisterResendOTP}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const resendOtpRes = await response.json();
    setDisabledBtn((prev) => ({
      ...prev,
      loginOrSignUp: false,
      logIn: false,
      signUp: false,
      forgotPassword: false,
      resendOTP: false,
      resetPassword: false,
      requestOTP: false,
      verifyOTP: false,
    }));
    if (
      resendOtpRes.status == 500 ||
      resendOtpRes.status == 501 ||
      resendOtpRes.status == 502 ||
      resendOtpRes.status == 503 ||
      (resendOtpRes.message &&
        resendOtpRes.message.toLowerCase().includes("server error"))
    ) {
      if (resendOtpRes.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    if (resendOtpRes.status == 201) {
      setIsActive(false);
      setSeconds(60);
      setotpSent(true);
      setIsActive(true);
      setOtpToken(resendOtpRes.data);
    }

    setValues((prev) => ({
      ...prev,
      otp: "",
    }));
    if (resendOtpRes.status == 429) {
      showToast("error", resendOtpRes.message);
    }
  };

  const handleKeyPressContinue = (event) => {
    if (event.key === "Enter" || event.key === 13) {
      event.preventDefault();
      if (
        // !isActive &&
        !disabledBtn.loginOrSignUp
      ) {
        setDisabledBtn((prev) => ({
          ...prev,
          loginOrSignUp: true,
        }));
        handleSubmission();
      }
    }
  };
  const handleKeyPressLogin = (event) => {
    if (event.key === "Enter" || event.key === 13) {
      event.preventDefault();
      if (
        // !isActive &&
        !disabledBtn.logIn
      ) {
        setDisabledBtn((prev) => ({
          ...prev,
          logIn: true,
        }));
        if (loginWithOtp) {
          buyerLoginWithOTPVerifyOtp();
        } else {
          handlePasswordSubmission();
        }
      }
    }
  };
  const handleKeyPressRequestOTP = (event) => {
    if (event.key === "Enter" || event.key === 13) {
      event.preventDefault();
      if (!isActive && !disabledBtn.requestOTP) {
        setDisabledBtn((prev) => ({
          ...prev,
          requestOTP: true,
        }));
        handleRequestOTP();
      }
    }
  };
  const handleKeyPressVerifyOTP = (event) => {
    if (event.key === "Enter" || event.key === 13) {
      event.preventDefault();
      if (
        // !isActive &&
        !disabledBtn.verifyOTP
      ) {
        setDisabledBtn((prev) => ({
          ...prev,
          verifyOTP: true,
        }));
        handleOTPVerification();
      }
    }
  };

  useEffect(() => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden"; // Disable scroll when the modal is open
    document.body.style.WebkitOverflowScrolling = "hidden"; // Enable scroll when the modal is closed

    return () => {
      document.body.style.overflow = "auto"; // Enable scroll when the modal is closed
      document.body.style.WebkitOverflowScrolling = "scroll"; // Enable scroll when the modal is closed
    };
  }, []);

  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(false);
  const [otpSent, setotpSent] = useState(false);

  useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      resetTimer();
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const startTimer = () => {
    setIsActive(true);
  };

  const resetTimer = () => {
    setIsActive(false);
    setSeconds(60);
  };

  return (
    <>
      {isModalOpen && (
        <div className="Login_ModalContainer mobile_modal_Login_ModalContainer">
          <div className="Login_ModalContent">
            {/* <div className="mobileViewOnly ">
              <div className="modelImageContainerbox">
                <img
                  src={showForgetPassword ? BuyerResetPassword : login_banner}
                  alt="Image"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </div> */}
            <div
              className={
                "BuyerLoginPage-innerBox-expanded mobile_modal_BuyerLoginPage-innerBox-expanded"
              }
            >
              <div
                className="mobileViewOnly loginMobilePageNavbar"
                id="loginMobilePageNavbar"
              >
                <div
                  className="navbar__logo mobile_modal_navbar__logo"
                  style={{
                    position: "static",
                    marginTop: "4px",
                  }}
                  onClick={() => {
                    sessionStorage.removeItem("multiFilters");
                    navigate(`${navigation.navigateToHomepage}`);
                    window.location.reload();
                  }}
                >
                  {/* <h3 className="orgName">{`${ORG_NAME}`}</h3> */}
                  <img
                    src={logo_p2}
                    alt="logo"
                    style={{
                      width: "70px",
                    }}
                  />
                </div>

                {/* modal close icon */}
                <div
                  className="crossbuttonNavbar mobileViewOnly"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleCloseModal();
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} size="lg" color="#626262" />
                </div>
              </div>
              <div className="modelImageContainer">
                {/* <img
                  src={showForgetPassword ? BuyerResetPassword : login_banner}
                  alt="Image"
                  style={{
                    // width: "100%",
                    // height: "100%",
                    width: "450px",
                    height: "500px"
                  }}
                /> */}
                <img
                  src={
                    showForgetPassword
                      ? window.innerWidth > 600
                        ? ResetPasswordWEB
                        : ResetPasswordMOBILE
                      : window.innerWidth > 600
                      ? LogInBuyerPageWeb
                      : LogInBuyerPageMobile
                  }
                  alt="Image"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>

              <div className="mobileViewOnly LoginBannerImgContainer">
                {/* <img
                  src={showForgetPassword ? BuyerResetPassword : login_banner}
                  alt="Image"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                /> */}
                {/* <img
                  src={loginBannerImg}
                  alt="Image"
                  style={{
                    width: "100%",
                    
                  }}
                /> */}
                <img
                  src={
                    showForgetPassword
                      ? window.innerWidth > 600
                        ? ResetPasswordWEB
                        : ResetPasswordMOBILE
                      : window.innerWidth > 600
                      ? LogInBuyerPageWeb
                      : LogInBuyerPageMobile
                  }
                  alt="Image"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>

              <div
                className={
                  "modelInputFieldContainer mobile_modal_modelInputFieldContainer"
                }
              >
                <button
                  className="CloseLoginModal login-cross-icons"
                  onClick={handleCloseModal}
                  id="CloseLoginModalPop"
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="cross-icon"
                    size="lg"
                  />
                </button>

                {showInput && (
                  <div className="containerBox mobile_modal_containerBox">
                    <div className="mobileViewOnly mobileViewModalTitleContainer mobile_modal_mobileViewModalTitleContainer">
                      <p className="mobileViewModalTitle mobile_modal_mobileViewModalTitle">
                        {modalTitle}
                      </p>
                    </div>
                    <InputControl
                      inputTagId="loginSignupInputTag"
                      label={
                        <span className="inputTagLabel">
                          Email Address or Phone Number
                          <span className="required">*</span>
                        </span>
                      }
                      type="text"
                      autoFocus
                      style={{
                        color: "#808080",
                      }}
                      value={values.emailOrPhone}
                      onKeyPress={handleKeyPressContinue}
                      onChange={(event) => {
                        if (!disabledBtn.loginOrSignUp) {
                          setValues((prev) => ({
                            ...prev,
                            emailOrPhone: event.target.value.trim(),
                          }));
                          setErrorMsgEmail("");
                          setErrorMsg("");
                        }
                      }}
                      error={errorMsgEmail}
                    />{" "}
                    <div className="BuyerLoginPage-footer BuyerLoginPage-footer-LoginResetPass-Mobile mobile_modal_BuyerLoginPage-footer-LoginResetPass-Mobile">
                      <button
                        className="tabFocus"
                        id="loginSignupContinueBtn"
                        onClick={() => {
                          //   handleForceRerender();
                          if (
                            // !isActive &&
                            !disabledBtn.loginOrSignUp
                          ) {
                            setDisabledBtn((prev) => ({
                              ...prev,
                              loginOrSignUp: true,
                            }));
                            handleSubmission();
                          }
                        }}
                      >
                        {disabledBtn.loginOrSignUp ? (
                          <img
                            src={loadingImgPath}
                            alt="Loading"
                            className="disabledBtn-Loading"
                          />
                        ) : (
                          "Continue"
                        )}
                      </button>
                    </div>
                  </div>
                )}
                {showLogIn && (
                  <div className="containerBox mobile_modal_containerBox">
                    <div className="mobileViewOnly mobileViewModalTitleContainer mobile_modal_mobileViewModalTitleContainer">
                      <p className="mobileViewModalTitle mobile_modal_mobileViewModalTitle">
                        {modalTitle}
                      </p>
                    </div>
                    <div className="password-line">
                      <InputControl
                        inputTagId="loginReadOnlyInputTag"
                        label={
                          <span className="inputTagLabel">
                            Email Address or Phone Number
                            <span className="required">*</span>
                          </span>
                        }
                        type="text"
                        value={values.emailOrPhone}
                        onKeyPress={handleKeyPressLogin}
                        readOnly
                      />{" "}
                      <button
                        className="password-eye-icons login-cross-icons"
                        onClick={handleResetModel}
                      >
                        {/* Edit */}
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 22 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.69568 20.3251C3.01045 20.3251 2.37016 20.0891 1.9096 19.651C1.32548 19.1006 1.04465 18.2693 1.14575 17.3707L1.56137 13.7311C1.64001 13.0459 2.05563 12.136 2.53866 11.6418L11.7611 1.88019C14.0639 -0.557414 16.4678 -0.624813 18.9054 1.67799C21.343 3.98079 21.4104 6.38469 19.1076 8.82229L9.88517 18.5839C9.41338 19.0894 8.53718 19.5612 7.85195 19.6736L4.23487 20.2913C4.04391 20.3025 3.87541 20.3251 3.69568 20.3251ZM15.367 1.66676C14.502 1.66676 13.7494 2.20595 12.9855 3.01474L3.76308 12.7877C3.53841 13.0236 3.28005 13.5852 3.23512 13.911L2.81949 17.5504C2.77456 17.9211 2.86442 18.2244 3.06662 18.4154C3.26882 18.6063 3.57211 18.6737 3.94281 18.6176L7.55989 17.9999C7.88565 17.9437 8.42485 17.6515 8.64951 17.4156L17.872 7.65404C19.2649 6.17126 19.7704 4.80082 17.7372 2.89117C16.8385 2.02622 16.0634 1.66676 15.367 1.66676Z"
                            fill="#CDCDCD"
                          />
                          <path
                            d="M16.9512 10.698C16.9287 10.698 16.895 10.698 16.8726 10.698C13.3678 10.3497 10.5483 7.68753 10.0091 4.20525C9.94169 3.74468 10.2562 3.31782 10.7168 3.23919C11.1773 3.17179 11.6042 3.48632 11.6828 3.94688C12.1097 6.66531 14.3114 8.75468 17.0523 9.02428C17.5129 9.06921 17.8499 9.48484 17.8049 9.94534C17.7488 10.3722 17.3781 10.698 16.9512 10.698Z"
                            fill="#CDCDCD"
                          />
                          <path
                            d="M21.0622 23.9533H0.842489C0.381928 23.9533 0 23.5714 0 23.1108C0 22.6502 0.381928 22.2683 0.842489 22.2683H21.0622C21.5228 22.2683 21.9047 22.6502 21.9047 23.1108C21.9047 23.5714 21.5228 23.9533 21.0622 23.9533Z"
                            fill="#CDCDCD"
                          />
                        </svg>
                      </button>
                    </div>

                    {/* login password line - password/otp */}
                    <div className="password-line">
                      {loginWithOtp ? (
                        <>
                          {/* login with otp */}
                          <InputControl
                            id="loginOTPInputTag"
                            inputTagId="loginOTPInputTag"
                            label={
                              <span className="inputTagLabel">
                                OTP
                                <span className="required">*</span>
                              </span>
                            }
                            autoFocus
                            value={values.otp}
                            maxLength={4}
                            type="text"
                            onKeyPress={handleKeyPressLogin}
                            onChange={(event) => {
                              setErrorMsgOTP("");
                              setErrorMsgLogin("");

                              let validOTP = isOTPFormat(event.target.value);
                              if (!validOTP && event.target.value !== "") {
                                setErrorMsgOTP("Only numbers are allowed");
                              } else {
                                setValues((prev) => ({
                                  ...prev,
                                  otp: event.target.value.trim(),
                                }));
                              }
                            }}
                            // placeholder="Password"
                            error={errorMsgOTP}
                          />

                          <span
                            id="BuyerLoginResendOTPBtn"
                            onClick={async () => {
                              if (!isActive) {
                                setOtpToken("");
                                setValues((prev) => ({
                                  ...prev,
                                  otp: "",
                                }));
                                startTimer();
                                setErrorMsgLogin("");
                                await buyerLoginWithOTPResendOtp();
                              }
                            }}
                          >
                            <button
                              className="BuyerLogin-SignUpBtn1 tabIndexBtn"
                              style={{
                                color: "#3366cc",
                                cursor: isActive ? "default" : "pointer",
                              }}
                            >
                              {isActive
                                ? `Resend OTP in ${formatTime(seconds)}`
                                : "Resend OTP"}
                            </button>
                          </span>
                        </>
                      ) : (
                        <>
                          {/* login with password */}
                          <InputControl
                            id="loginPasswordInputTag"
                            inputTagId="loginPasswordInputTag"
                            label={
                              <span
                                className="inputTagLabel"
                                style={{
                                  color: "#b1b1b1",
                                }}
                              >
                                Password
                                <span className="required">*</span>
                              </span>
                            }
                            autoFocus
                            value={values.password}
                            maxLength={16}
                            style={{
                              color: "#808080",
                            }}
                            type="password"
                            onKeyPress={handleKeyPressLogin}
                            onChange={(event) => {
                              if (!disabledBtn.logIn) {
                                setValues((prev) => ({
                                  ...prev,
                                  password: event.target.value.trim(),
                                }));
                                setErrorMsgPassword("");
                                setErrorMsgLogin("");
                              }
                            }}
                            // placeholder="Password"
                            error={errorMsgPassword}
                          />
                          <div className="password-eye-icons-password">
                            {showPassword ? (
                              <FontAwesomeIcon
                                icon={faEye}
                                className="fontassowmicon"
                                style={{ color: "#CDCDCD" }}
                                onClick={() => {
                                  setShowPassword(false);
                                  const x = document.getElementById(
                                    "loginPasswordInputTag"
                                  );
                                  x.type = "password";
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    setShowPassword(false);
                                    const x = document.getElementById(
                                      "loginPasswordInputTag"
                                    );
                                    x.type = "password";
                                  }
                                }}
                                tabindex="0"
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="fontassowmicon"
                                icon={faEyeSlash}
                                style={{ color: "#CDCDCD" }}
                                onClick={() => {
                                  setShowPassword(true);
                                  const x = document.getElementById(
                                    "loginPasswordInputTag"
                                  );
                                  x.type = "text";
                                }}
                                tabIndex={0}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    setShowPassword(true);
                                    const x = document.getElementById(
                                      "loginPasswordInputTag"
                                    );
                                    x.type = "text";
                                  }
                                }}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="BuyerLoginPage-footer">
                      <p className="BuyerLoginPage-error">{errorMsgLogin}</p>

                      <button
                        className="tabFocus"
                        id="loginModelLoginBTN"
                        onClick={() => {
                          if (!disabledBtn.logIn) {
                            setDisabledBtn((prev) => ({
                              ...prev,
                              logIn: true,
                            }));
                            if (loginWithOtp) {
                              buyerLoginWithOTPVerifyOtp();
                            } else {
                              handlePasswordSubmission();
                            }
                          }
                        }}
                      >
                        {disabledBtn.logIn ? (
                          <img
                            src={loadingImgPath}
                            alt="Loading"
                            className="disabledBtn-Loading"
                          />
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>

                    {/* div to show login with options */}
                    <div className="loginModalForgotPasswordHolder">
                      {loginWithOtp ? (
                        <button
                          onClick={() => {
                            setLoginWithOtp(false);
                            setValues((prev) => ({
                              ...prev,
                              otp: "",
                              password: "",
                            }));
                          }}
                          style={{
                            color: "#3366cc",
                            cursor: "pointer",
                            background: "none",
                            border: "none",
                            padding: "0",
                          }}
                          tabIndex={0}
                        >
                          <p
                            style={{
                              color: "#3366cc",
                              cursor: "default",
                              fontSize: "13px",
                              margin: "0px",
                            }}
                          >
                            Login using Password
                          </p>
                        </button>
                      ) : (
                        <>
                          {isActive ? (
                            <p
                              style={{
                                color: "#3366cc",
                                cursor: "default",
                                fontSize: "13px",
                                margin: "0px",
                              }}
                            >
                              Login using OTP in {formatTime(seconds)}
                            </p>
                          ) : (
                            <button
                              onClick={() => {
                                setValues((prev) => ({
                                  ...prev,
                                  otp: "",
                                  password: "",
                                }));
                                startTimer();
                                buyerLoginWithOTPStep1();
                                setLoginWithOtp(true);
                              }}
                              style={{
                                color: "#3366cc",
                                cursor: "pointer",
                                background: "none",
                                border: "none",
                                padding: "0",
                                fontSize: "13px",
                                margin: "0px",

                                border: "none",
                                background: "transparent",
                              }}
                              tabIndex={0}
                            >
                              Login using OTP
                            </button>
                          )}
                        </>
                      )}

                      {!loginWithOtp && (
                        <>
                          {showFeatures.forgetPassword && (
                            <button
                              id="BuyerLogin-signup-resetPassword-resetBtn"
                              onClick={() => {
                                setShowLogIn(false);
                                setShowSignUp(false);
                                setShowOTP(false);
                                setShowForgetPassword(true);
                                setForgetPassShowOTP(false);
                                setErrorMsgOTP("");
                                setErrorMsgNewPass("");
                                setErrorMsgNewPassCon("");
                                setValues((prev) => ({
                                  ...prev,
                                  password: "",
                                  name: "",
                                  new_password: "",
                                  new_password_confirmation: "",
                                  otp: "",
                                }));
                                setModalTitle("Reset your password");
                              }}
                              style={{
                                color: "#3366cc",
                                cursor: "pointer",
                                background: "none",
                                border: "none",
                                padding: "0",
                                fontSize: "13px",
                              }}
                              tabIndex={0}
                            >
                              Forgot your password?
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}

                {/* register form */}
                {showSignUp && (
                  <div className="containerBox mobile_modal_containerBox">
                    <div className="mobileViewOnly mobileViewModalTitleContainer mobile_modal_mobileViewModalTitleContainer">
                      <p className="mobileViewModalTitle mobile_modal_mobileViewModalTitle">
                        {modalTitle}
                      </p>
                    </div>
                    <div className="password-line">
                      <InputControl
                        inputTagId="EmailAddressOrPhoneNumber"
                        label={
                          <span className="inputTagLabel">
                            Email Address or Phone Number
                            <span className="required">*</span>
                          </span>
                        }
                        type="text"
                        value={values.emailOrPhone}
                        readOnly
                      />{" "}
                      <span
                        className="password-eye-icons"
                        onClick={handleResetModel}
                      >
                        {/* Edit */}
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 22 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.69568 20.3251C3.01045 20.3251 2.37016 20.0891 1.9096 19.651C1.32548 19.1006 1.04465 18.2693 1.14575 17.3707L1.56137 13.7311C1.64001 13.0459 2.05563 12.136 2.53866 11.6418L11.7611 1.88019C14.0639 -0.557414 16.4678 -0.624813 18.9054 1.67799C21.343 3.98079 21.4104 6.38469 19.1076 8.82229L9.88517 18.5839C9.41338 19.0894 8.53718 19.5612 7.85195 19.6736L4.23487 20.2913C4.04391 20.3025 3.87541 20.3251 3.69568 20.3251ZM15.367 1.66676C14.502 1.66676 13.7494 2.20595 12.9855 3.01474L3.76308 12.7877C3.53841 13.0236 3.28005 13.5852 3.23512 13.911L2.81949 17.5504C2.77456 17.9211 2.86442 18.2244 3.06662 18.4154C3.26882 18.6063 3.57211 18.6737 3.94281 18.6176L7.55989 17.9999C7.88565 17.9437 8.42485 17.6515 8.64951 17.4156L17.872 7.65404C19.2649 6.17126 19.7704 4.80082 17.7372 2.89117C16.8385 2.02622 16.0634 1.66676 15.367 1.66676Z"
                            fill="#CDCDCD"
                          />
                          <path
                            d="M16.9512 10.698C16.9287 10.698 16.895 10.698 16.8726 10.698C13.3678 10.3497 10.5483 7.68753 10.0091 4.20525C9.94169 3.74468 10.2562 3.31782 10.7168 3.23919C11.1773 3.17179 11.6042 3.48632 11.6828 3.94688C12.1097 6.66531 14.3114 8.75468 17.0523 9.02428C17.5129 9.06921 17.8499 9.48484 17.8049 9.94534C17.7488 10.3722 17.3781 10.698 16.9512 10.698Z"
                            fill="#CDCDCD"
                          />
                          <path
                            d="M21.0622 23.9533H0.842489C0.381928 23.9533 0 23.5714 0 23.1108C0 22.6502 0.381928 22.2683 0.842489 22.2683H21.0622C21.5228 22.2683 21.9047 22.6502 21.9047 23.1108C21.9047 23.5714 21.5228 23.9533 21.0622 23.9533Z"
                            fill="#CDCDCD"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="password-line">
                      <InputControl
                        id="buyer_signup_forName"
                        inputTagId="buyer_signup_forName"
                        label={
                          <span className="inputTagLabel">
                            Full Name
                            <span className="required">*</span>
                          </span>
                        }
                        maxLength={50}
                        autoFocus
                        type="text"
                        value={values.name}
                        onKeyPress={handleKeyPressRequestOTP}
                        onChange={(event) => {
                          setErrorMsgName("");
                          const inputValue = event.target.value;
                          const sanitizedValue = sanitizeInput(inputValue);
                          if (sanitizedValue === inputValue) {
                            if (
                              isValidName(sanitizedValue) ||
                              sanitizedValue == ""
                            ) {
                              setValues((prev) => ({
                                ...prev,
                                name: sanitizedValue,
                              }));
                            } else {
                              setErrorMsgName(
                                Constants.alertMessages.alertForChar
                              );
                            }
                          } else {
                            setErrorMsgName(
                              Constants.alertMessages.alertForSpecialChar
                            );
                          }
                        }}
                        // placeholder="Please enter name here."
                        error={errorMsgName}
                      />
                    </div>
                    <div className="password-line">
                      <InputControl
                        id="setSignupPass"
                        inputTagId="setSignupPass"
                        label={
                          <span className="inputTagLabel">
                            Set Password
                            <span className="required">*</span>
                          </span>
                        }
                        maxLength={16}
                        value={values.password}
                        onKeyPress={handleKeyPressRequestOTP}
                        type="password"
                        onChange={(event) => {
                          setErrorMsg("");
                          setValues((prev) => ({
                            ...prev,
                            password: event.target.value.trim(),
                          }));
                          setErrorMsgPassword("");
                        }}
                        error={errorMsgPassword}
                      />
                      <span className="password-eye-icons-password">
                        {showPassword ? (
                          <FontAwesomeIcon
                            icon={faEye}
                            className="fontassowmicon"
                            id="setSignupPass"
                            style={{ color: "#CDCDCD" }}
                            onClick={() => {
                              setShowPassword(false);
                              const x =
                                document.getElementById("setSignupPass");
                              x.type = "password";
                            }}
                            tabindex="0"
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="fontassowmicon"
                            icon={faEyeSlash}
                            style={{ color: "#CDCDCD" }}
                            onClick={() => {
                              setShowPassword(true);
                              const x =
                                document.getElementById("setSignupPass");
                              x.type = "text";
                            }}
                            tabindex="0"
                          />
                        )}
                      </span>
                      <a
                        href="#!"
                        className="password-requirements mobile_modal_password-requirements"
                        id="signUpPasswordRequirements"
                      >
                        Password requirements
                        <div className="signup-tooltip">
                          <ul>
                            <li>At least one number</li>
                            <li>At least 8 characters</li>
                            <li>At least one uppercase letter</li>
                            <li>At least one special character</li>
                          </ul>
                        </div>
                      </a>
                      {showFeatures.referralCode && (
                        <>
                          {!values.showReferral_code && (
                            <div className="showReferralCode-Div">
                              <FontAwesomeIcon
                                icon={faCaretRight}
                                style={{
                                  height: "12px",
                                  color: "#3366cc",
                                }}
                              />{" "}
                              <a
                                href="#!"
                                className="showReferralCode-txt mobile_modal_password-requirements"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setValues((prev) => ({
                                    ...prev,
                                    referral_code: "",
                                    showReferral_code: true,
                                  }));
                                  setErrorMsgReferralCode("");
                                }}
                              >
                                Have any referral code?
                              </a>
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    {/* referral code start*/}
                    {values.showReferral_code && (
                      <div className="password-line">
                        <InputControl
                          id="refralCodeInputFeild"
                          inputTagId="refralCodeInputFeild"
                          label={
                            <span className="inputTagLabel">Referral Code</span>
                          }
                          value={values.referral_code}
                          onKeyPress={handleKeyPressRequestOTP}
                          type="text"
                          onChange={(event) => {
                            setErrorMsg("");
                            setErrorMsgReferralCode("");
                            let refCode = event.target.value.trim();
                            let invalidInput = CheckSpecialChar(refCode);
                            if (!invalidInput || refCode == "") {
                              setValues((prev) => ({
                                ...prev,
                                referral_code: refCode.toUpperCase(),
                              }));
                            } else {
                              setErrorMsgReferralCode(
                                Constants.alertMessages.alertForSpecialChar
                              );
                            }
                          }}
                          error={errorMsgReferralCode}
                        />
                      </div>
                    )}
                    {/* referral code end*/}

                    <div className="BuyerLoginPage-footer">
                      {isActive ? (
                        <button
                          className={
                            isActive ? "tabFocus disabledElement" : "tabFocus"
                          }
                        >
                          Request OTP in {formatTime(seconds)}
                        </button>
                      ) : (
                        <button
                          id="RequestOTPBtn"
                          className={
                            isActive ? "tabFocus disabledElement" : "tabFocus"
                          }
                          onClick={() => {
                            if (!disabledBtn.requestOTP) {
                              setDisabledBtn((prev) => ({
                                ...prev,
                                requestOTP: true,
                              }));
                              handleRequestOTP();
                            }
                          }}
                        >
                          {disabledBtn.requestOTP ? (
                            <img
                              src={loadingImgPath}
                              alt="Loading"
                              className="disabledBtn-Loading"
                            />
                          ) : (
                            "Request OTP"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                )}

                {/* regiter show password */}
                {showOTP && (
                  <div className="containerBox mobile_modal_containerBox">
                    <div className="mobileViewOnly mobileViewModalTitleContainer">
                      <p className="mobileViewModalTitle mobile_modal_mobileViewModalTitle">
                        {modalTitle}
                      </p>
                    </div>
                    <div className="password-line">
                      <InputControl
                        inputTagId="EmailAddressOrPhoneNumber"
                        label={
                          <span className="inputTagLabel">
                            Email Address or Phone Number
                            <span className="required">*</span>
                          </span>
                        }
                        type="text"
                        value={values.emailOrPhone}
                        onKeyPress={handleKeyPressVerifyOTP}
                        readOnly
                      />{" "}
                      <span
                        className="password-eye-icons"
                        onClick={() => {
                          setDisabledBtn((prev) => ({
                            ...prev,
                            requestOTP: false,
                          }));
                          setValues((prev) => ({
                            ...prev,
                            otp: "",
                          }));
                          setOtpToken("");
                          setShowSignUp(true);
                          setModalTitle("Register to Get Started");
                          setShowOTP(false);
                        }}
                      >
                        {/* Edit */}
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 22 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.69568 20.3251C3.01045 20.3251 2.37016 20.0891 1.9096 19.651C1.32548 19.1006 1.04465 18.2693 1.14575 17.3707L1.56137 13.7311C1.64001 13.0459 2.05563 12.136 2.53866 11.6418L11.7611 1.88019C14.0639 -0.557414 16.4678 -0.624813 18.9054 1.67799C21.343 3.98079 21.4104 6.38469 19.1076 8.82229L9.88517 18.5839C9.41338 19.0894 8.53718 19.5612 7.85195 19.6736L4.23487 20.2913C4.04391 20.3025 3.87541 20.3251 3.69568 20.3251ZM15.367 1.66676C14.502 1.66676 13.7494 2.20595 12.9855 3.01474L3.76308 12.7877C3.53841 13.0236 3.28005 13.5852 3.23512 13.911L2.81949 17.5504C2.77456 17.9211 2.86442 18.2244 3.06662 18.4154C3.26882 18.6063 3.57211 18.6737 3.94281 18.6176L7.55989 17.9999C7.88565 17.9437 8.42485 17.6515 8.64951 17.4156L17.872 7.65404C19.2649 6.17126 19.7704 4.80082 17.7372 2.89117C16.8385 2.02622 16.0634 1.66676 15.367 1.66676Z"
                            fill="#CDCDCD"
                          />
                          <path
                            d="M16.9512 10.698C16.9287 10.698 16.895 10.698 16.8726 10.698C13.3678 10.3497 10.5483 7.68753 10.0091 4.20525C9.94169 3.74468 10.2562 3.31782 10.7168 3.23919C11.1773 3.17179 11.6042 3.48632 11.6828 3.94688C12.1097 6.66531 14.3114 8.75468 17.0523 9.02428C17.5129 9.06921 17.8499 9.48484 17.8049 9.94534C17.7488 10.3722 17.3781 10.698 16.9512 10.698Z"
                            fill="#CDCDCD"
                          />
                          <path
                            d="M21.0622 23.9533H0.842489C0.381928 23.9533 0 23.5714 0 23.1108C0 22.6502 0.381928 22.2683 0.842489 22.2683H21.0622C21.5228 22.2683 21.9047 22.6502 21.9047 23.1108C21.9047 23.5714 21.5228 23.9533 21.0622 23.9533Z"
                            fill="#CDCDCD"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="password-line">
                      <InputControl
                        // className="PasswordContainer-otp"
                        inputTagId="OTP"
                        label={
                          <span className="inputTagLabel">
                            OTP
                            <span className="required">*</span>
                          </span>
                        }
                        autoFocus
                        value={values.otp}
                        maxLength={4}
                        type="text"
                        onKeyPress={handleKeyPressVerifyOTP}
                        onChange={(event) => {
                          setErrorMsg("");
                          setErrorMsgOTP("");

                          // resetTimer();
                          const otp = event.target.value.trim();
                          const validFormat = isOTPFormat(otp);
                          if (validFormat || otp == "") {
                            setValues((prev) => ({
                              ...prev,
                              otp: otp,
                            }));
                          } else {
                            setErrorMsgOTP(
                              Constants.alertMessages.alertForOnlyNo
                            );
                          }
                        }}
                        // placeholder="Enter OTP"
                        error={errorMsgOTP}
                      />
                      <div className="resetPasswordResendOTP mobile_modal_resetPasswordResendOTP">
                        <>
                          {inputType === "Email" ? (
                            <>
                              {isActive ? (
                                <p
                                  className="resendOTPBtn"
                                  style={{
                                    color: "#3366cc",
                                    cursor: isActive ? "default" : "pointer",
                                  }}
                                >
                                  Resend OTP in {formatTime(seconds)}{" "}
                                </p>
                              ) : (
                                <button
                                  className="resendOTPBtn tabIndexBtn"
                                  style={{
                                    color: "#3366cc",
                                    cursor: isActive ? "default" : "pointer",
                                  }}
                                  onClick={() => {
                                    if (
                                      // !isActive &&
                                      !disabledBtn.resendOTP
                                    ) {
                                      setDisabledBtn((prev) => ({
                                        ...prev,
                                        loginOrSignUp: true,
                                        resendOTP: true,
                                      }));
                                      resendOTP();
                                    }
                                  }}
                                >
                                  {disabledBtn.resendOTP ? (
                                    <img
                                      src={loadingImgPath}
                                      alt="Loading"
                                      className="disabledBtn-Loading"
                                    />
                                  ) : (
                                    "Resend OTP"
                                  )}
                                </button>
                              )}
                            </>
                          ) : (
                            <>
                              {isActive ? (
                                <p
                                  className="resendOTPBtn"
                                  style={{
                                    color: "#3366cc",
                                    cursor: isActive ? "default" : "pointer",
                                  }}
                                >
                                  {" "}
                                  Resend OTP after {formatTime(seconds)}{" "}
                                </p>
                              ) : (
                                <button
                                  className="resendOTPBtn tabIndexBtn"
                                  style={{
                                    color: "#3366cc",
                                    cursor: isActive ? "default" : "pointer",
                                  }}
                                  onClick={() => {
                                    if (
                                      // !isActive &&
                                      !disabledBtn.resendOTP
                                    ) {
                                      setDisabledBtn((prev) => ({
                                        ...prev,
                                        loginOrSignUp: true,
                                        resendOTP: true,
                                      }));
                                      resendOTP();
                                    }
                                  }}
                                >
                                  {disabledBtn.resendOTP ? (
                                    <img
                                      src={loadingImgPath}
                                      alt="Loading"
                                      className="disabledBtn-Loading"
                                    />
                                  ) : (
                                    "Resend OTP"
                                  )}
                                </button>
                              )}
                            </>
                          )}
                        </>
                      </div>
                    </div>
                    <div className="BuyerLoginPage-footer">
                      <p className="BuyerLoginPage-error">{errorMsg}</p>

                      <button
                        className="tabFocus"
                        id="verifyOTP"
                        onClick={() => {
                          if (
                            // !isActive &&
                            !disabledBtn.verifyOTP
                          ) {
                            setDisabledBtn((prev) => ({
                              ...prev,
                              verifyOTP: true,
                            }));
                            handleOTPVerification();
                          }
                        }}
                      >
                        {disabledBtn.verifyOTP ? (
                          <img
                            src={loadingImgPath}
                            alt="Loading"
                            className="disabledBtn-Loading"
                          />
                        ) : (
                          "Verify"
                        )}
                      </button>
                      {/* <p className="or-text">OR</p> */}
                    </div>
                  </div>
                )}

                {/* forget password */}
                {showForgetPassword && (
                  <div className="containerBox mobile_modal_containerBox">
                    <div className="mobileViewOnly mobileViewModalTitleContainer mobile_modal_mobileViewModalTitleContainer">
                      <p className="mobileViewModalTitle mobile_modal_mobileViewModalTitle">
                        {modalTitle}
                      </p>
                    </div>
                    <div className="password-line">
                      {!forgetPassShowOTP ? (
                        <>
                          <InputControl
                            inputTagId="EmailAddressOrPhoneNumber"
                            label={
                              <span className="inputTagLabel">
                                Email Address or Phone Number
                                <span className="required">*</span>
                              </span>
                            }
                            type="text"
                            autoFocus
                            value={values.emailOrPhone}
                            onKeyPress={(event) => {
                              if (event.key === "Enter" || event.key === 13) {
                                event.preventDefault();
                                if (!isActive) {
                                  sendForgetPasswordOTP();
                                }
                              }
                            }}
                            onChange={(event) => {
                              if (!disabledBtn.requestOTP) {
                                setValues((prev) => ({
                                  ...prev,
                                  emailOrPhone: event.target.value.trim(),
                                }));
                                setErrorMsgEmail("");
                                setErrorMsg("");
                              }
                            }}
                            error={errorMsgEmail}
                          />{" "}
                        </>
                      ) : (
                        <div
                          className=""
                          style={{
                            margin: "0px 0px 20px 0px",
                          }}
                        >
                          <p
                            style={{
                              margin: "0",
                              fontSize: "14px",
                              width: "85%",
                            }}
                          >
                            Please enter the verification code we've sent you on{" "}
                            <span
                              className=""
                              style={{
                                fontWeight: "500",
                              }}
                            >
                              {" "}
                              {values.emailOrPhone}{" "}
                            </span>
                            {""}{" "}
                            <span
                              style={{
                                color: "#3366cc",
                                cursor: "pointer",
                                fontSize: "13px",
                              }}
                              onClick={() => {
                                setErrorMsgOTP("");
                                setErrorMsgNewPass("");
                                setErrorMsgNewPassCon("");
                                setForgetPassShowOTP(false);
                              }}
                            >
                              Edit
                            </span>
                          </p>
                        </div>
                      )}
                    </div>

                    {/* sendForgetPasswordOTP(); */}
                    {!forgetPassShowOTP ? (
                      <div className="BuyerLoginPage-footer BuyerLoginPage-footer-LoginResetPass-Mobile mobile_modal_BuyerLoginPage-footer-LoginResetPass-Mobile ">
                        {isActive ? (
                          <button className="disabledBtn hover-pointer">
                            Request OTP in {formatTime(seconds)}
                          </button>
                        ) : (
                          <button
                            className="tabFocus"
                            id="RequestOTPBtn"
                            onClick={() => {
                              if (!disabledBtn.requestOTP) {
                                setDisabledBtn((prev) => ({
                                  ...prev,
                                  requestOTP: true,
                                }));
                                sendForgetPasswordOTP();
                              }
                            }}
                          >
                            {disabledBtn.requestOTP ? (
                              <img
                                src={loadingImgPath}
                                alt="Loading"
                                className="disabledBtn-Loading"
                              />
                            ) : (
                              "Request OTP"
                            )}
                          </button>
                        )}
                        <span
                          id="BuyerLogin-signup-resetPassword-resetBtn"
                          onClick={() => {
                            setValues((prev) => ({
                              ...prev,
                              emailOrPhone: "",
                              password: "",
                              name: "",
                              new_password: "",
                              new_password_confirmation: "",
                              otp: "",
                            }));
                            setForgetPassShowOTP(false);
                            setErrorMsgEmail("");
                            handleResetModel();
                          }}
                        >
                          <button
                            className="BuyerLogin-SignUpBtn1 tabIndexBtn"
                            style={{
                              color: "#3366cc",
                              cursor: "pointer",
                            }}
                          >
                            Login or Signup
                          </button>
                        </span>
                      </div>
                    ) : (
                      <>
                        {" "}
                        <div className="password-line">
                          <InputControl
                            // className="PasswordContainer-otp"
                            inputTagId="OTP"
                            label={
                              <span className="inputTagLabel">
                                OTP
                                <span className="required">*</span>
                              </span>
                            }
                            autoFocus
                            value={values.otp}
                            maxLength={4}
                            type="text"
                            onKeyPress={(event) => {
                              if (event.key === "Enter" || event.key === 13) {
                                event.preventDefault();
                                setDisabledBtn((prev) => ({
                                  ...prev,
                                  resetPassword: true,
                                }));
                                verifyForgetPasswordOTP();
                              }
                            }}
                            onChange={(event) => {
                              setErrorMsg("");
                              setErrorMsgOTP("");
                              const otp = event.target.value.trim();
                              const validFormat = isOTPFormat(otp);
                              if (validFormat || otp == "") {
                                setValues((prev) => ({
                                  ...prev,
                                  otp: event.target.value.trim(),
                                }));
                              } else if (!validFormat && otp !== "") {
                                setErrorMsgOTP(
                                  Constants.alertMessages.alertForOnlyNo
                                );
                              }
                            }}
                            // placeholder="Enter OTP"
                            error={errorMsgOTP}
                          />
                          {/* update the function to resend OTP via resend OTP for forgot password API */}
                          <div className="resetPasswordResendOTP mobile_modal_resetPasswordResendOTP">
                            {isActive ? (
                              <p
                                style={{
                                  margin: "0px",
                                  cursor: isActive ? "default" : "pointer",
                                }}
                              >
                                {inputType == "Email"
                                  ? " Resend OTP in"
                                  : "Resend OTP after"}{" "}
                                {formatTime(seconds)}{" "}
                              </p>
                            ) : (
                              <button
                                style={{ margin: "0px", cursor: "pointer" }}
                                className="tabIndexBtn"
                                onClick={() => {
                                  if (!isActive && !disabledBtn.resendOTP) {
                                    setDisabledBtn((prev) => ({
                                      ...prev,
                                      loginOrSignUp: true,
                                    }));
                                    resendForgotPasswordOTP();
                                  }
                                }}
                              >
                                {disabledBtn.resendOTP ? (
                                  <img
                                    src={loadingImgPath}
                                    alt="Loading"
                                    className="disabledBtn-Loading"
                                  />
                                ) : (
                                  <>
                                    {" "}
                                    {inputType == "Email"
                                      ? "Resend OTP"
                                      : "Resend OTP"}
                                  </>
                                )}
                              </button>
                            )}
                          </div>
                        </div>
                        <div
                          className="password-line"
                          style={{ marginTop: "20px" }}
                        >
                          <InputControl
                            // className="PasswordContainer-otp"
                            inputTagId="NewPassword"
                            label={
                              <span className="inputTagLabel">
                                New Password
                                <span className="required">*</span>
                              </span>
                            }
                            maxLength={16}
                            type={showResetNewPassword ? "text" : "password"}
                            onKeyPress={(event) => {
                              if (event.key === "Enter" || event.key === 13) {
                                event.preventDefault();
                                setDisabledBtn((prev) => ({
                                  ...prev,
                                  resetPassword: true,
                                }));
                                verifyForgetPasswordOTP();
                              }
                            }}
                            onChange={(event) => {
                              setErrorMsg("");
                              setErrorMsgNewPass("");
                              setValues((prev) => ({
                                ...prev,
                                new_password: event.target.value.trim(),
                              }));
                            }}
                            // placeholder="New password"
                            error={errorMsgNewPass}
                          />
                          <span className="password-eye-icons-password">
                            {showResetNewPassword ? (
                              <FontAwesomeIcon
                                icon={faEye}
                                className="fontassowmicon"
                                style={{ color: "#CDCDCD" }}
                                onClick={() => {
                                  setShowResetNewPassword(false);
                                }}
                                tabindex="0"
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="fontassowmicon"
                                icon={faEyeSlash}
                                style={{ color: "#CDCDCD" }}
                                onClick={() => {
                                  setShowResetNewPassword(true);
                                }}
                                tabindex="0"
                              />
                            )}
                          </span>
                        </div>
                        <div className="password-line">
                          <InputControl
                            // className="PasswordContainer-otp"
                            inputTagId="ConfirmNewPassword"
                            label={
                              <span className="inputTagLabel">
                                Confirm New Password{" "}
                                <span className="required">*</span>
                              </span>
                            }
                            maxLength={16}
                            type={showResetNewConPassword ? "text" : "password"}
                            onKeyPress={(event) => {
                              if (event.key === "Enter" || event.key === 13) {
                                event.preventDefault();
                                setDisabledBtn((prev) => ({
                                  ...prev,
                                  resetPassword: true,
                                }));
                                verifyForgetPasswordOTP();
                              }
                            }}
                            onChange={(event) => {
                              setErrorMsg("");
                              setErrorMsgNewPassCon("");
                              setValues((prev) => ({
                                ...prev,
                                new_password_confirmation:
                                  event.target.value.trim(),
                              }));
                            }}
                            error={errorMsgNewPassCon}
                          />
                          <span className="password-eye-icons-password">
                            {showResetNewConPassword ? (
                              <FontAwesomeIcon
                                icon={faEye}
                                className="fontassowmicon"
                                style={{ color: "#CDCDCD" }}
                                onClick={() => {
                                  setShowResetNewConPassword(false);
                                }}
                                tabindex="0"
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="fontassowmicon"
                                icon={faEyeSlash}
                                style={{ color: "#CDCDCD" }}
                                onClick={() => {
                                  setShowResetNewConPassword(true);
                                }}
                                tabindex="0"
                              />
                            )}
                          </span>
                        </div>
                        <a
                          href="#!"
                          className="forgot-password-requirements mobile_modal_password-requirements"
                          id="signUpPasswordRequirements"
                        >
                          Password requirements
                          <div className="forgot-tooltip">
                            <ul>
                              <li>At least one number</li>
                              <li>At least 8 characters</li>
                              <li>At least one uppercase letter</li>
                              <li>At least one special character</li>
                            </ul>
                          </div>
                        </a>
                        <div className="BuyerLoginPage-footer">
                          <button
                            className="tabFocus"
                            id="verifyOTP"
                            onClick={() => {
                              if (!disabledBtn.resetPassword) {
                                setDisabledBtn((prev) => ({
                                  ...prev,
                                  resetPassword: true,
                                }));
                                verifyForgetPasswordOTP();
                              }
                            }}
                          >
                            {disabledBtn.resetPassword ? (
                              <img
                                src={loadingImgPath}
                                alt="Loading"
                                className="disabledBtn-Loading"
                              />
                            ) : (
                              "Verify"
                            )}
                          </button>
                          <span
                            id="BuyerLogin-signup-resetPassword-resetBtn"
                            onClick={() => {
                              setValues((prev) => ({
                                ...prev,
                                emailOrPhone: "",
                                password: "",
                                name: "",
                                new_password: "",
                                new_password_confirmation: "",
                                otp: "",
                              }));
                              setForgetPassShowOTP(false);
                              setErrorMsgEmail("");
                              handleResetModel();
                            }}
                          >
                            <button
                              className="BuyerLogin-SignUpBtn1 tabIndexBtn"
                              style={{
                                color: "#3366cc",
                                cursor: "pointer",
                              }}
                            >
                              Login or Signup
                            </button>
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                )}

                <span className="TermsConditionsTextHolder mobile_modal_TermsConditionsTextHolder">
                  <TermsConditionsText />
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginNewUpdatedModal;
