import React, { useEffect, useState, useContext, useRef } from "react";
import ParamsContext from "../useContext/paramContext";
import { useNavigate } from "react-router-dom";
import CategoryDropDown from "./categoryDropDown";
import { getAllCategoriesData } from "../../resuableFunctions/resuableFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";

const NavbarCategorySwiper = (props) => {
  const queryParams = new URLSearchParams(location.search);
  const [showFeatures, setShowFeatures] = useState({
    showCategoryFilter: true,
  });

  const lastItemRef = useRef(null);
  const scrollAmount = 150;
  const [selectedOption, setSelectedOption] = useState({
    name: "Newest First",
    value: "&order=desc&sort=created_at",
    showSort: false,
  });
  function smoothScroll(element, start, end) {
    let startTime = null;
    const duration = 300;

    function step(timestamp) {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const scrollDistance = end - start;
      const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
      const scrollAmount =
        scrollDistance * easeInOutQuad(Math.min(progress / duration, 1));
      element.scrollLeft = start + scrollAmount;
      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }
  const [scrollIntervalLeft, setScrollIntervalLeft] = useState(null);
  const handleScrollLeft = () => {
    const container = document.getElementById("topRibbonCategoryHolder");
    if (container) {
      if (container.scrollLeft < scrollAmount) {
        smoothScroll(container, container.scrollLeft, 0);
        setActiveScrollButton((prev) => ({
          ...prev,
          left: false,
        }));
        const button = document.getElementById("category-scroll-arrow-left");

        // Check if the button exists
        if (button) {
          // Create a new mouseup event
          const mouseUpEvent = new MouseEvent("mouseup", {
            bubbles: true,
            cancelable: true,
            view: window,
          });

          // Dispatch the mouseup event on the button
          button.dispatchEvent(mouseUpEvent);
        }

        return;
      } else {
        const targetScrollLeft = Math.max(
          container.scrollLeft - scrollAmount,
          0
        );
        if (targetScrollLeft >= 0) {
          smoothScroll(container, container.scrollLeft, targetScrollLeft);
          setActiveScrollButton((prev) => ({
            ...prev,
            left: true,
            right: true,
          }));
        } else {
          stopScrollingLeft();
          setActiveScrollButton((prev) => ({
            ...prev,
            left: false,
          }));
        }
      }
    } else {
      stopScrollingLeft(); // Clear interval if container not found
    }
  };

  const startScrollingLeft = () => {
    if (scrollIntervalLeft) return; // Return if already scrolling
    const intervalLeft = setInterval(() => {
      handleScrollLeft();
    }, 300); // Adjust the interval as needed
    setScrollIntervalLeft(intervalLeft);
  };

  // Function to stop scrolling when mouse up or mouse leaves
  const stopScrollingLeft = () => {
    clearInterval(scrollIntervalLeft);
    setScrollIntervalLeft(null);
  };

  // right
  const [scrollIntervalRight, setScrollIntervalRight] = useState(null);
  // Function to start scrolling repeatedly
  const startScrollingRight = () => {
    // if (scrollIntervalRight) {
    const intervalRight = setInterval(() => {
      handleScrollRight();
    }, 300); // Adjust the interval as needed
    setScrollIntervalRight(intervalRight);
    // }
  };
  // Function to stop scrolling when mouse up or leaves
  const stopScrollingRight = () => {
    clearInterval(scrollIntervalRight);
    setScrollIntervalRight(null);
  };

  let [dropDownPosition, setDropDownPosition] = useState("0px");
  let [activeScrollButton, setActiveScrollButton] = useState({
    left: false,
    right: true,
  });

  const handleScrollRight = (e) => {
    const container = document.getElementById("topRibbonCategoryHolder");
    const scrollableWidth = container.scrollWidth - container.clientWidth;
    const targetScrollRight = Math.min(
      container.scrollLeft + scrollAmount,
      scrollableWidth
    );

    if (
      scrollableWidth - targetScrollRight < scrollAmount ||
      targetScrollRight == scrollableWidth
    ) {
      stopScrollingRight();
      smoothScroll(container, container.scrollLeft, scrollableWidth);
      setActiveScrollButton((prev) => ({
        ...prev,
        right: false,
      }));
      const button = document.getElementById("category-scroll-arrow-right");

      if (button) {
        // Create a new mouseup event
        const mouseUpEvent = new MouseEvent("mouseup", {
          bubbles: true,
          cancelable: true,
          view: window,
        });

        button.dispatchEvent(mouseUpEvent);
      }
      return;
    }

    if (targetScrollRight <= scrollableWidth) {
      smoothScroll(container, container.scrollLeft, targetScrollRight);

      if (container.scrollLeft < scrollableWidth) {
        setActiveScrollButton((prev) => ({
          ...prev,
          right: true,
        }));
      } else {
        setActiveScrollButton((prev) => ({
          ...prev,
          right: false,
        }));
      }

      setActiveScrollButton((prev) => ({
        ...prev,
        left: true,
      }));
    }
  };

  let filterParamsUpdated = [
    {
      columnName: "brand_id",
      values: [],
    },
    {
      columnName: "category_id",
      values: [],
    },
    {
      columnName: "age_group",
      values: [],
    },
    {
      columnName: "condition",
      values: [],
    },
    {
      columnName: "price_range",
      values: [],
    },
    {
      columnName: "sort",
      values: [],
    },
    {
      columnName: "selected_category_id",
      value: [],
    },
  ];

  const navigate = useNavigate();

  function frameQueryString() {
    let queryString = "";
    let filterCount = 0;
    let tempArray = [];
    for (let i = 0; i < filterParamsUpdated.length; i++) {
      if (
        filterParamsUpdated[i].values.length != 0 &&
        filterParamsUpdated[i].columnName != "sort"
      ) {
        tempArray = filterParamsUpdated[i].values;
        if (tempArray.length > 0) {
          for (let j = 0; j < tempArray.length; j++) {
            if (
              filterParamsUpdated[i].columnName == "price_range" ||
              filterParamsUpdated[i].columnName == "age_group" ||
              filterParamsUpdated[i].columnName == "brand_id" ||
              filterParamsUpdated[i].columnName == "condition" ||
              filterParamsUpdated[i].columnName == "category_id" ||
              filterParamsUpdated[i].columnName == "selected_category_id"
            ) {
              {
                queryString +=
                  j == 0
                    ? `&${filterParamsUpdated[i].columnName}=${tempArray[j]}`
                    : `,${tempArray[j]}`;
              }
              if (tempArray[j] !== "") {
                filterCount++;
              }
            }
          }
        }
      }
    }

    const sortColumnIndex = getIndexOfColumn("sort");

    // if (showFeatures.showSortBy) {
    if (filterParamsUpdated[sortColumnIndex].values[0]) {
      queryString += filterParamsUpdated[sortColumnIndex].values[0];
    }
    // }

    if (queryString) {
      const base64EncodedQuery = btoa(queryString);
      navigate(`/products?query=${base64EncodedQuery}`);
    } else {
      navigate(`/products`);
    }
  }

  function pushToArray(columnName, value) {
    // Ensure that updateFilterArray returns a Promise
    return updateFilterArray()
      .then(() => {
        const filterElement = filterParamsUpdated.find(
          (element) => element.columnName === columnName
        );

        if (columnName == "selected_category_id") {
          filterElement.values = [];
          filterElement.values.push(value);
          return;
        }

        if (filterElement && !filterElement.values.includes(value)) {
          filterElement.values.push(value);
        }
      })
      .catch((error) => {
        console.error(
          "An error occurred while updating the filter array:",
          error
        );
      });
  }

  function getIndexOfColumn(columnName) {
    let columnIndex = -1;
    for (let i = 0; i <= filterParamsUpdated.length; i++) {
      if (filterParamsUpdated[i].columnName === columnName) {
        columnIndex = i;
        break;
      }
    }
    return columnIndex;
  }
  function processQuery(arr) {
    const result = {};
    let orderSort = "";
    let valueSort = "";
    arr.forEach((item) => {
      if (item) {
        // Check if the string is not empty
        const [key, value] = item.split("="); // Split by '='
        if (key !== "sort" && key !== "order") {
          result[key] = value ? value.split(",") : "";
        } // Assign key and value to the result object

        if (key == "order") {
          orderSort = value;
        }
        if (key == "sort") {
          valueSort = value;
        }
      }
    });

    if (orderSort && valueSort) {
      const indexOfSortColumn = getIndexOfColumn("sort");
      filterParamsUpdated[indexOfSortColumn].values = [];
      if (indexOfSortColumn >= 0) {
        if (orderSort == "asc") {
          if (valueSort == "selling_price") {
            setSelectedOption((prev) => ({
              ...prev,
              name: "Low-Priced First",
              value: "&order=asc&sort=selling_price",
            }));

            result.sort = ["&order=asc&sort=selling_price"];
          } else {
            setSelectedOption((prev) => ({
              ...prev,
              name: "Oldest First",
              value: "&order=asc&sort=created_at",
            }));

            result.sort = ["&order=asc&sort=created_at"];
          }
        } else {
          if (valueSort == "selling_price") {
            setSelectedOption((prev) => ({
              ...prev,
              name: "High-Priced First",
              value: "&order=desc&sort=selling_price",
            }));

            result.sort = ["&order=desc&sort=selling_price"];
          } else {
            setSelectedOption((prev) => ({
              ...prev,
              name: "Newest First",
              value: "&order=desc&sort=created_at",
            }));

            result.sort = ["&order=desc&sort=created_at"];
          }
        }
      }
    }

    return result;
  }

  useEffect(() => {
    updateFilterArray();
  }, [location.search]);
  const updateFilterArray = () => {
    return new Promise((resolve, reject) => {
      try {
        if (queryParams) {
          const queryString = atob(queryParams.get("query")).split("&");

          if (queryString) {
            const queryObj = processQuery(queryString);
            filterParamsUpdated = [
              { columnName: "brand_id", values: [] },
              { columnName: "category_id", values: [] },
              { columnName: "age_group", values: [] },
              { columnName: "condition", values: [] },
              { columnName: "price_range", values: [] },
              { columnName: "sort", values: [] },
              {
                columnName: "selected_category_id",
                values: [],
              },
            ];

            filterParamsUpdated = filterParamsUpdated.map((element) => {
              const value = queryObj?.[element.columnName];

              if (
                element.columnName !== "category_id" &&
                element.columnName !== "selected_category_id" &&
                value &&
                value.length > 0
              ) {
                element = { ...element, values: value };
              } else {
                element = { ...element, values: [] };
              }

              if (element.columnName == "selected_category_id" && value) {
                setSelectedCategoryId(value[0]);
              }

              return element;
            });
          }
        }

        // Resolve the promise after processing
        resolve();
      } catch (error) {
        // Reject the promise if there's an error
        reject(error);
      }
    });
  };

  const navigateToAllProducts = async () => {
    window.scrollTo(0, 0);
    setSelectedCategoryId("allProducts");
    await contextObj.setInput("reSetFilters", true);
    navigate("/products");
  };
  const [items, setItems] = useState([]);
  const reFetchCategoriesData = async () => {
    let serviceUnavailable = await getAllCategoriesData();
    if (serviceUnavailable) {
      contextObj.setInput("serviceUnavailable", true);
    }

    let categoryInCookies = sessionStorage.getItem("parentCategoryListData");
    categoryInCookies = JSON.parse(categoryInCookies);
    if (categoryInCookies && categoryInCookies.data) {
      categoryInCookies = categoryInCookies.data;
      setItems(categoryInCookies);
    }
  };
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const contextObj = useContext(ParamsContext);

  useEffect(() => {
    let featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        showCategoryFilter: featureFlags.buyer_products_categories,
      }));
    }
  }, [contextObj.params.featureFlags]);

  useEffect(() => {
    let categoryInSession = sessionStorage.getItem("parentCategoryListData");
    if (categoryInSession === null || categoryInSession === undefined) {
      reFetchCategoriesData();
    } else {
      categoryInSession = JSON.parse(categoryInSession);
      categoryInSession = categoryInSession.data;
      if (categoryInSession) {
        setItems(categoryInSession);
      }
    }
  }, []);

  const navigateToParentCategory = async (categories, parentCategoryId) => {
    // let categoriesQuery = "";
    // categories.forEach((category) => {
    //   categoriesQuery = categoriesQuery + `${category.id},`;
    // });

    // let updatedCategoryQuery = btoa(`&category_id=${categoriesQuery}`);
    // navigate(`/products?query=${updatedCategoryQuery}`);
    const filterElement = filterParamsUpdated.find(
      (element) => element.columnName === "category_id"
    );
    if (filterElement) {
      filterElement.values = [];
      await pushToArray("category_id", parentCategoryId);
    }
    Promise.all(
      categories.map(async (category) => {
        await pushToArray("category_id", category.id);
      })
      
    ).then(() => {
      frameQueryString();
    });
  };
  const navigateToCategory = async (category) => {
    // let updatedCategoryQuery = btoa(`&category_id=${category.id}`);
    // navigate(`/products?query=${updatedCategoryQuery}`);

    const filterElement = filterParamsUpdated.find(
      (element) => element.columnName === "category_id"
    );
    if (filterElement) {
      filterElement.values = [];
    }
    pushToArray("selected_category_id", category.id);
    pushToArray("category_id", category.id)
      .then(() => {
        frameQueryString();
      })
      .catch((error) => {
        console.error("An error occurred while pushing to the array:", error);
      });
  };
  const container = document.getElementById("topRibbonCategoryHolder");

  useEffect(() => {
    const handleResize = () => {
      setActiveScrollButton((prev) => ({
        ...prev,
        left: container.scrollLeft == 0 ? false : true,
        right: container.scrollWidth > container.clientWidth,
      }));
    };
    if (container !== null) {
      window.addEventListener("resize", handleResize);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [container]);

  useEffect(() => {
    const container = document.getElementById("topRibbonCategoryHolder");
    if (container) {
      setActiveScrollButton((prev) => ({
        ...prev,
        right: container.scrollWidth > container.clientWidth,
      }));
    }
  }, [lastItemRef.current]);

  const closeCategoryDropDown = (itemId) => {
    setDropDownPosition("0px"),
      setItems((prevItems) => {
        return prevItems.map((prevItem) => {
          if (prevItem.id === itemId) {
            return { ...prevItem, showList: false };
          } else {
            return prevItem;
          }
        });
      });
  };

  return (
    <div className="top-menu-bar">
      <ul
        style={{
          margin: "0px",
          padding: "0px",
          listStyle: "none",
        }}
      >
        <li
          role="button"
          tabIndex="0"
          onClick={() => {
            navigateToAllProducts();
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              // Trigger action on Enter or Space key press
              navigateToAllProducts();
            }
          }}
          onMouseDown={(e) => {
            if (e.button == 1) {
              e.preventDefault();
              window.scrollTo(0, 0);
              navigateToAllProducts();
            }
          }}
          id="navbar_all_Products"
          className="all-prdoducts-menu topRibbonCategoryName"
        >
          <p className="noMarginParagraph"> All Products </p>
        </li>
      </ul>

      {showFeatures.showCategoryFilter && (
        <>
          {/* Left Arrow */}
          {activeScrollButton.left && (
            <div
              className="category-scroll-arrow ribbon-left-arrow"
              id="category-scroll-arrow-left"
              onMouseDown={startScrollingLeft}
              onMouseUp={stopScrollingLeft}
              onMouseLeave={stopScrollingLeft}
              onClick={handleScrollLeft}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
          )}
          {/* Category Menu List */}
          <div id="topRibbonCategoryHolder">
            {items &&
              items.map((item, index) => (
                <div
                  key={index + 1}
                  className="topRibbonCategoryList navbar-logocontainer-button"
                >
                  <ul
                    style={{ listStyle: "none", margin: "0px", padding: "0px" }}
                  >
                    <li
                      ref={index === items.length - 1 ? lastItemRef : null}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setSelectedCategoryId(item.id);
                        if (
                          item.childCategory &&
                          item.childCategory.length > 0
                        ) {
                          navigateToParentCategory(item.childCategory,item.id);
                        } else {
                          navigateToCategory(item);
                        }
                      }}
                      onMouseDown={(e) => {
                        if (e.button === 1) {
                          e.preventDefault();
                          window.scrollTo(0, 0);
                          setSelectedCategoryId(item.id);
                          if (
                            item.childCategory &&
                            item.childCategory.length > 0
                          ) {
                            sessionStorage.setItem(
                              "selectedCategoryId",
                              item.id
                            );
                            navigateToParentCategory(item.childCategory);
                          } else {
                            navigateToCategory(item);
                          }
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          // Trigger action on Enter or Space key press
                          window.scrollTo(0, 0);
                          setSelectedCategoryId(item.id);
                          if (
                            item.childCategory &&
                            item.childCategory.length > 0
                          ) {
                            sessionStorage.setItem(
                              "selectedCategoryId",
                              item.id
                            );
                            navigateToParentCategory(item.childCategory);
                          } else {
                            navigateToCategory(item);
                          }
                        }
                      }}
                      style={{
                        border: selectedCategoryId == item.id && "1px solid",
                      }}
                      className="topRibbonCategoryName"
                      tabIndex={0} // Add tabindex here
                    >
                      <p className="noMarginParagraph">{item.name}</p>

                      {item.showList &&
                        item.childCategory &&
                        item.childCategory.length > 0 && (
                          <div
                            className="CategoryDropDownContainer"
                            style={{
                              left: `${dropDownPosition}`,
                              position: "absolute",
                            }}
                          >
                            <CategoryDropDown
                              setSelectedCategoryId={setSelectedCategoryId}
                              childCategories={item.childCategory}
                              show={item.showList}
                              closeCategoryDropDown={closeCategoryDropDown}
                              item={item}
                            />
                          </div>
                        )}
                    </li>
                  </ul>
                </div>
              ))}
          </div>
          {/* Right arrow */}
          {activeScrollButton.right && (
            <div
              className="category-scroll-arrow ribbon-right-arrow"
              id="category-scroll-arrow-right"
              onMouseDown={startScrollingRight}
              onMouseUp={stopScrollingRight}
              onMouseLeave={stopScrollingRight}
              onClick={handleScrollRight}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NavbarCategorySwiper;
