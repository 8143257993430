import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import InputControl from "../InputControl/InputControl";
import { UserSideNavbar } from "../userSideNavbar/usersidenavbarMQ";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import ServiceUnavailable from "../serviceUnavailable/serviceUnavailable";
import ParamsContext from "../useContext/paramContext";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import endpoints from "../../config/APIEndpoints.json";
import navigation from "../../config/Navigation.json";
import Constants from "../../config/ProjectConstants.json";
import "react-calendar/dist/Calendar.css";
import { logout } from "../../resuableFunctions/resuableFunctions";
import AccountDelete from "./AccountDelete";
import "./profileMQ.css";
import {
  getDataFromCookies,
  setDataToCookies,
  removeDataFromCookie,
} from "../Cookies/Cookies";
import Toast from "../ReactToastify/Toast";
import ProfileSkeleton from "./ProfileSkeleton";
import loadingImgPath from "../../Assets/rolling.svg";
import { Helmet } from "react-helmet";
const validator = require("validator");
const MemoizedUserSideNavbar = React.memo(UserSideNavbar);
const API_HOST = process.env.REACT_APP_API_HOST;
const infra = process.env.REACT_APP_INFRA;
const ProfileInfo = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentURL = window.location.origin + pathname;
  const { showToast } = Toast();
  const [accountDeleted, setAccountDeleted] = useState(false);
  const [maintainanceMode, setMaintainanceMode] = useState(false);
  const [activeAddChildBtn, setActiveAddChildBtn] = useState(true);
  const [inEditMode, setInEditMode] = useState(false);
  const [enableUpdatePassBtn, setEnableUpdatePassBtn] = useState(false);
  const [unableToDelete, setUnableToDelete] = useState(false);
  const [focusDivId, setFocusDivId] = useState(null);
  const contextObj = useContext(ParamsContext);
  const [showAddChild, setShowAddChild] = useState(false);
  const [changePassPopup, setChangePassPopup] = useState(false);
  const [deleteAccountPopup, setDeleteAccountPopup] = useState(false);
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  let [loggedInAfterUpdate, LoggedInOutAfterUpdate] = useState(false);
  const [deleteSelectedValue, setDeleteSelectedValue] = useState("");
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [loading, setLoading] = useState(true);
  const [DontShowChildDiv, setDontShowChildDiv] = useState(false);
  const [showChildrenListElement, setShowChildrenListElement] = useState(false);
  const [inputTagDeisable, setInputTagDeisable] = useState(false);
  const [emailExist, setEmailExist] = useState(false);
  const [phoneExist, setPhoneExist] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [enableUpdateProfileBtn, setEnableUpdateProfileBtn] = useState(false);
  const [otp, setOTP] = useState("");
  const [otpValidationMessage, setOtpValidationMessage] = useState("");
  const [otpVerifyToken, setOtpVerifyToken] = useState("");
  const [removeChildDetails, setRemoveChildDetails] = useState(false);
  const [removeChildId, setRemoveChildId] = useState(null);
  const space = " ";
  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(false);
  const [showDeleteEditEmailPopup, setShowDeleteEditEmailPopup] =
    useState(false);
  const [showDeleteEditMobilePopup, setShowDeleteEditMobilePopup] =
    useState(false);
  const [dataChanged, setDataChanged] = useState({
    dataUpdated: false,
    dataUpdatedId: "",
  });
  const [updateDataErrors, setUpdateDataErrors] = useState({
    name: "",
    dob: "",
    gender: "",
  });
  const [showFeatures, setShowFeatures] = useState({
    changePassword: true,
    deleteAccount: true,
    showFooter: true,
    showChildrenDetails: true,
    searchbar: true,
    referralCode: true,
  });
  const [editData, setEditData] = useState({
    id: "",
    name: "",
    dob: "",
    gender: "",
  });
  const [updateContactInfo, setUpdateContactInfo] = useState({
    AddFirstName: false,
    firstName: "",
    AddLastName: false,
    lastName: "",
    addEmailPopup: false,
    addPhonePopup: false,
    showOTP: false,
    enterOTP: "",
    otpToken: "",
    email: "",
    phone: "",
    errorOTP: "",
    errorMSG: "",
    inputType: "",
  });
  const [values, setValues] = useState({
    Fname: "",
    Lname: "",
    Phone: "",
    email: "",
    referral_code: "",
  });
  const [deactivateAccountError, setDeactivateAccountError] = useState("");
  const [name, setName] = useState({
    Fname: "",
    Lname: "",
  });
  const [childrenList, setChildrenList] = useState([]);
  const [childrenData, setChildrenData] = useState({
    name: "",
    dob: "",
    gender: "",
  });
  const [errorChildrenMessage, setErrorChildrenMessage] = useState({
    name: "",
    dob: "",
    gender: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    errorFname: "",
    errorLname: "",
    errorEmail: "",
    errorPhone: "",
    new_password: "",
    new_password_confirmation: "",
  });
  const [disabledBtn, setDisabledBtn] = useState({
    checkProfileStatus: false,
    requestOTPPhone: false,
    verifyOTP: false,
    requestOTPEmail: false,
    updateProfile: false,
    deleteAccount: false,
    updateProfile: false,
    changePassword: false,
    resendOTP: false,
  });
  const [pass, setPass] = useState({
    new_password: "",
    new_password_confirmation: "",
  });

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  function isDateValid(dateString) {
    const today = new Date();
    let updatedDate = dateString.split("/").reverse().join("-");
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    const minRange = calculateMinDate();
    const maxRange = calculateMaxDate();
    return (
      updatedDate >= minRange &&
      updatedDate <= maxRange &&
      updatedDate <= formattedDate
    );
  }

  function isDateValidBirthday(dateString) {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    const selectedDate = dateString.split("/");
    const selectedDay = parseInt(selectedDate[0]);
    const selectedMonth = parseInt(selectedDate[1]);
    const selectedYear = parseInt(selectedDate[2]);
    if (selectedYear < year) {
      return true;
    } else if (selectedYear == year) {
      if (selectedMonth < month) {
        return true;
      } else if (selectedMonth == month) {
        if (selectedDay <= day) {
          return true;
        }
      }
    }
    return false;
  }

  useEffect(() => {
    if (pass.new_password !== "" && pass.new_password_confirmation !== "") {
      setEnableUpdatePassBtn(true);
    } else {
      setEnableUpdatePassBtn(false);
    }
  }, [pass]);

  const handelDeleteChild = (deleteId) => {
    setRemoveChildId(deleteId);
    setRemoveChildDetails(true);
  };

  const handelCancelDeleteChildId = () => {
    setRemoveChildDetails(false);
  };

  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags !== null) {
      setShowFeatures((prev) => ({
        ...prev,
        changePassword: featureFlags.buyer_profile_changepassword,
        deleteAccount: featureFlags.buyer_profile_deleteaccount,
        showFooter: featureFlags.buyer_common_footer,
        showChildrenDetails: featureFlags.buyer_profile_childrendetails,
        searchbar: featureFlags.buyer_products_search,
        referralCode: featureFlags.buyer_profile_referralcode,
      }));
    }
  }, [contextObj.params.featureFlags]);

  useEffect(() => {
    getChildrenBirthdayList();
  }, []);

  const handleSelectChange = (e) => {
    setDeleteSelectedValue(e.target.value);
  };

  const updateBirthdayData = async () => {
    const token = getDataFromCookies("7H3K9R5P2Q");
    let validDOB = isDateValid(editData.dob);
    let isValidBirthday = isDateValidBirthday(editData.dob);

    let errorFound = false;

    if (!validDOB) {
      setUpdateDataErrors((prevData) => ({
        ...prevData,
        dob: Constants.alertMessages.errorForValidDOB,
      }));
      errorFound = true;
    }
    if (!isValidBirthday) {
      setUpdateDataErrors((prevData) => ({
        ...prevData,
        dob: Constants.alertMessages.errorForInvalidDOB,
      }));
      errorFound = true;
    }
    if (editData.id == "") {
      errorFound = true;
    } else if (editData.name.trim() == "") {
      setUpdateDataErrors((prevData) => ({
        ...prevData,
        name: Constants.alertMessages.alertForName,
      }));
      errorFound = true;
    } else if (editData.name.length < 2 || editData.name.length > 50) {
      setUpdateDataErrors((prevData) => ({
        ...prevData,
        name: Constants.alertMessages.errorForNameLength,
      }));
      errorFound = true;
    } else if (editData.dob == "") {
      setUpdateDataErrors((prevData) => ({
        ...prevData,
        dob: Constants.alertMessages.errorForBirthDayRequired,
      }));
      errorFound = true;
    } else if (editData.gender == "") {
      setUpdateDataErrors((prevData) => ({
        ...prevData,
        gender: Constants.alertMessages.alertForGender,
      }));
      errorFound = true;
    }
    if (errorFound == true) {
      return;
    }
    const payload = {
      name: editData.name,
      dob: editData.dob,
      gender: editData.gender,
    };

    const updateBirthDay = await fetch(
      `${API_HOST}/${endpoints.updateChildrenBirthdayDetails}/${editData.id}`, // update API
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );

    const updateBirthDayResJson = await updateBirthDay.json();
    if (
      updateBirthDayResJson.status == 500 ||
      updateBirthDayResJson.status == 501 ||
      updateBirthDayResJson.status == 502 ||
      updateBirthDayResJson.status == 503 ||
      (updateBirthDayResJson.message &&
        updateBirthDayResJson.message.toLowerCase().includes("server error"))
    ) {
      if (updateBirthDayResJson.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    setDataChanged((prev) => ({
      ...prev,
      dataUpdated: false,
      dataUpdatedId: "",
    }));
    if (updateBirthDayResJson.status == 200) {
      showToast("success", updateBirthDayResJson.message);
      setUpdateDataErrors((prevData) => ({
        ...prevData,
        name: "",
        dob: "",
        gender: "",
      }));
      getChildrenBirthdayList();
    } else if (updateBirthDayResJson.status == 400) {
      let errors = updateBirthDayResJson.errors;

      if (errors) {
        if (errors.name) {
          errorFound = true;

          setUpdateDataErrors((prevData) => ({
            ...prevData,
            name: errors.name[0],
          }));
        } else if (errors.dob) {
          errorFound = true;

          setUpdateDataErrors((prevData) => ({
            ...prevData,
            dob: errors.dob[0],
          }));
        } else if (errors.gender) {
          errorFound = true;
        }
      }
    } else if (updateBirthDayResJson.status == 409) {
      if (
        updateBirthDayResJson.message ==
        Constants.alertMessages.errorForSameChildName
      ) {
        setUpdateDataErrors((prevData) => ({
          ...prevData,
          name: Constants.alertMessages.errorForSameChildName,
        }));

        return;
      }
    } else if (updateBirthDayResJson.status == 401) {
      removeDataFromCookie("7H3K9R5P2Q", "/");
      removeDataFromCookie("isLoggedIn", "/");
      showToast("info", Constants.alertMessages.alertForAccountDeleted);
      contextObj.setInput("isLoggedIn", false);
      navigate(`${navigation.navigateToHomepage}`);
    } else {
      if (updateBirthDayResJson.message) {
        showToast("info", updateBirthDayResJson.message);
      }
    }
    // setInEditMode(false);
  };

  const deleteBirthdayData = async (id) => {
    const token = getDataFromCookies("7H3K9R5P2Q");

    const deleteBirthDay = await fetch(
      `${API_HOST}/${endpoints.deleteChildrenBirthdayDetails}/${id}`, // update API
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const deleteBirthDayResJson = await deleteBirthDay.json();
    if (
      deleteBirthDayResJson.status == 500 ||
      deleteBirthDayResJson.status == 501 ||
      deleteBirthDayResJson.status == 502 ||
      deleteBirthDayResJson.status == 503 ||
      (deleteBirthDayResJson.message &&
        deleteBirthDayResJson.message.toLowerCase().includes("server error"))
    ) {
      if (deleteBirthDayResJson.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    if (deleteBirthDayResJson.status == 200) {
      showToast("success", deleteBirthDayResJson.message);
      getChildrenBirthdayList();
    } else if (deleteBirthDayResJson.status == 401) {
      removeDataFromCookie("7H3K9R5P2Q", "/");
      removeDataFromCookie("isLoggedIn", "/");
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${navigation.navigateToHomepage}`);
    } else {
      if (deleteBirthDayResJson.message) {
        showToast("info", deleteBirthDayResJson.message);
      }
    }
    setRemoveChildDetails(false);
  };

  const isPhoneNumberFormat = (phone) => {
    const regex = /^[0-9]+$/;
    return regex.test(phone);
  };

  const isPhoneNumber = (phone) => {
    const regex = /^(\+?91[\-\s]?)?[1-9]\d{9}$/.test(phone);
    return regex;
  };

  const isOTPFormat = (otp) => {
    const regex = /^[0-9]+$/;
    return regex.test(otp);
  };

  const isPasswordValid = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_\-+=])[^\s]{6,}$/;
    return passwordRegex.test(password);
  };

  const requestOTP = async (type) => {
    setUpdateContactInfo((prev) => ({
      ...prev,
      errorMSG: "",
    }));

    const payload = {};
    const isEmailValue = validator.isEmail(updateContactInfo.email);
    const isPhoneNumberValue = isPhoneNumber(updateContactInfo.phone);

    if (isEmailValue) {
      payload.email = updateContactInfo.email;
    } else if (isPhoneNumberValue) {
      payload.phone = updateContactInfo.phone;
    } else if (updateContactInfo.email !== "" && !isEmailValue) {
      setDisabledBtn((prev) => ({
        ...prev,
        requestOTPPhone: false,
        verifyOTP: false,
        requestOTPEmail: false,
        deleteAccount: false,
        updateProfile: false,
        changePassword: false,
        resendOTP: false,
      }));

      setUpdateContactInfo((prev) => ({
        ...prev,
        errorMSG: Constants.alertMessages.alertForValidEmail,
      }));
      setIsDataChanged(false);
      return;
    } else if (updateContactInfo.phone !== "" && !isPhoneNumberValue) {
      setDisabledBtn((prev) => ({
        ...prev,
        requestOTPPhone: false,
        verifyOTP: false,
        requestOTPEmail: false,
        updateProfile: false,
        deleteAccount: false,
        updateProfile: false,
        changePassword: false,
        resendOTP: false,
      }));
      setUpdateContactInfo((prev) => ({
        ...prev,
        errorMSG: Constants.alertMessages.alertForValidPhone,
      }));
      setIsDataChanged(false);
      return;
    } else if (updateContactInfo.email === "" && type == "email") {
      setDisabledBtn((prev) => ({
        ...prev,
        requestOTPPhone: false,
        verifyOTP: false,
        requestOTPEmail: false,
        updateProfile: false,
        deleteAccount: false,
        updateProfile: false,
        changePassword: false,
        resendOTP: false,
      }));
      setUpdateContactInfo((prev) => ({
        ...prev,
        errorMSG: "Email address field is required.",
      }));
      setIsDataChanged(false);
      return;
    } else if (updateContactInfo.phone === "" && type == "phone") {
      setDisabledBtn((prev) => ({
        ...prev,
        requestOTPPhone: false,
        verifyOTP: false,
        requestOTPEmail: false,
        updateProfile: false,
        deleteAccount: false,
        updateProfile: false,
        changePassword: false,
        resendOTP: false,
      }));
      setUpdateContactInfo((prev) => ({
        ...prev,
        errorMSG: "Phone number field is required.",
      }));
      setIsDataChanged(false);
      return;
    }

    setUpdateContactInfo((prev) => ({
      ...prev,
      inputType: type,
    }));

    if (isEmailValue || isPhoneNumberValue) {
      const token = getDataFromCookies("7H3K9R5P2Q");
      const requestOTP = await fetch(
        `${API_HOST}/${endpoints.buyerUpdateEmailPhoneStep1}`, // update API
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const requestOTPResJson = await requestOTP.json();
      if (
        requestOTPResJson.status == 500 ||
        requestOTPResJson.status == 501 ||
        requestOTPResJson.status == 502 ||
        requestOTPResJson.status == 503 ||
        (requestOTPResJson.message &&
          requestOTPResJson.message.toLowerCase().includes("server error"))
      ) {
        if (requestOTPResJson.status == 503) {
          setMaintainanceMode(true);
        } else {
          setMaintainanceMode(false);
        }
        contextObj.setInput("serviceUnavailable", true);
      } else {
        contextObj.setInput("serviceUnavailable", false);
      }
      setIsDataChanged(false);
      if (type == "email") {
        setDisabledBtn((prev) => ({
          ...prev,
          requestOTPEmail: false,
        }));
      } else if (type == "phone") {
        setDisabledBtn((prev) => ({
          ...prev,
          requestOTPPhone: false,
        }));
      }
      setDisabledBtn((prev) => ({
        ...prev,
        requestOTPPhone: false,
        verifyOTP: false,
        requestOTPEmail: false,
        updateProfile: false,
        deleteAccount: false,
        changePassword: false,
        resendOTP: false,
      }));
      if (requestOTPResJson.status == 201) {
        setDisabledBtn((prev) => ({
          ...prev,
          verifyOTP: false,
        }));
        setUpdateContactInfo((prev) => ({
          ...prev,
          otpToken: requestOTPResJson.data,
          errorMSG: "",
          showOTP: true,
        }));
        startTimer();
        setSeconds(60);
      }
      if (requestOTPResJson.status == 400) {
        if (requestOTPResJson.message) {
          setUpdateContactInfo((prev) => ({
            ...prev,
            errorMSG: requestOTPResJson.message,
          }));
        }
        if (requestOTPResJson.errors) {
          if (requestOTPResJson.errors.email) {
            setUpdateContactInfo((prev) => ({
              ...prev,
              errorMSG: requestOTPResJson.errors.email[0],
            }));
          }
          if (requestOTPResJson.errors.phone) {
            setUpdateContactInfo((prev) => ({
              ...prev,
              errorMSG: requestOTPResJson.errors.phone[0],
            }));
          }
        }

        return;
      }

      if (requestOTPResJson.status == 404) {
        if (isEmailValue) {
          setUpdateContactInfo((prev) => ({
            ...prev,
            errorMSG: "This email address is already registered",
          }));
        }
        if (isPhoneNumberValue) {
          setUpdateContactInfo((prev) => ({
            ...prev,
            errorMSG: "This phone number is already registered",
          }));
        }
      }
      if (requestOTPResJson.status == 429) {
        setUpdateContactInfo((prev) => ({
          ...prev,
          errorMSG: "Too many attempts, try later.",
        }));
      }
    }
  };

  const verifyOTP = async () => {
    const payload = {
      otp: updateContactInfo.enterOTP,
      otp_verify_token: updateContactInfo.otpToken,
    };
    if (updateContactInfo.inputType == "email") {
      payload.email = updateContactInfo.email;
    }
    if (updateContactInfo.inputType == "phone") {
      payload.phone = updateContactInfo.phone;
    }
    if (updateContactInfo.enterOTP == "") {
      return setUpdateContactInfo((prev) => ({
        ...prev,
        errorOTP: "OTP is required",
      }));
    }
    const token = getDataFromCookies("7H3K9R5P2Q");
    const updateProfile = await fetch(
      `${API_HOST}/${endpoints.buyerUpdateEmailPhoneStep2}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );

    const updateProfileResJson = await updateProfile.json();
    if (
      updateProfileResJson.status == 500 ||
      updateProfileResJson.status == 501 ||
      updateProfileResJson.status == 502 ||
      updateProfileResJson.status == 503 ||
      (updateProfileResJson.message &&
        updateProfileResJson.message.toLowerCase().includes("server error"))
    ) {
      if (updateProfileResJson.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    setDisabledBtn((prev) => ({
      ...prev,
      requestOTPPhone: false,
      verifyOTP: false,
      requestOTPEmail: false,
      updateProfile: false,
      deleteAccount: false,
      changePassword: false,
      resendOTP: false,
    }));
    if (updateProfileResJson.status == 400) {
      showToast("error", Constants.alertMessages.alertForStatus400);
    }
    if (updateProfileResJson.status == 200) {
      showToast("success", updateProfileResJson.message);
      let userData = contextObj.params.userProfile;
      if (updateContactInfo.inputType == "email") {
        userData.email = updateContactInfo.email;
        values.email = updateContactInfo.email;
      }
      if (updateContactInfo.inputType == "phone") {
        userData.phone = updateContactInfo.phone;
        values.Phone = updateContactInfo.phone;
      }

      contextObj.setInput("userProfile", userData);

      setInputTagDeisable(false);

      setUpdateContactInfo((prev) => ({
        ...prev,
        addEmailPopup: false,
        addPhonePopup: false,
        showOTP: false,
        email: "",
        phone: "",
        otpToken: "",
        enterOTP: "",
        errorMSG: "",
        errorOTP: "",
        inputType: "",
      }));
      setIsActive(false);
      if (contextObj.params.updateProfileFromCheckout) {
        await contextObj.setInput("navigation", "true");
        await contextObj.setInput("navigationFrom", "updateProfile");

        await contextObj.setInput("updateProfileFromCheckout", false);
        navigate(navigation.navigateToCheckoutPage);
      }
      if (loggedInAfterUpdate == true) {
        logout();
        await contextObj.setInput("triggerReSetContextObj", true);
        navigate("/");
      } else {
        setReRender(!reRender);
      }
    }
    if (updateProfileResJson.status == 401) {
      setUpdateContactInfo((prev) => ({
        ...prev,

        errorOTP: "Invalid OTP",
      }));
    }
  };

  const resendOTP = async () => {
    const payload = {
      otp_verify_token: updateContactInfo.otpToken,
    };
    if (updateContactInfo.inputType == "email") {
      payload.email = updateContactInfo.email;
    }
    if (updateContactInfo.inputType == "phone") {
      payload.phone = updateContactInfo.phone;
    }
    const token = getDataFromCookies("7H3K9R5P2Q");
    const resendOTP = await fetch(
      `${API_HOST}/${endpoints.updateEmailPhoneResendOTP}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );

    const resendOTPResJson = await resendOTP.json();
    setDisabledBtn((prev) => ({
      ...prev,
      requestOTPPhone: false,
      verifyOTP: false,
      requestOTPEmail: false,
      deleteAccount: false,
      updateProfile: false,
      changePassword: false,
      resendOTP: false,
    }));
    if (
      resendOTPResJson.status == 500 ||
      resendOTPResJson.status == 501 ||
      resendOTPResJson.status == 502 ||
      resendOTPResJson.status == 503 ||
      (resendOTPResJson.message &&
        resendOTPResJson.message.toLowerCase().includes("server error"))
    ) {
      if (resendOTPResJson.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    if (resendOTPResJson.status == 201) {
      setIsActive(true);
      startTimer();
    }
  };

  const updateProfile = async () => {
    setErrorMessages((prev) => ({
      ...prev,
      errorFname: "",
      errorLname: "",
    }));

    let fName = values.Fname.trim();
    let lName = values.Lname !== null ? values.Lname.trim() : null;

    let errorFound = false;

    if (fName == "") {
      setUpdateContactInfo((prev) => ({
        ...prev,
        firstName: "",
      }));

      setErrorMessages((prev) => ({
        ...prev,
        errorFname: "First name is required.",
      }));
      errorFound = true;
    } else if (fName.length < 2 || fName.length > 50) {
      setUpdateContactInfo((prev) => ({
        ...prev,
        firstName: "",
      }));
      setErrorMessages((prev) => ({
        ...prev,
        errorFname: "First name must be 2-50 characters long.",
      }));
      errorFound = true;
    }

    if (
      lName !== null &&
      lName !== "" &&
      (lName.length < 2 || lName.length > 50)
    ) {
      setUpdateContactInfo((prev) => ({
        ...prev,
        lastName: "",
      }));

      setErrorMessages((prev) => ({
        ...prev,
        errorLname: "Last name must be 2-50 characters long.",
      }));
      errorFound = true;
    }
    if (errorFound) {
      return;
    }
    let payload = {
      f_name:
        updateContactInfo.firstName === ""
          ? values.Fname
          : updateContactInfo.firstName,
      l_name:
        updateContactInfo.lastName === ""
          ? values.Lname
          : updateContactInfo.lastName,
    };

    const token = getDataFromCookies("7H3K9R5P2Q");
    payload = JSON.stringify(payload);
    const updatedUserProfile = await fetch(
      `${API_HOST}/${endpoints.buyerProfile}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: payload,
      }
    );
    const updatedUserProfileResJson = await updatedUserProfile.json();
    if (
      updatedUserProfileResJson.status == 500 ||
      updatedUserProfileResJson.status == 501 ||
      updatedUserProfileResJson.status == 502 ||
      updatedUserProfileResJson.status == 503 ||
      (updatedUserProfileResJson.message &&
        updatedUserProfileResJson.message
          .toLowerCase()
          .includes("server error"))
    ) {
      if (updatedUserProfileResJson.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    if (updatedUserProfileResJson.status === 200) {
      contextObj.setInput("displayName", values.Fname.split(" ")[0]);
      contextObj.setInput("lastName", values.Lname);

      let userData = contextObj.params.userProfile;
      userData.f_name = values.Fname;
      userData.l_name = values.Lname;

      contextObj.setInput("userProfile", userData);
      showToast("success", Constants.alertMessages.alertForProfileUpdate);
      setDataToCookies("displayName", values.Fname, "/");
      setDataToCookies("lastName", values.Lname, "/");
      setEnableUpdateProfileBtn(false);
    }
    if (updatedUserProfileResJson.status == 401) {
      removeDataFromCookie("7H3K9R5P2Q", "/");
      removeDataFromCookie("isLoggedIn", "/");
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${navigation.navigateToHomepage}`);
    }

    setUpdateContactInfo((prev) => ({
      ...prev,
      AddFirstName: false,
      firstName: "",
      AddLastName: false,
      lastName: "",
      addEmailPopup: false,
      addPhonePopup: false,
      showOTP: false,
      enterOTP: "",
      otpToken: "",
      email: "",
      phone: "",
      errorOTP: "",
      errorMSG: "",
      inputType: "",
    }));
    setIsDataChanged(false);
    setReRender(!reRender);
  };

  // Change Password Code
  const HandleChangePassword = async () => {
    setErrorMessages((prev) => ({
      ...prev,
      new_password: "",
      new_password_confirmation: "",
    }));
    let errorFound = false;

    if (pass.new_password == "") {
      setErrorMessages((prev) => ({
        ...prev,
        new_password: "New Password is required.",
      }));
      errorFound = true;
    }
    if (pass.new_password_confirmation == "") {
      setErrorMessages((prev) => ({
        ...prev,
        new_password_confirmation: "Confirm New Password is required.",
      }));
      errorFound = true;
    }

    if (
      pass.new_password !== "" &&
      pass.new_password == pass.new_password_confirmation &&
      pass.new_password.length < 6
    ) {
      setErrorMessages((prev) => ({
        ...prev,
        new_password: "Password should be minimum 6 characters.",
      }));
      errorFound = true;
    }
    const validNewPassword = isPasswordValid(pass.new_password);
    const validConfimPassword = isPasswordValid(pass.new_password_confirmation);
    if (!validNewPassword && pass.new_password !== "") {
      setErrorMessages((prev) => ({
        ...prev,
        new_password: "Password requirements not met.",
      }));
      errorFound = true;
    }
    if (!validConfimPassword && pass.new_password_confirmation !== "") {
      setErrorMessages((prev) => ({
        ...prev,
        new_password_confirmation: "Password requirements not met.",
      }));
      errorFound = true;
    }
    if (
      pass.new_password !== pass.new_password_confirmation &&
      pass.new_password !== "" &&
      pass.new_password_confirmation !== ""
    ) {
      setErrorMessages((prev) => ({
        ...prev,
        new_password_confirmation:
          "New and Confirm New Password doesn't matches.",
      }));
      errorFound = true;
    }

    if (errorFound == true) {
      setDisabledBtn((prev) => ({
        ...prev,
        requestOTPPhone: false,
        verifyOTP: false,
        requestOTPEmail: false,
        updateProfile: false,
        deleteAccount: false,
        updateProfile: false,
        changePassword: false,
        resendOTP: false,
      }));
      return;
    }
    const token = getDataFromCookies("7H3K9R5P2Q");
    const updatePassword = await fetch(
      `${API_HOST}/${endpoints.buyerUpdatePassword}`, // update API
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        body: JSON.stringify(pass),
      }
    );

    const updatePasswordResJson = await updatePassword.json();
    if (
      updatePasswordResJson.status == 500 ||
      updatePasswordResJson.status == 501 ||
      updatePasswordResJson.status == 502 ||
      updatePasswordResJson.status == 503 ||
      (updatePasswordResJson.message &&
        updatePasswordResJson.message.toLowerCase().includes("server error"))
    ) {
      if (updatePasswordResJson.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    setDisabledBtn((prev) => ({
      ...prev,
      requestOTPPhone: false,
      verifyOTP: false,
      requestOTPEmail: false,
      updateProfile: false,
      deleteAccount: false,
      updateProfile: false,
      changePassword: false,
      resendOTP: false,
    }));
    if (updatePasswordResJson.status == 200) {
      contextObj.setInput("cartLength", 0);

      showToast("success", Constants.alertMessages.alertForPasswodUpdate);
      setErrorMessages((prev) => ({
        ...prev,
        new_password: "",
        new_password_confirmation: "",
      }));
      setPass((prev) => ({
        ...prev,
        new_password: "",
        new_password_confirmation: "",
      }));
      setChangePassPopup(false);
      setInputTagDeisable(false);
      logout();
      await contextObj.setInput("triggerReSetContextObj", true);
      navigate("/");
    }
    if (updatePasswordResJson.status == 409) {
      if (
        updatePasswordResJson.message ==
        Constants.alertMessages.alertForEnteringSamePasswordAsPrevious
      ) {
        setErrorMessages((prev) => ({
          ...prev,
          new_password: "",
          new_password_confirmation:
            "New Password cannot be same as Old Password",
        }));
      } else if (updatePasswordResJson.message == "Incorrect old password.") {
        setErrorMessages((prev) => ({
          ...prev,
          new_password: "",
          new_password_confirmation: "",
        }));
      }
    }
    if (updatePasswordResJson.status == 404) {
    }
  };

  const fetchProfileData = async () => {
    let profileData = contextObj.params.userProfile;
    if (profileData) {
      setValues({
        Fname: profileData.f_name,
        Lname: profileData.l_name,
        Phone: profileData.phone,
        email: profileData.email,
        referral_code: profileData.referral_code,
      });
      if (profileData.f_name !== null) {
        setName((prev) => ({
          ...prev,
          Fname: profileData.f_name,
        }));
      } else {
        setName((prev) => ({
          ...prev,
          Fname: "",
        }));
      }

      if (profileData.l_name !== null) {
        setName((prev) => ({
          ...prev,
          Lname: profileData.l_name,
        }));
      } else {
        setName((prev) => ({
          ...prev,
          Lname: "",
        }));
      }
      if (
        (profileData.email == null || profileData.email == "") &&
        contextObj.params.updateProfileFromCheckout
      ) {
        setUpdateContactInfo((prev) => ({
          ...prev,
          addEmailPopup: true,
          email: "",
        }));
      }

      if (
        (profileData.phone == null || profileData.phone == "") &&
        contextObj.params.updateProfileFromCheckout
      ) {
        setUpdateContactInfo((prev) => ({
          ...prev,
          addPhonePopup: true,
          phone: "",
        }));
      }

      if (profileData.phone) {
        setPhoneExist(true);
      }
      if (profileData.email) {
        setEmailExist(true);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (
      changePassPopup ||
      deleteAccountPopup ||
      updateContactInfo.addEmailPopup ||
      updateContactInfo.addPhonePopup ||
      showDeleteEditMobilePopup ||
      showDeleteEditEmailPopup ||
      removeChildDetails ||
      unableToDelete
    ) {
      document.body.style.overflow = "hidden"; // Disable scroll when the modal is open

      return () => {
        document.body.style.overflow = "auto"; // Enable scroll when the modal is closed
      };
    }
  }, [
    changePassPopup,
    deleteAccountPopup,
    updateContactInfo.addEmailPopup,
    updateContactInfo.addPhonePopup,
    showDeleteEditMobilePopup,
    showDeleteEditEmailPopup,
    removeChildDetails,
    unableToDelete,
  ]);

  useEffect(() => {
    const loggedIn = getDataFromCookies("isLoggedIn");
    if (loggedIn === true) {
      fetchProfileData();
    } else {
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${navigation.navigateToHomepage}`);
    }
  }, [reRender, contextObj.params.userProfile]);

  useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      resetTimer();
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const startTimer = () => {
    setIsActive(true);
  };

  const resetTimer = () => {
    setIsActive(false);
    setSeconds(60);
  };

  useEffect(() => {
    if (
      updateContactInfo.AddFirstName ||
      updateContactInfo.AddLastName ||
      updateContactInfo.addEmailPopup ||
      updateContactInfo.addEmailPopup
    ) {
      document.body.style.overflow = "hidden"; // Disable scroll when the modal is open
      document.body.style.WebkitOverflowScrolling = "hidden"; // Disable scroll when the modal is open
      return () => {
        document.body.style.overflow = "auto"; // Enable scroll when the modal is closed
        document.body.style.WebkitOverflowScrolling = "scroll"; // Enable scroll when the modal is closed
      };
    }
  }, [
    updateContactInfo.AddFirstName,
    updateContactInfo.AddLastName,
    updateContactInfo.addEmailPopup,
    updateContactInfo.addEmailPopup,
  ]);

  // select Button Value
  const handleSelect = (value) => {
    setSelectedValue(value);
    setChildrenData((prev) => ({
      ...prev,
      gender: value,
    }));
    setErrorChildrenMessage((prevData) => ({
      ...prevData,
      gender: "",
    }));
  };

  // Validation Level Changes for children Birthday

  const handelChange = (event) => {
    let { name, value } = event.target;

    if (name == "name") {
      if (name == "" || name.length <= 50) {
        setErrorChildrenMessage((prevData) => ({
          ...prevData,
          name: "",
        }));
        setChildrenData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      } else {
        if (name.length > 50) {
          setErrorChildrenMessage((prevData) => ({
            ...prevData,
            name: "Name must be 2-50 characters long.",
          }));
        }
      }
    }
    if (name === "dob") {
      if (name !== "") {
        setErrorChildrenMessage((prevData) => ({
          ...prevData,
          dob: "",
        }));
      }
      setChildrenData((prevData) => ({
        ...prevData,
        [name]: value.split("-").reverse().join("/"),
      }));
    }
  };

  // to convert Child dob string to calendar format
  function reverseChildrenData(data) {
    const reversedData = data.split("/").reverse().join("-");
    return reversedData;
  }
  // date min max ========>
  function calculateMinDate() {
    const today = new Date();
    const minDate = new Date(today);
    minDate.setFullYear(today.getFullYear() - 12); // 12 years ago from today
    return minDate.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
  }

  function calculateMaxDate() {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
  }
  // check date is older than 12 years or not and should not be greater than today

  // API For Add Children Birthday Details.
  const PostChildrenBirthdayDetails = async () => {
    //
    let errorFound = false;

    if (childrenData.name.trim() == "") {
      setErrorChildrenMessage((prevData) => ({
        ...prevData,
        name: Constants.alertMessages.alertForChildrenName,
      }));
      if (childrenData.name.trim() == "") {
        setChildrenData((prev) => ({
          ...prev,
          name: "",
        }));
      }
      errorFound = true;
    } else if (childrenData.name.length < 2 || childrenData.name.length > 50) {
      setErrorChildrenMessage((prevData) => ({
        ...prevData,
        name: "Name must be 2-50 characters long.",
      }));
      errorFound = true;
    }
    if (childrenData.dob == "" || childrenData.dob == null) {
      setErrorChildrenMessage((prevData) => ({
        ...prevData,
        dob: Constants.alertMessages.alertFordob,
      }));
      errorFound = true;
    } else {
      let validDOB = isDateValid(childrenData.dob);
      let isValidBirthday = isDateValidBirthday(childrenData.dob);

      if (!validDOB) {
        setErrorChildrenMessage((prevData) => ({
          ...prevData,
          dob: Constants.alertMessages.errorForValidDOB,
        }));
        errorFound = true;
      }
      if (!isValidBirthday) {
        setErrorChildrenMessage((prevData) => ({
          ...prevData,
          dob: Constants.alertMessages.errorForInvalidDOB,
        }));
        errorFound = true;
      }
    }
    if (childrenData.gender !== "Male" && childrenData.gender !== "Female") {
      setErrorChildrenMessage((prevData) => ({
        ...prevData,
        gender: Constants.alertMessages.alertForGender,
      }));
      errorFound = true;
    }
    if (errorFound) {
      return;
    }
    const Payload = JSON.stringify(childrenData);
    const token = getDataFromCookies("7H3K9R5P2Q");
    const response = await fetch(
      `${API_HOST}/${endpoints.postChildrenDetails}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: Payload,
      }
    );
    const addChildren = await response.json();
    if (
      addChildren.status == 500 ||
      addChildren.status == 501 ||
      addChildren.status == 502 ||
      addChildren.status == 503 ||
      (addChildren.message &&
        addChildren.message.toLowerCase().includes("server error"))
    ) {
      if (addChildren.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    if (addChildren.status == 201) {
      showToast("success", addChildren.message);

      getChildrenBirthdayList();

      //reset data
      setChildrenData((prev) => ({
        ...prev,
        name: "",
        dob: "",
        gender: "",
      }));
      setSelectedValue("");
      setErrorChildrenMessage((prevData) => ({
        ...prevData,
        name: "",
        dob: "",
        gender: "",
      }));
    } else if (addChildren.status == 409) {
      if (
        addChildren.message == Constants.alertMessages.errorForExistingChildName
      ) {
        setErrorChildrenMessage((prevData) => ({
          ...prevData,
          name: Constants.alertMessages.errorForExistingChildName,
        }));
      }

      return;
    }
  };

  const checkProfileStatus = async () => {
    const token = getDataFromCookies("7H3K9R5P2Q");
    if (token == null || token == "") {
      return;
    }
    const response = await fetch(
      `${API_HOST}/${endpoints.checkProfileStatus}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const checkProfileStatusRes = await response.json();
    setDisabledBtn((prev) => ({
      ...prev,
      checkProfileStatus: false,
    }));
    if (checkProfileStatusRes.status == 200) {
      setShowFeatures((prev) => ({
        ...prev,
        deleteAccount: true,
      }));

      if (values.email == null) {
        setDeleteSelectedValue("Phone");
      } else if (values.Phone == null) {
        setDeleteSelectedValue("Email");
      } else if (values.Phone !== null && values.email !== null) {
        setDeleteSelectedValue("Email");
      }

      setDeleteAccountPopup(true);
      setInputTagDeisable(true);
    } else if (checkProfileStatusRes.status == 403) {
      setUnableToDelete(true);
    }
  };

  // API For Get ALL Children BirthDay List
  const getChildrenBirthdayList = async () => {
    const token = getDataFromCookies("7H3K9R5P2Q");
    const response = await fetch(
      `${API_HOST}/${endpoints.getChildrenBirthdayList}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const childrenBirthday = await response.json();
    if (
      childrenBirthday.status == 500 ||
      childrenBirthday.status == 501 ||
      childrenBirthday.status == 502 ||
      childrenBirthday.status == 503 ||
      (childrenBirthday.message &&
        childrenBirthday.message.toLowerCase().includes("server error"))
    ) {
      if (childrenBirthday.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    if (childrenBirthday.status === 401) {
      removeDataFromCookie("7H3K9R5P2Q", "/");
      removeDataFromCookie("isLoggedIn", "/");
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${navigation.navigateToHomepage}`);
    }
    if (childrenBirthday.status === 200) {
      if (childrenBirthday.data) {
        const childrenData = childrenBirthday.data;
        if (childrenBirthday.data.length >= 4) {
          setActiveAddChildBtn(false);
        } else {
          setActiveAddChildBtn(true);
        }
        setChildrenList(childrenData);
        setInEditMode(false);
        setDontShowChildDiv(true);
        setShowAddChild(true);
        setShowChildrenListElement(true);
        if (childrenData.length > 0) {
        } else if (childrenData.length === 0) {
        }
      }
      setShowAddChild(true);
    } else if (childrenBirthday.status === 404) {
      setChildrenList([]);
      setInEditMode(false);
      setDontShowChildDiv(false);
    }
  };

  const handelOtpChange = (event) => {
    setOtpValidationMessage("");
    const enteredOTP = event.target.value;
    setOTP(enteredOTP);
  };
  // Code To Request OTP to Delete Account.

  const sendRequetOtpVerifation = async () => {
    setDeactivateAccountError("");
    const payload = {
      otp_send_via: "",
    };
    if (deleteSelectedValue == "Phone") {
      payload.phone = values.Phone;
    } else if (deleteSelectedValue == "Email") {
      payload.email = values.email;
    }
    const token = getDataFromCookies("7H3K9R5P2Q");
    const response = await fetch(
      `${API_HOST}/${endpoints.deactiveAccountSendOTP}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const deactivateAccount = await response.json();
    setDisabledBtn((prev) => ({
      ...prev,
      requestOTPDeleteAccount: false,
    }));
    if (
      deactivateAccount.status == 500 ||
      deactivateAccount.status == 501 ||
      deactivateAccount.status == 502 ||
      deactivateAccount.status == 503 ||
      (deactivateAccount.message &&
        deactivateAccount.message.toLowerCase().includes("server error"))
    ) {
      if (deactivateAccount.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    if (deactivateAccount.status == 201 || deactivateAccount.remember_token) {
      setShowFeatures((prev) => ({
        ...prev,
        deleteAccount: false,
      }));
      if (deleteSelectedValue == "Phone") {
        setShowDeleteEditMobilePopup(true);
      } else {
        setShowDeleteEditEmailPopup(true);
      }
      setOtpVerifyToken(deactivateAccount.data);
      setIsActive(true);
      startTimer();
    }

    if (deactivateAccount.status == 429) {
      setDeactivateAccountError(deactivateAccount.message);
    }
  };

  const handelOtpSubmit = async () => {
    const payload = {
      otp: "",
      otp_send_via: "",
      otp_verify_token: "",
    };

    let errorFound = false;
    if (otp == "" || otp == null) {
      setOtpValidationMessage(Constants.alertMessages.AlertForOtp);
      errorFound = true;
    }
    if (otp.length > 1 && otp.length < 4) {
      setOtpValidationMessage(Constants.alertMessages.alertForValidOtp);
      errorFound = true;
    }
    if (errorFound == true) {
      setDisabledBtn((prev) => ({
        ...prev,
        verifyOTPDeleteAccount: false,
      }));
      return;
    }
    payload.otp = otp;
    payload.otp_verify_token = otpVerifyToken;
    if (deleteSelectedValue == "Phone") {
      payload.phone = values.Phone;
    } else if (deleteSelectedValue == "Email") {
      payload.email = values.email;
    }
    const token = getDataFromCookies("7H3K9R5P2Q");
    const response = await fetch(
      `${API_HOST}/${endpoints.deactiveAccountVerify}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const deactivateAccount = await response.json();
    setDisabledBtn((prev) => ({
      ...prev,
      verifyOTPDeleteAccount: false,
    }));
    setOTP("");
    if (
      deactivateAccount.status == 500 ||
      deactivateAccount.status == 501 ||
      deactivateAccount.status == 502 ||
      deactivateAccount.status == 503 ||
      (deactivateAccount.message &&
        deactivateAccount.message.toLowerCase().includes("server error"))
    ) {
      if (deactivateAccount.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    if (deactivateAccount.status == 200) {
      await contextObj.setInput("userProfile", null);

      showToast("success", deactivateAccount.message);
      contextObj.setInput("displayName", "");
      contextObj.setInput("lastName", "");
      await contextObj.setInput("cartLength", 0);

      await contextObj.setInput("isLoggedIn", false);
      removeDataFromCookie("7H3K9R5P2Q", "/");
      removeDataFromCookie("isLoggedIn", "/");

      contextObj.setInput("displayName", "");
      setAccountDeleted(true);
    }
    if (deactivateAccount.status == 400 && deactivateAccount.errors) {
      if (deactivateAccount.errors.otp) {
        setOtpValidationMessage(deactivateAccount.errors.otp[0]);
      }
    } else {
      setOtpValidationMessage(deactivateAccount.message);
    }
  };

  // Account deleted time
  useEffect(() => {
    if (accountDeleted == true) {
      const redirectTimer = setTimeout(() => {
        navigate("/");
      }, 3000);
      return () => clearTimeout(redirectTimer);
    }
  }, [accountDeleted]);

  const resendVerifyOtp = async () => {
    const payload = {
      otp_send_via: "",
      otp_verify_token: "",
    };
    payload.otp_verify_token = otpVerifyToken;
    if (deleteSelectedValue == "Phone") {
      payload.phone = values.Phone;
    } else if (deleteSelectedValue == "Email") {
      payload.email = values.email;
    }
    const token = getDataFromCookies("7H3K9R5P2Q");
    const response = await fetch(`${API_HOST}/${endpoints.deactiveResendOTP}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    const otpSendMessage = await response.json();
    if (
      otpSendMessage.status == 500 ||
      otpSendMessage.status == 501 ||
      otpSendMessage.status == 502 ||
      otpSendMessage.status == 503 ||
      (otpSendMessage.message &&
        otpSendMessage.message.toLowerCase().includes("server error"))
    ) {
      if (otpSendMessage.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    if (otpSendMessage.status == 201) {
      setOtpVerifyToken(otpSendMessage.data);

      setIsActive(true);
      startTimer();
    }
    if (otpSendMessage.status == 429) {
      setOtpValidationMessage(otpSendMessage.message);
    }
  };

  const handleCopy = () => {
    const textarea = document.createElement("textarea");
    textarea.value = values.referral_code;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    showToast("success", Constants.alertMessages.alertForReferralCodeCopy);
  };

  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>Your Profile - JOYREJOY</title>
          <meta
            name="description"
            content="Your profile at JOYREJOY is where the fun begins! Update your details, track orders, and manage your wishlist with ease. Let's make playtime even more delightful together!"
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}
      {contextObj.params.serviceUnavailable == true ? (
        <ServiceUnavailable maintainanceMode={maintainanceMode} />
      ) : (
        <div className="pageParentDiv pageParentDivColoredBackground">
          <div className="allBoxContainer">
            {accountDeleted ? (
              <AccountDelete />
            ) : (
              <div className="buyerMainProfileInformationPage">
                <div className="buyerMainDivForNavProfileInfoSectioncontainer">
                  <div className="buyerProfileInfoMainDivForNavSection">
                    <MemoizedUserSideNavbar />
                  </div>
                  <div className="buyerMainDivForProfileSection">
                    {unableToDelete && (
                      <div className="ModalContainer">
                        <div className="ModalContent">
                          <div className="Profile_Update_email_popup">
                            <div
                              className="crossbutton"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <FontAwesomeIcon
                                id="deactivateCancleButton"
                                icon={faXmark}
                                style={{
                                  color: "#a6a6a6",
                                  width: "18px",
                                  height: "18px",
                                }}
                                onClick={() => {
                                  setUnableToDelete(false);
                                }}
                              />
                            </div>

                            <div className="unableToDeleteModalTextHolder">
                              <p className="unableToDeleteModalText">
                                Thank you for reaching out to deactivate your
                                account. While we're sorry to see you leave, we
                                respect your decision. Please bear with us as we
                                finalize any pending orders on your behalf
                                before processing your request. Your patience is
                                greatly appreciated, and we're committed to
                                ensuring a smooth transition for you. Rest
                                assured, we'll have everything sorted out for
                                you shortly!
                              </p>
                            </div>

                            <div className="updateCancleBtn">
                              <button
                                className="updateyesbutton"
                                onClick={() => {
                                  setUnableToDelete(false);
                                }}
                              >
                                Okay, Sure
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="pageListHeaderContainer">
                      <p className="PageListTitle">Profile Information</p>
                    </div>
                    {loading ? (
                      <ProfileSkeleton />
                    ) : (
                      <>
                        <div className="profileContainerBox">
                          <div className="basicUserInfoContainer">
                            <div className="ProfileNameClass">
                              <div>
                                <InputControl
                                  label={<span>First Name</span>}
                                  inputTagId="userFirstName"
                                  type="text"
                                  value={values.Fname}
                                  onChange={(e) => {
                                    setEnableUpdateProfileBtn(true);
                                    setErrorMessages((prev) => ({
                                      ...prev,
                                      errorFname: "",
                                    }));
                                    if (
                                      e.target.value.length <= 50 ||
                                      e.target.value == ""
                                    ) {
                                      setValues((prev) => ({
                                        ...prev,
                                        Fname: e.target.value,
                                      }));
                                      setUpdateContactInfo((prev) => ({
                                        ...prev,
                                        firstName: "",
                                      }));
                                    } else {
                                      if (e.target.value.length > 50) {
                                        setErrorMessages((prev) => ({
                                          ...prev,
                                          errorFname:
                                            "First name must be 2-50 characters long.",
                                        }));
                                      }
                                    }
                                  }}
                                  error={errorMessages.errorFname}
                                />
                              </div>

                              <InputControl
                                label={<span>Last Name</span>}
                                inputTagId="userLastName"
                                type="text"
                                value={values.Lname}
                                onChange={(e) => {
                                  setEnableUpdateProfileBtn(true);
                                  setErrorMessages((prev) => ({
                                    ...prev,
                                    errorLname: "",
                                  }));

                                  if (
                                    e.target.value.length <= 50 ||
                                    e.target.value == ""
                                  ) {
                                    setValues((prev) => ({
                                      ...prev,
                                      Lname: e.target.value,
                                    }));
                                    setUpdateContactInfo((prev) => ({
                                      ...prev,
                                      lastName: "",
                                    }));
                                  } else {
                                    if (e.target.value.length > 50) {
                                      setErrorMessages((prev) => ({
                                        ...prev,
                                        errorLname:
                                          "Last name must be 2-50 characters long.",
                                      }));
                                    }
                                  }
                                }}
                                error={errorMessages.errorLname}
                              />
                            </div>

                            <div className="profilemobileupadtebuttoncontainer">
                              <button
                                className={
                                  enableUpdateProfileBtn
                                    ? "UpdateProfileButton"
                                    : "UpdateProfileButton disabledElement"
                                }
                                id="UpdateUserNameButton"
                                style={{
                                  // opacity: enableUpdateProfileBtn ? 1 : 0.5,
                                  cursor: enableUpdateProfileBtn
                                    ? "pointer"
                                    : "not-allowed",
                                }}
                                onClick={() => {
                                  if (enableUpdateProfileBtn) {
                                    setEnableUpdateProfileBtn(false);
                                    updateProfile();
                                  }
                                }}
                              >
                                Update Profile
                              </button>
                            </div>
                          </div>
                          {<hr className="HrLineProfileinfoPage" />}
                          <div className="AccountDetailsDiv">
                            <h1 className="ChildrensHeaginTag">
                              Account Details{" "}
                            </h1>
                            <div className="EmailMobileNoAddContainer">
                              <div className="ProfileMobileitemContainer">
                                <InputControl
                                  inputTagId="EmailAddress"
                                  label={<span>Email Address</span>}
                                  type="text"
                                  readOnly
                                  value={values.email}
                                  error={errorMessages.errorEmail}
                                  id="emailAddressInputTag"
                                />
                                <button
                                  id="emailAddressInputEditButton"
                                  className="email-password-eye-icons profilepassword-eye-icons button-profile-tabindex"
                                  onClick={() => {
                                    setIsDataChanged(false);
                                    if (values.email !== null) {
                                      LoggedInOutAfterUpdate(true);
                                    } else {
                                      LoggedInOutAfterUpdate(false);
                                    }
                                    setUpdateContactInfo((prev) => ({
                                      ...prev,
                                      addEmailPopup: true,
                                      email: "",
                                    }));
                                    setInputTagDeisable(true);
                                  }}
                                >
                                  <svg
                                    style={{ color: "ea5e3d" }}
                                    width="16"
                                    height="17"
                                    viewBox="0 0 12 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2.00432 11.0312C1.63242 11.0312 1.28491 10.9032 1.03494 10.6654C0.717916 10.3666 0.565499 9.91549 0.620369 9.42776L0.845947 7.45243C0.888624 7.08053 1.1142 6.5867 1.37636 6.31845L6.38175 1.02045C7.63157 -0.30253 8.93626 -0.339111 10.2592 0.910712C11.5822 2.16053 11.6188 3.46523 10.369 4.78821L5.3636 10.0862C5.10754 10.3606 4.63199 10.6166 4.26009 10.6776L2.29696 11.0129C2.19332 11.019 2.10187 11.0312 2.00432 11.0312ZM8.33879 0.904615C7.86934 0.904615 7.46086 1.19726 7.04629 1.63622L2.0409 6.94037C1.91896 7.0684 1.77874 7.37324 1.75435 7.55004L1.52878 9.52531C1.50439 9.7265 1.55316 9.89111 1.6629 9.99475C1.77264 10.0984 1.93725 10.135 2.13845 10.1045L4.10158 9.76923C4.27838 9.73875 4.57103 9.58018 4.69296 9.45215L9.69835 4.15415C10.4543 3.34939 10.7287 2.60559 9.62519 1.56915C9.13745 1.09971 8.71678 0.904615 8.33879 0.904615Z"
                                      fill="#888888"
                                    />
                                    <path
                                      d="M9.19847 5.80701C9.18628 5.80701 9.16799 5.80701 9.15579 5.80701C7.25362 5.61801 5.72336 4.17313 5.43071 2.28315C5.39413 2.03319 5.56484 1.80152 5.81481 1.75884C6.06477 1.72226 6.29644 1.89297 6.33912 2.14293C6.5708 3.61833 7.76575 4.75232 9.25334 4.89864C9.5033 4.92302 9.68621 5.1486 9.66182 5.39853C9.63133 5.63021 9.43014 5.80701 9.19847 5.80701Z"
                                      fill="#888888"
                                    />
                                    <path
                                      d="M11.4313 13.0004H0.457252C0.207288 13.0004 0 12.7932 0 12.5432C0 12.2932 0.207288 12.0859 0.457252 12.0859H11.4313C11.6813 12.0859 11.8885 12.2932 11.8885 12.5432C11.8885 12.7932 11.6813 13.0004 11.4313 13.0004Z"
                                      fill="#888888"
                                    />
                                  </svg>
                                </button>
                              </div>
                              <div className="ProfileMobileitemContainer">
                                <InputControl
                                  label={<span>Mobile Number</span>}
                                  inputTagId="MobileNumber"
                                  type="text"
                                  value={values.Phone}
                                  readOnly
                                  error={errorMessages.errorPhone}
                                  id="mplifyAddMobileDIV"
                                />
                                <button
                                  id="mobileEditDiv"
                                  className="profilepassword-eye-icons button-profile-tabindex"
                                  onClick={() => {
                                    setIsDataChanged(false);
                                    if (values.Phone !== null) {
                                      LoggedInOutAfterUpdate(true);
                                    } else {
                                      LoggedInOutAfterUpdate(false);
                                    }
                                    setUpdateContactInfo((prev) => ({
                                      ...prev,
                                      addPhonePopup: true,
                                      phone: "",
                                    }));
                                    setInputTagDeisable(true);
                                  }}
                                >
                                  <svg
                                    style={{ color: "#ea5e3d" }}
                                    width="16"
                                    height="17"
                                    viewBox="0 0 12 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2.00432 11.0312C1.63242 11.0312 1.28491 10.9032 1.03494 10.6654C0.717916 10.3666 0.565499 9.91549 0.620369 9.42776L0.845947 7.45243C0.888624 7.08053 1.1142 6.5867 1.37636 6.31845L6.38175 1.02045C7.63157 -0.30253 8.93626 -0.339111 10.2592 0.910712C11.5822 2.16053 11.6188 3.46523 10.369 4.78821L5.3636 10.0862C5.10754 10.3606 4.63199 10.6166 4.26009 10.6776L2.29696 11.0129C2.19332 11.019 2.10187 11.0312 2.00432 11.0312ZM8.33879 0.904615C7.86934 0.904615 7.46086 1.19726 7.04629 1.63622L2.0409 6.94037C1.91896 7.0684 1.77874 7.37324 1.75435 7.55004L1.52878 9.52531C1.50439 9.7265 1.55316 9.89111 1.6629 9.99475C1.77264 10.0984 1.93725 10.135 2.13845 10.1045L4.10158 9.76923C4.27838 9.73875 4.57103 9.58018 4.69296 9.45215L9.69835 4.15415C10.4543 3.34939 10.7287 2.60559 9.62519 1.56915C9.13745 1.09971 8.71678 0.904615 8.33879 0.904615Z"
                                      fill="#888888"
                                    />
                                    <path
                                      d="M9.19847 5.80701C9.18628 5.80701 9.16799 5.80701 9.15579 5.80701C7.25362 5.61801 5.72336 4.17313 5.43071 2.28315C5.39413 2.03319 5.56484 1.80152 5.81481 1.75884C6.06477 1.72226 6.29644 1.89297 6.33912 2.14293C6.5708 3.61833 7.76575 4.75232 9.25334 4.89864C9.5033 4.92302 9.68621 5.1486 9.66182 5.39853C9.63133 5.63021 9.43014 5.80701 9.19847 5.80701Z"
                                      fill="#888888"
                                    />
                                    <path
                                      d="M11.4313 13.0004H0.457252C0.207288 13.0004 0 12.7932 0 12.5432C0 12.2932 0.207288 12.0859 0.457252 12.0859H11.4313C11.6813 12.0859 11.8885 12.2932 11.8885 12.5432C11.8885 12.7932 11.6813 13.0004 11.4313 13.0004Z"
                                      fill="#888888"
                                    />
                                  </svg>
                                </button>
                              </div>
                              {updateContactInfo.addEmailPopup && (
                                <div className="ModalContainer">
                                  <div className="ModalContent">
                                    {updateContactInfo.showOTP == false ? (
                                      <div className="Profile_Update_email_popup">
                                        <div
                                          className="crossbutton"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <button
                                            className="close-profile-popup-button"
                                            onClick={async () => {
                                              if (
                                                contextObj.params
                                                  .updateProfileFromCheckout
                                              ) {
                                                await contextObj.setInput(
                                                  "navigation",
                                                  "true"
                                                );
                                                await contextObj.setInput(
                                                  "navigationFrom",
                                                  "updateProfile"
                                                );
                                                await contextObj.setInput(
                                                  "updateProfileFromCheckout",
                                                  false
                                                );

                                                navigate(
                                                  navigation.navigateToCheckoutPage
                                                );
                                              } else {
                                                LoggedInOutAfterUpdate(false);
                                                setUpdateContactInfo(
                                                  (prev) => ({
                                                    ...prev,
                                                    addEmailPopup: false,
                                                    email: "",
                                                    errorMSG: "",
                                                  })
                                                );
                                                setInputTagDeisable(false);
                                                setIsActive(false);
                                              }
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              // id="closeUpdateEmailPopup"
                                              icon={faXmark}
                                              style={{
                                                position: "relative",
                                                color: "#a6a6a6",
                                                width: "18px",
                                                height: "18px",
                                                top: "-20px",
                                              }}
                                            />
                                          </button>
                                        </div>
                                        <h1 className="headingpopupemail">
                                          Update Email Address
                                        </h1>
                                        <div className="popupContainer">
                                          <InputControl
                                            inputTagId="newEmailUser"
                                            label={
                                              <span
                                                style={{ color: "#7c7c7c" }}
                                              >
                                                New Email Address
                                              </span>
                                            }
                                            autoFocus
                                            onKeyPress={(event) => {
                                              if (isDataChanged) {
                                                if (
                                                  event.key === "Enter" ||
                                                  event.key === 13
                                                ) {
                                                  event.preventDefault();
                                                  if (
                                                    !disabledBtn.requestOTPEmail
                                                  ) {
                                                    setDisabledBtn((prev) => ({
                                                      ...prev,
                                                      requestOTPEmail: true,
                                                    }));

                                                    requestOTP("email");
                                                  }
                                                }
                                              }
                                            }}
                                            value={updateContactInfo.email}
                                            onChange={(event) => {
                                              if (
                                                !disabledBtn.requestOTPEmail
                                              ) {
                                                setIsDataChanged(true);
                                                setUpdateContactInfo(
                                                  (prev) => ({
                                                    ...prev,
                                                    email:
                                                      event.target.value.trim(),
                                                    errorMSG: "",
                                                    inputType: "email",
                                                  })
                                                );
                                              }
                                            }}
                                            error={updateContactInfo.errorMSG}
                                          />

                                          <div className="updateCancleBtn">
                                            {isActive ? (
                                              <button
                                                id="requestOtpDetailButton"
                                                className="disabledBtn hover-pointer"
                                              >
                                                Request OTP in{" "}
                                                {formatTime(seconds)}
                                              </button>
                                            ) : (
                                              <button
                                                id="requestOtpDetailButton"
                                                className={
                                                  !isDataChanged
                                                    ? "disabledBtn hover-pointer"
                                                    : "yesbutton"
                                                }
                                                onClick={() => {
                                                  if (
                                                    !disabledBtn.requestOTPEmail
                                                  ) {
                                                    setDisabledBtn((prev) => ({
                                                      ...prev,
                                                      requestOTPEmail: true,
                                                    }));
                                                    requestOTP("email");
                                                  }
                                                }}
                                                disabled={!isDataChanged}
                                              >
                                                {disabledBtn.requestOTPEmail &&
                                                isDataChanged ? (
                                                  <img
                                                    src={loadingImgPath}
                                                    alt="Loading"
                                                    className="disabledBtn-Loading"
                                                  />
                                                ) : (
                                                  "Request OTP"
                                                )}
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="Profile_Update_email_popup">
                                        <div
                                          className="crossbutton"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <button
                                            className="close-profile-popup-button"
                                            onClick={async () => {
                                              if (
                                                contextObj.params
                                                  .updateProfileFromCheckout
                                              ) {
                                                await contextObj.setInput(
                                                  "navigation",
                                                  "true"
                                                );
                                                await contextObj.setInput(
                                                  "navigationFrom",
                                                  "updateProfile"
                                                );
                                                await contextObj.setInput(
                                                  "updateProfileFromCheckout",
                                                  false
                                                );

                                                navigate(
                                                  navigation.navigateToCheckoutPage
                                                );
                                              } else {
                                                setUpdateContactInfo(
                                                  (prev) => ({
                                                    ...prev,
                                                    addEmailPopup: false,
                                                    showOTP: false,
                                                    email: "",
                                                    phone: "",
                                                    otpToken: "",
                                                    enterOTP: "",
                                                    errorMSG: "",
                                                    errorOTP: "",
                                                    inputType: "",
                                                  })
                                                );
                                                setInputTagDeisable(false);
                                                setIsActive(false);
                                              }
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faXmark}
                                              style={{
                                                position: "relative",
                                                color: "#a6a6a6",
                                                width: "18px",
                                                height: "18px",
                                                top: "-20px",
                                              }}
                                            />
                                          </button>
                                        </div>
                                        <h1 className="headingpopupemail">
                                          Update Email Address
                                        </h1>
                                        <div className="popupContainer">
                                          <div className="Profile_DeleteAccount_container">
                                            <InputControl
                                              inputTagId="NewEmailAddress"
                                              label={
                                                <span
                                                  style={{ color: "#7c7c7c" }}
                                                >
                                                  New Email Address
                                                </span>
                                              }
                                              value={updateContactInfo.email}
                                              readOnly
                                              error={
                                                updateContactInfo.errorEmail
                                              }
                                            />
                                            <svg
                                              onClick={() => {
                                                setUpdateContactInfo(
                                                  (prev) => ({
                                                    ...prev,
                                                    addEmailPopup: true,
                                                    showOTP: false,
                                                    enterOTP: "",
                                                    otpToken: "",
                                                  })
                                                );
                                                setDisabledBtn((prev) => ({
                                                  ...prev,
                                                  requestOTPPhone: false,
                                                }));
                                                setIsDataChanged(true);
                                              }}
                                              className="Profile_info_Delete_Account_svg"
                                              width="18"
                                              height="18"
                                              viewBox="0 0 12 13"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M2.00432 11.0312C1.63242 11.0312 1.28491 10.9032 1.03494 10.6654C0.717916 10.3666 0.565499 9.91549 0.620369 9.42776L0.845947 7.45243C0.888624 7.08053 1.1142 6.5867 1.37636 6.31845L6.38175 1.02045C7.63157 -0.30253 8.93626 -0.339111 10.2592 0.910712C11.5822 2.16053 11.6188 3.46523 10.369 4.78821L5.3636 10.0862C5.10754 10.3606 4.63199 10.6166 4.26009 10.6776L2.29696 11.0129C2.19332 11.019 2.10187 11.0312 2.00432 11.0312ZM8.33879 0.904615C7.86934 0.904615 7.46086 1.19726 7.04629 1.63622L2.0409 6.94037C1.91896 7.0684 1.77874 7.37324 1.75435 7.55004L1.52878 9.52531C1.50439 9.7265 1.55316 9.89111 1.6629 9.99475C1.77264 10.0984 1.93725 10.135 2.13845 10.1045L4.10158 9.76923C4.27838 9.73875 4.57103 9.58018 4.69296 9.45215L9.69835 4.15415C10.4543 3.34939 10.7287 2.60559 9.62519 1.56915C9.13745 1.09971 8.71678 0.904615 8.33879 0.904615Z"
                                                fill="#888888"
                                              />
                                              <path
                                                d="M9.19847 5.80701C9.18628 5.80701 9.16799 5.80701 9.15579 5.80701C7.25362 5.61801 5.72336 4.17313 5.43071 2.28315C5.39413 2.03319 5.56484 1.80152 5.81481 1.75884C6.06477 1.72226 6.29644 1.89297 6.33912 2.14293C6.5708 3.61833 7.76575 4.75232 9.25334 4.89864C9.5033 4.92302 9.68621 5.1486 9.66182 5.39853C9.63133 5.63021 9.43014 5.80701 9.19847 5.80701Z"
                                                fill="#888888"
                                              />
                                              <path
                                                d="M11.4313 13.0004H0.457252C0.207288 13.0004 0 12.7932 0 12.5432C0 12.2932 0.207288 12.0859 0.457252 12.0859H11.4313C11.6813 12.0859 11.8885 12.2932 11.8885 12.5432C11.8885 12.7932 11.6813 13.0004 11.4313 13.0004Z"
                                                fill="#888888"
                                              />
                                            </svg>
                                          </div>
                                          <InputControl
                                            inputTagId="OTP"
                                            label={<span>OTP</span>}
                                            maxLength={4}
                                            autoFocus
                                            onKeyPress={(event) => {
                                              if (
                                                event.key === "Enter" ||
                                                event.key === 13
                                              ) {
                                                event.preventDefault();
                                                LoggedInOutAfterUpdate(true);
                                                verifyOTP();
                                              }
                                            }}
                                            value={updateContactInfo.enterOTP}
                                            onChange={(event) => {
                                              setUpdateContactInfo((prev) => ({
                                                ...prev,
                                                errorOTP: "",
                                              }));
                                              const otp =
                                                event.target.value.trim();
                                              const validFormat =
                                                isOTPFormat(otp);
                                              if (validFormat || otp == "") {
                                                setUpdateContactInfo(
                                                  (prev) => ({
                                                    ...prev,
                                                    enterOTP: otp,
                                                    errorOTP: "",
                                                  })
                                                );
                                              } else {
                                                setUpdateContactInfo(
                                                  (prev) => ({
                                                    ...prev,
                                                    errorOTP:
                                                      "Only numbers are allowed",
                                                  })
                                                );
                                              }
                                            }}
                                            error={updateContactInfo.errorOTP}
                                          />
                                          <div className="updateMobileNumberResendOTPBtn">
                                            <div
                                              style={{
                                                color: "#3366cc",
                                                cursor: isActive
                                                  ? "default"
                                                  : "pointer",
                                                zIndex: 1,
                                              }}
                                            >
                                              <button
                                                className="updateMobileNumberResendOTPBtn-txt tabIndexBtn"
                                                onClick={() => {
                                                  if (
                                                    !isActive &&
                                                    !disabledBtn.resendOTP
                                                  ) {
                                                    setDisabledBtn((prev) => ({
                                                      ...prev,
                                                      resendOTP: true,
                                                    }));
                                                    resendOTP();
                                                  }
                                                }}
                                                style={{
                                                  color: "#3366cc",
                                                  cursor: isActive
                                                    ? "default"
                                                    : "pointer",
                                                  zIndex: 1,
                                                }}
                                              >
                                                {isActive
                                                  ? `Resend OTP after ${" "}
                                                ${formatTime(seconds)}`
                                                  : "Resend OTP"}
                                              </button>
                                            </div>
                                          </div>
                                          <div className="updateCancleBtn">
                                            <button
                                              id="verifyOTP"
                                              className="yesbutton"
                                              onClick={() => {
                                                if (!disabledBtn.verifyOTP) {
                                                  setDisabledBtn((prev) => ({
                                                    ...prev,
                                                    verifyOTP: true,
                                                  }));
                                                  LoggedInOutAfterUpdate(true);
                                                  verifyOTP();
                                                }
                                              }}
                                            >
                                              {disabledBtn.requestOTPEmail ? (
                                                <img
                                                  src={loadingImgPath}
                                                  alt="Loading"
                                                  className="disabledBtn-Loading"
                                                />
                                              ) : (
                                                "Verify OTP"
                                              )}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              {updateContactInfo.addPhonePopup == true && (
                                <div className="ModalContainer">
                                  <div className="ModalContent">
                                    {updateContactInfo.showOTP == false ? (
                                      <div className="Profile_Update_email_popup">
                                        <div
                                          className="crossbutton"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <button
                                            className="close-profile-popup-button"
                                            onClick={async () => {
                                              if (
                                                contextObj.params
                                                  .updateProfileFromCheckout
                                              ) {
                                                await contextObj.setInput(
                                                  "navigation",
                                                  "true"
                                                );
                                                await contextObj.setInput(
                                                  "navigationFrom",
                                                  "updateProfile"
                                                );
                                                await contextObj.setInput(
                                                  "updateProfileFromCheckout",
                                                  false
                                                );

                                                navigate(
                                                  navigation.navigateToCheckoutPage
                                                );
                                              } else {
                                                LoggedInOutAfterUpdate(false);
                                                setUpdateContactInfo(
                                                  (prev) => ({
                                                    ...prev,
                                                    AddFirstName: false,
                                                    firstName: "",
                                                    AddLastName: false,
                                                    lastName: "",
                                                    addEmailPopup: false,
                                                    addPhonePopup: false,
                                                    showOTP: false,
                                                    enterOTP: "",
                                                    otpToken: "",
                                                    email: "",
                                                    phone: "",
                                                    errorOTP: "",
                                                    errorMSG: "",
                                                    inputType: "",
                                                  })
                                                );
                                                setInputTagDeisable(false);
                                                setIsActive(false);
                                              }
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              id="mobileDivCloseButton"
                                              icon={faXmark}
                                              style={{
                                                position: "relative",
                                                color: "#a6a6a6",
                                                width: "18px",
                                                height: "18px",
                                                top: "-20px",
                                              }}
                                            />
                                          </button>
                                        </div>
                                        <h1 className="headingpopupemail">
                                          Update Mobile Number
                                        </h1>
                                        <div className="popupContainer">
                                          <InputControl
                                            inputTagId="updateMobileIdDiv"
                                            id="updateMobileIdDiv"
                                            label={
                                              <span
                                                style={{ color: "#7c7c7c" }}
                                              >
                                                Mobile Number
                                              </span>
                                            }
                                            autoFocus
                                            value={updateContactInfo.phone}
                                            maxLength={10}
                                            onKeyPress={(event) => {
                                              if (isDataChanged) {
                                                if (
                                                  event.key === "Enter" ||
                                                  event.key === 13
                                                ) {
                                                  event.preventDefault();

                                                  if (
                                                    !disabledBtn.requestOTPPhone
                                                  ) {
                                                    setDisabledBtn((prev) => ({
                                                      ...prev,
                                                      requestOTPPhone: true,
                                                    }));
                                                    requestOTP("phone");
                                                  }
                                                }
                                              }
                                            }}
                                            onChange={(event) => {
                                              if (
                                                !disabledBtn.requestOTPPhone
                                              ) {
                                                setIsDataChanged(true);
                                                const inputValue =
                                                  event.target.value.trim();
                                                const validFormat =
                                                  isPhoneNumberFormat(
                                                    inputValue
                                                  );
                                                if (
                                                  !validFormat &&
                                                  inputValue.length > 0
                                                ) {
                                                  setUpdateContactInfo(
                                                    (prev) => ({
                                                      ...prev,

                                                      inputType: "",
                                                      errorMSG:
                                                        "Only numbers are allowed",
                                                    })
                                                  );
                                                } else if (validFormat) {
                                                  setUpdateContactInfo(
                                                    (prev) => ({
                                                      ...prev,
                                                      phone: inputValue,
                                                      inputType: "Phone",
                                                      errorMSG: "",
                                                    })
                                                  );
                                                }
                                                if (inputValue.length == 0) {
                                                  setUpdateContactInfo(
                                                    (prev) => ({
                                                      ...prev,
                                                      phone: "",
                                                      inputType: "",
                                                      errorMSG: "",
                                                    })
                                                  );
                                                }
                                                if (inputValue.length == 10) {
                                                  const validNumber =
                                                    isPhoneNumber(inputValue);
                                                  if (!validNumber) {
                                                    setUpdateContactInfo(
                                                      (prev) => ({
                                                        ...prev,
                                                        inputType: "",
                                                        errorMSG:
                                                          "Invalid phone number.",
                                                      })
                                                    );
                                                  }
                                                }
                                              }
                                            }}
                                            error={updateContactInfo.errorMSG}
                                          />

                                          <div className="updateCancleBtn">
                                            {isActive ? (
                                              <button
                                                id="updateMobileButtonDiv"
                                                className="disabledBtn hover-pointer"
                                              >
                                                Request OTP in{" "}
                                                {formatTime(seconds)}
                                              </button>
                                            ) : (
                                              <button
                                                id="updateMobileButtonDiv"
                                                className={
                                                  !isDataChanged
                                                    ? "disabledBtn hover-pointer"
                                                    : "yesbutton"
                                                }
                                                onClick={() => {
                                                  if (
                                                    !disabledBtn.requestOTPPhone
                                                  ) {
                                                    setDisabledBtn((prev) => ({
                                                      ...prev,
                                                      requestOTPPhone: true,
                                                    }));
                                                    requestOTP("phone");
                                                  }
                                                }}
                                                disabled={!isDataChanged}
                                              >
                                                {disabledBtn.requestOTPPhone ? (
                                                  <img
                                                    src={loadingImgPath}
                                                    alt="Loading"
                                                    className="disabledBtn-Loading"
                                                  />
                                                ) : (
                                                  "Request OTP"
                                                )}
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="Profile_Update_email_popup">
                                        <div
                                          className="crossbutton"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <button
                                            className="close-profile-popup-button"
                                            onClick={async () => {
                                              if (
                                                contextObj.params
                                                  .updateProfileFromCheckout
                                              ) {
                                                await contextObj.setInput(
                                                  "navigation",
                                                  "true"
                                                );
                                                await contextObj.setInput(
                                                  "navigationFrom",
                                                  "updateProfile"
                                                );
                                                // window.history.back();
                                                await contextObj.setInput(
                                                  "updateProfileFromCheckout",
                                                  false
                                                );

                                                navigate(
                                                  navigation.navigateToCheckoutPage
                                                );
                                              } else {
                                                setUpdateContactInfo(
                                                  (prev) => ({
                                                    ...prev,
                                                    addPhonePopup: false,
                                                    showOTP: false,
                                                    email: "",
                                                    phone: "",
                                                    otpToken: "",
                                                    enterOTP: "",
                                                    errorMSG: "",
                                                    errorOTP: "",
                                                    inputType: "",
                                                  })
                                                );
                                                setInputTagDeisable(false);
                                                setIsActive(false);
                                              }
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faXmark}
                                              style={{
                                                position: "relative",
                                                color: "#a6a6a6",
                                                width: "18px",
                                                height: "18px",
                                                top: "-20px",
                                              }}
                                            />
                                          </button>
                                        </div>
                                        <h1 className="headingpopupemail">
                                          Update Mobile
                                        </h1>
                                        <div className="popupContainer">
                                          <div className="Profile_DeleteAccount_container">
                                            <InputControl
                                              inputTagId="Phone"
                                              label={
                                                <span
                                                  style={{ color: "#7c7c7c" }}
                                                >
                                                  Phone
                                                </span>
                                              }
                                              value={updateContactInfo.phone}
                                              readOnly
                                              error={updateContactInfo.errorMSG}
                                            />
                                            <svg
                                              onClick={() => {
                                                setUpdateContactInfo(
                                                  (prev) => ({
                                                    ...prev,
                                                    addPhonePopup: true,
                                                    showOTP: false,
                                                    enterOTP: "",
                                                    otpToken: "",
                                                  })
                                                );
                                                setDisabledBtn((prev) => ({
                                                  ...prev,
                                                  requestOTPPhone: false,
                                                }));
                                                setIsDataChanged(true);
                                              }}
                                              className="Profile_info_Delete_Account_svg"
                                              width="18"
                                              height="18"
                                              viewBox="0 0 12 13"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M2.00432 11.0312C1.63242 11.0312 1.28491 10.9032 1.03494 10.6654C0.717916 10.3666 0.565499 9.91549 0.620369 9.42776L0.845947 7.45243C0.888624 7.08053 1.1142 6.5867 1.37636 6.31845L6.38175 1.02045C7.63157 -0.30253 8.93626 -0.339111 10.2592 0.910712C11.5822 2.16053 11.6188 3.46523 10.369 4.78821L5.3636 10.0862C5.10754 10.3606 4.63199 10.6166 4.26009 10.6776L2.29696 11.0129C2.19332 11.019 2.10187 11.0312 2.00432 11.0312ZM8.33879 0.904615C7.86934 0.904615 7.46086 1.19726 7.04629 1.63622L2.0409 6.94037C1.91896 7.0684 1.77874 7.37324 1.75435 7.55004L1.52878 9.52531C1.50439 9.7265 1.55316 9.89111 1.6629 9.99475C1.77264 10.0984 1.93725 10.135 2.13845 10.1045L4.10158 9.76923C4.27838 9.73875 4.57103 9.58018 4.69296 9.45215L9.69835 4.15415C10.4543 3.34939 10.7287 2.60559 9.62519 1.56915C9.13745 1.09971 8.71678 0.904615 8.33879 0.904615Z"
                                                fill="#888888"
                                              />
                                              <path
                                                d="M9.19847 5.80701C9.18628 5.80701 9.16799 5.80701 9.15579 5.80701C7.25362 5.61801 5.72336 4.17313 5.43071 2.28315C5.39413 2.03319 5.56484 1.80152 5.81481 1.75884C6.06477 1.72226 6.29644 1.89297 6.33912 2.14293C6.5708 3.61833 7.76575 4.75232 9.25334 4.89864C9.5033 4.92302 9.68621 5.1486 9.66182 5.39853C9.63133 5.63021 9.43014 5.80701 9.19847 5.80701Z"
                                                fill="#888888"
                                              />
                                              <path
                                                d="M11.4313 13.0004H0.457252C0.207288 13.0004 0 12.7932 0 12.5432C0 12.2932 0.207288 12.0859 0.457252 12.0859H11.4313C11.6813 12.0859 11.8885 12.2932 11.8885 12.5432C11.8885 12.7932 11.6813 13.0004 11.4313 13.0004Z"
                                                fill="#888888"
                                              />
                                            </svg>
                                          </div>
                                          <InputControl
                                            inputTagId="OTP"
                                            label={<span>OTP</span>}
                                            maxLength={4}
                                            onKeyPress={(event) => {
                                              if (
                                                event.key === "Enter" ||
                                                event.key === 13
                                              ) {
                                                event.preventDefault();
                                                LoggedInOutAfterUpdate(true);
                                                verifyOTP();
                                              }
                                            }}
                                            autoFocus
                                            value={updateContactInfo.enterOTP}
                                            onChange={(event) => {
                                              setUpdateContactInfo((prev) => ({
                                                ...prev,
                                                errorOTP: "",
                                              }));
                                              const otp =
                                                event.target.value.trim();
                                              const validFormat =
                                                isOTPFormat(otp);
                                              if (validFormat || otp == "") {
                                                setUpdateContactInfo(
                                                  (prev) => ({
                                                    ...prev,
                                                    enterOTP: otp,
                                                    errorOTP: "",
                                                  })
                                                );
                                              } else {
                                                setUpdateContactInfo(
                                                  (prev) => ({
                                                    ...prev,
                                                    errorOTP:
                                                      "Only numbers are allowed",
                                                  })
                                                );
                                              }
                                            }}
                                            error={updateContactInfo.errorOTP}
                                          />
                                          <div className="updateMobileNumberResendOTPBtn">
                                            <div
                                              style={{
                                                cursor: isActive
                                                  ? "default"
                                                  : "pointer",
                                                zIndex: 1,
                                              }}
                                            >
                                              <button
                                                className="updateMobileNumberResendOTPBtn-txt tabIndexBtn"
                                                style={{
                                                  color: "#3366cc",
                                                  cursor: isActive
                                                    ? "default"
                                                    : "pointer",
                                                }}
                                                onClick={() => {
                                                  if (
                                                    !isActive &&
                                                    !disabledBtn.resendOTP
                                                  ) {
                                                    setDisabledBtn((prev) => ({
                                                      ...prev,
                                                      resendOTP: true,
                                                    }));
                                                    resendOTP();
                                                  }
                                                }}
                                              >
                                                {isActive
                                                  ? `Resend OTP after ${" "}
                                                ${formatTime(seconds)}`
                                                  : "Resend OTP"}
                                              </button>
                                            </div>
                                          </div>
                                          <div className="updateCancleBtn">
                                            <button
                                              id="verifyOTP"
                                              className="yesbutton"
                                              onClick={() => {
                                                if (!disabledBtn.verifyOTP) {
                                                  setDisabledBtn((prev) => ({
                                                    ...prev,
                                                    verifyOTP: true,
                                                  }));
                                                  LoggedInOutAfterUpdate(true);
                                                  verifyOTP();
                                                }
                                              }}
                                            >
                                              {disabledBtn.requestOTPEmail ? (
                                                <img
                                                  src={loadingImgPath}
                                                  alt="Loading"
                                                  className="disabledBtn-Loading"
                                                />
                                              ) : (
                                                "Verify OTP"
                                              )}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          {showFeatures.showChildrenDetails ? (
                            <>
                              <hr className="HrLineProfileinfoPage" />
                              <div className="ChildrenBirthDayDiv">
                                <h1 className="ChildrensHeaginTag">
                                  Children's Birthday
                                </h1>

                                {DontShowChildDiv == false ? (
                                  <>
                                    <div className="ChildAddBirthContainer">
                                      <li className="AddInfoLineDetails">
                                        To avail of a special discount, we
                                        kindly request you to share your
                                        children's birthdates with us. Thank you
                                        for considering this!
                                      </li>
                                      <button
                                        id="addInfoButtonProfile"
                                        className="AddInformationButton"
                                        onClick={() => {
                                          setErrorChildrenMessage(
                                            (prevData) => ({
                                              ...prevData,
                                              name: "",
                                              dob: "",
                                              gender: "",
                                            })
                                          );
                                          setDontShowChildDiv(true);
                                          setShowAddChild(false);
                                          setUpdateDataErrors((prevData) => ({
                                            ...prevData,
                                            name: "",
                                            dob: "",
                                            gender: "",
                                          }));
                                        }}
                                      >
                                        Add Info
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {showChildrenListElement && (
                                      <>
                                        {childrenList &&
                                          childrenList.map((data, index) => (
                                            <div key={data.id}>
                                              <div id="childDetails">
                                                <div
                                                  className="ProfileNameClassButton"
                                                  id="ChildrenDetailsContainers"
                                                  onMouseEnter={() =>
                                                    setFocusDivId(data.id)
                                                  }
                                                  onMouseLeave={() =>
                                                    setFocusDivId(null)
                                                  }
                                                >
                                                  <div>
                                                    <InputControl
                                                      id="childNameIdForProfile"
                                                      inputTagId="childNameIdForProfile"
                                                      label={<span>Name</span>}
                                                      type="text"
                                                      value={
                                                        inEditMode &&
                                                        editData.id == data.id
                                                          ? editData.name
                                                          : data.name
                                                      }
                                                      name="name"
                                                      onClick={() => {
                                                        if (
                                                          data.id !==
                                                          editData.id
                                                        ) {
                                                          setEditData(
                                                            (prev) => ({
                                                              ...prev,
                                                              id: data.id,
                                                              name: data.name,
                                                              dob: data.dob,
                                                              gender:
                                                                data.gender,
                                                            })
                                                          );
                                                          setDataChanged(
                                                            (prev) => ({
                                                              ...prev,
                                                              dataUpdated: false,
                                                              dataUpdatedId:
                                                                data.id,
                                                            })
                                                          );
                                                          setUpdateDataErrors(
                                                            (prevData) => ({
                                                              ...prevData,
                                                              name: "",
                                                              dob: "",
                                                              gender: "",
                                                            })
                                                          );
                                                        }
                                                        if (!inEditMode) {
                                                          setInEditMode(true);
                                                          if (
                                                            editData.id !==
                                                            data.id
                                                          ) {
                                                            setEditData(
                                                              (prev) => ({
                                                                ...prev,
                                                                name: data.name,
                                                                dob: data.dob,
                                                                id: data.id,
                                                                gender:
                                                                  data.gender,
                                                              })
                                                            );
                                                          } else {
                                                            setEditData(
                                                              (prev) => ({
                                                                ...prev,
                                                                name: editData.name,
                                                                gender:
                                                                  editData.gender,
                                                                dob: editData.dob,
                                                              })
                                                            );
                                                          }
                                                        }
                                                      }}
                                                      onChange={(e) => {
                                                        setShowAddChild(true);
                                                        setDataChanged(
                                                          (prev) => ({
                                                            ...prev,
                                                            dataUpdated: true,
                                                            dataUpdatedId:
                                                              data.id,
                                                          })
                                                        );
                                                        setUpdateDataErrors(
                                                          (prevData) => ({
                                                            ...prevData,
                                                            name: "",
                                                          })
                                                        );
                                                        let name =
                                                          e.target.value;

                                                        if (
                                                          name == "" ||
                                                          name.length <= 50
                                                        ) {
                                                          setEditData(
                                                            (prevData) => ({
                                                              ...prevData,
                                                              id: data.id,
                                                              name: name,
                                                            })
                                                          );
                                                        } else if (
                                                          name.length > 50
                                                        ) {
                                                          setUpdateDataErrors(
                                                            (prevData) => ({
                                                              ...prevData,
                                                              name: "Name must be 2-50 characters long.",
                                                            })
                                                          );
                                                        }
                                                      }}
                                                      error={
                                                        editData.id == data.id
                                                          ? updateDataErrors.name
                                                          : ""
                                                      }
                                                    />
                                                  </div>
                                                  <div>
                                                    <InputControl
                                                      label={
                                                        <span>Birthday</span>
                                                      }
                                                      id="childBirthdayInputFeilds"
                                                      inputTagId="childBirthdayInputFeilds"
                                                      type="Date"
                                                      name="dob"
                                                      value={
                                                        inEditMode &&
                                                        editData.id == data.id
                                                          ? reverseChildrenData(
                                                              editData.dob
                                                            )
                                                          : reverseChildrenData(
                                                              data.dob
                                                            )
                                                      }
                                                      onClick={() => {
                                                        if (!inEditMode) {
                                                          setInEditMode(true);
                                                          if (
                                                            editData.id !==
                                                            data.id
                                                          ) {
                                                            setEditData(
                                                              (prev) => ({
                                                                ...prev,
                                                                name: data.name,
                                                                dob: data.dob,
                                                                id: data.id,
                                                                gender:
                                                                  data.gender,
                                                              })
                                                            );
                                                          } else {
                                                            setEditData(
                                                              (prev) => ({
                                                                ...prev,
                                                                name: editData.name,
                                                                gender:
                                                                  editData.gender,
                                                                dob: editData.dob,
                                                              })
                                                            );
                                                          }
                                                        }
                                                      }}
                                                      onChange={(e) => {
                                                        setShowAddChild(true);
                                                        setUpdateDataErrors(
                                                          (prevData) => ({
                                                            ...prevData,
                                                            dob: "",
                                                          })
                                                        );
                                                        setDataChanged(
                                                          (prev) => ({
                                                            ...prev,
                                                            dataUpdated: true,
                                                            dataUpdatedId:
                                                              data.id,
                                                          })
                                                        );
                                                        if (
                                                          editData.id !==
                                                          data.id
                                                        ) {
                                                          setEditData(
                                                            (prev) => ({
                                                              ...prev,
                                                              name: data.name,
                                                              dob: e.target.value
                                                                .split("-")
                                                                .reverse()
                                                                .join("/"),
                                                              id: data.id,
                                                              gender:
                                                                data.gender,
                                                            })
                                                          );
                                                        } else {
                                                          setEditData(
                                                            (prevData) => ({
                                                              ...prevData,
                                                              id: data.id,
                                                              dob: e.target.value
                                                                .split("-")
                                                                .reverse()
                                                                .join("/"),
                                                            })
                                                          );
                                                        }
                                                      }}
                                                      min={calculateMinDate()}
                                                      max={calculateMaxDate()}
                                                      error={
                                                        editData.id == data.id
                                                          ? updateDataErrors.dob
                                                          : ""
                                                      }
                                                    />
                                                  </div>

                                                  <div className="updateGenderOptionsHolder">
                                                    <div className="ProfileGenderButtonContainer">
                                                      <div
                                                        name="gender"
                                                        className={`genderOptionsBtn ${
                                                          inEditMode &&
                                                          data.id == editData.id
                                                            ? editData.gender ===
                                                              "Male"
                                                              ? "selectedGenderOption"
                                                              : ""
                                                            : data.gender ===
                                                              "Male"
                                                            ? "selectedGenderOption"
                                                            : ""
                                                        }`}
                                                        onClick={() => {
                                                          setShowAddChild(true);
                                                          setDataChanged(
                                                            (prev) => ({
                                                              ...prev,
                                                              dataUpdated: true,
                                                              dataUpdatedId:
                                                                data.id,
                                                            })
                                                          );
                                                          setInEditMode(true);
                                                          if (
                                                            editData.id !==
                                                            data.id
                                                          ) {
                                                            setEditData(
                                                              (prev) => ({
                                                                ...prev,
                                                                name: data.name,
                                                                dob: data.dob,
                                                                id: data.id,
                                                                gender: "Male",
                                                              })
                                                            );
                                                          } else {
                                                            setEditData(
                                                              (prev) => ({
                                                                ...prev,
                                                                name: editData.name,
                                                                gender: "Male",
                                                                dob: editData.dob,
                                                              })
                                                            );
                                                          }
                                                        }}
                                                      >
                                                        Boy
                                                      </div>
                                                      <div
                                                        name="gender"
                                                        className={`genderOptionsBtn ${
                                                          inEditMode &&
                                                          data.id == editData.id
                                                            ? editData.gender ===
                                                              "Female"
                                                              ? "selectedGenderOption"
                                                              : ""
                                                            : data.gender ===
                                                              "Female"
                                                            ? "selectedGenderOption"
                                                            : ""
                                                        }`}
                                                        onClick={() => {
                                                          setShowAddChild(true);
                                                          setDataChanged(
                                                            (prev) => ({
                                                              ...prev,
                                                              dataUpdated: true,
                                                              dataUpdatedId:
                                                                data.id,
                                                            })
                                                          );
                                                          setInEditMode(true);
                                                          if (
                                                            editData.id !==
                                                            data.id
                                                          ) {
                                                            setEditData(
                                                              (prev) => ({
                                                                ...prev,
                                                                name: data.name,
                                                                dob: data.dob,
                                                                id: data.id,
                                                                gender:
                                                                  "Female",
                                                              })
                                                            );
                                                          } else {
                                                            setEditData(
                                                              (prev) => ({
                                                                ...prev,
                                                                name: editData.name,
                                                                gender:
                                                                  "Female",
                                                                dob: editData.dob,
                                                              })
                                                            );
                                                          }
                                                        }}
                                                      >
                                                        Girl
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="ProfileChildrenEditSaveChangesDiv">
                                                    {dataChanged.dataUpdatedId ==
                                                      data.id &&
                                                    dataChanged.dataUpdated ==
                                                      true ? (
                                                      <>
                                                        <div
                                                          className="SaveChangesButton updateBtn"
                                                          style={{
                                                            opacity:
                                                              editData.id ==
                                                              data.id
                                                                ? 1
                                                                : 0.5,
                                                          }}
                                                          onClick={() => {
                                                            if (
                                                              editData.id ==
                                                              data.id
                                                            ) {
                                                              updateBirthdayData();
                                                            } else {
                                                              showToast(
                                                                "info",
                                                                "Please save the updated child details."
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          Save Changes
                                                        </div>
                                                        <div
                                                          onClick={() => {
                                                            setEditData(
                                                              (prev) => ({
                                                                ...prev,
                                                                name: "",
                                                                dob: "",
                                                                id: "",
                                                                gender: "",
                                                              })
                                                            );
                                                            setUpdateDataErrors(
                                                              (prevData) => ({
                                                                ...prevData,
                                                                name: "",
                                                                dob: "",
                                                                gender: "",
                                                              })
                                                            );
                                                            setDataChanged(
                                                              (prev) => ({
                                                                ...prev,
                                                                dataUpdated: false,
                                                                dataUpdatedId:
                                                                  "",
                                                              })
                                                            );
                                                            setInEditMode(
                                                              false
                                                            );
                                                            if (
                                                              childrenList.length >
                                                              0
                                                            ) {
                                                              setShowAddChild(
                                                                true
                                                              );
                                                            } else {
                                                              setDontShowChildDiv(
                                                                false
                                                              );
                                                            }
                                                          }}
                                                          className="SaveChangesButton"
                                                          id="SaveChangesButton-cancel"
                                                        >
                                                          Cancel
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <div
                                                        className="SaveChangesButton removeBtn"
                                                        id={`removeChildBtn_${index}`}
                                                        onClick={() => {
                                                          handelDeleteChild(
                                                            data.id
                                                          );
                                                        }}
                                                      >
                                                        <svg
                                                          width="13"
                                                          height="13"
                                                          viewBox="0 0 13 13"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            d="M3.25 1.3C3.25 0.955218 3.38696 0.624558 3.63076 0.380761C3.87456 0.136964 4.20522 0 4.55 0H8.45C8.79478 0 9.12544 0.136964 9.36924 0.380761C9.61304 0.624558 9.75 0.955218 9.75 1.3V2.6H12.35C12.5224 2.6 12.6877 2.66848 12.8096 2.79038C12.9315 2.91228 13 3.07761 13 3.25C13 3.42239 12.9315 3.58772 12.8096 3.70962C12.6877 3.83152 12.5224 3.9 12.35 3.9H11.6551L11.0916 11.7923C11.0683 12.1203 10.9215 12.4272 10.6809 12.6513C10.4403 12.8754 10.1237 13 9.79485 13H3.2045C2.87569 13 2.55909 12.8754 2.31848 12.6513C2.07786 12.4272 1.9311 12.1203 1.90775 11.7923L1.3455 3.9H0.65C0.477609 3.9 0.312279 3.83152 0.190381 3.70962C0.0684819 3.58772 0 3.42239 0 3.25C0 3.07761 0.0684819 2.91228 0.190381 2.79038C0.312279 2.66848 0.477609 2.6 0.65 2.6H3.25V1.3ZM4.55 2.6H8.45V1.3H4.55V2.6ZM2.6481 3.9L3.20515 11.7H9.7955L10.3525 3.9H2.6481ZM5.2 5.2C5.37239 5.2 5.53772 5.26848 5.65962 5.39038C5.78152 5.51228 5.85 5.67761 5.85 5.85V9.75C5.85 9.92239 5.78152 10.0877 5.65962 10.2096C5.53772 10.3315 5.37239 10.4 5.2 10.4C5.02761 10.4 4.86228 10.3315 4.74038 10.2096C4.61848 10.0877 4.55 9.92239 4.55 9.75V5.85C4.55 5.67761 4.61848 5.51228 4.74038 5.39038C4.86228 5.26848 5.02761 5.2 5.2 5.2ZM7.8 5.2C7.97239 5.2 8.13772 5.26848 8.25962 5.39038C8.38152 5.51228 8.45 5.67761 8.45 5.85V9.75C8.45 9.92239 8.38152 10.0877 8.25962 10.2096C8.13772 10.3315 7.97239 10.4 7.8 10.4C7.62761 10.4 7.46228 10.3315 7.34038 10.2096C7.21848 10.0877 7.15 9.92239 7.15 9.75V5.85C7.15 5.67761 7.21848 5.51228 7.34038 5.39038C7.46228 5.26848 7.62761 5.2 7.8 5.2Z"
                                                            fill="#797979"
                                                          />
                                                        </svg>
                                                        <span
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Remove
                                                        </span>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              {removeChildDetails &&
                                                removeChildId === data.id && (
                                                  <div className="ModalContainer">
                                                    <div className="ModalContent">
                                                      <div className="delete-confirmation-popup">
                                                        <div
                                                          className="crossbuttondiv"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          <FontAwesomeIcon
                                                            icon={faXmark}
                                                            style={{
                                                              color: " #A3A3A3",
                                                              width: "18px",
                                                              height: "18px",
                                                            }}
                                                            onClick={
                                                              handelCancelDeleteChildId
                                                            }
                                                          />
                                                        </div>
                                                        <div className="popupContainer-RemoveFromList">
                                                          <h5 className="headingmessageDeleteaddress">
                                                            Remove Child
                                                            Details?
                                                          </h5>
                                                          <p>
                                                            Are you sure you
                                                            want to remove the
                                                            details of{" "}
                                                            <span
                                                              style={{
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              {" "}
                                                              {data.name}{" "}
                                                            </span>
                                                            from your profile?
                                                          </p>
                                                          <div className="buttonDiv">
                                                            <button
                                                              className="button2"
                                                              onClick={() => {
                                                                deleteBirthdayData(
                                                                  data.id
                                                                );
                                                              }}
                                                            >
                                                              Yes
                                                            </button>
                                                            <button
                                                              className=" profilechild_button_remove"
                                                              onClick={
                                                                handelCancelDeleteChildId
                                                              }
                                                            >
                                                              No
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                            </div>
                                          ))}
                                      </>
                                    )}

                                    {!showAddChild ? (
                                      <div>
                                        <div id="childDetails">
                                          <div
                                            className="ProfileNameClassButton"
                                            id="ChildrenDetailsContainers"
                                          >
                                            <div>
                                              <InputControl
                                                label={<span>Name</span>}
                                                inputTagId="Name"
                                                autoFocus
                                                type="text"
                                                name="name"
                                                value={childrenData.name}
                                                onChange={(e) => {
                                                  setErrorChildrenMessage(
                                                    (prevData) => ({
                                                      ...prevData,
                                                      name: "",
                                                    })
                                                  );
                                                  let name = e.target.value;

                                                  if (
                                                    name == "" ||
                                                    name.length <= 50
                                                  ) {
                                                    setChildrenData(
                                                      (prevData) => ({
                                                        ...prevData,
                                                        name: name,
                                                      })
                                                    );
                                                  } else if (name.length > 50) {
                                                    setErrorChildrenMessage(
                                                      (prevData) => ({
                                                        ...prevData,
                                                        name: "Name must be 2-50 characters long.",
                                                      })
                                                    );
                                                  }
                                                }}
                                                error={
                                                  errorChildrenMessage.name
                                                }
                                              />
                                            </div>

                                            <div>
                                              <InputControl
                                                label={<span>Birthday</span>}
                                                inputTagId="Birthday"
                                                type="Date"
                                                name="dob"
                                                value={reverseChildrenData(
                                                  childrenData.dob
                                                )}
                                                onChange={handelChange}
                                                error={errorChildrenMessage.dob}
                                                min={calculateMinDate()}
                                                max={calculateMaxDate()}
                                              />
                                            </div>
                                            {/* <div>
                                              <label className="Profile_Joyrejoy_lable_tag">
                                                <input
                                                  autoFocus
                                                  className="Profile_Joyrejoy_input_tag"
                                                  placeholder="Birthday"
                                                  type="Date"
                                                  name="dob"
                                                  value={reverseChildrenData(
                                                    childrenData.dob
                                                  )}
                                                  onChange={handelChange}
                                                  min={calculateMinDate()}
                                                  max={calculateMaxDate()}
                                                />
                                                <span className="Profile_Joyrejoy_span_tag">
                                                  {" "}
                                                  Birthday
                                                </span>
                                              </label>
                                            </div> */}
                                            <div className="AddChildGenderSection">
                                              <div className="genderOptionsHolder">
                                                <button
                                                  onClick={() => {
                                                    handleSelect("Male");
                                                  }}
                                                  className={
                                                    selectedValue === "Male"
                                                      ? "selectedGenderOption genderOptionsBtn"
                                                      : " genderOptionsBtn"
                                                  }
                                                >
                                                  Boy
                                                </button>
                                                <button
                                                  onClick={() => {
                                                    handleSelect("Female");
                                                  }}
                                                  className={
                                                    selectedValue === "Female"
                                                      ? "selectedGenderOption genderOptionsBtn"
                                                      : " genderOptionsBtn"
                                                  }
                                                >
                                                  Girl
                                                </button>
                                              </div>
                                              <div className="gendererror">
                                                {errorChildrenMessage.gender}
                                              </div>
                                            </div>
                                            <div className="ProfileChildrenEditSaveChangesDiv">
                                              <button
                                                onClick={() => {
                                                  PostChildrenBirthdayDetails();
                                                }}
                                                className="SaveChangesButton"
                                                id="SaveChangesButton-save"
                                              >
                                                Save
                                              </button>
                                              <button
                                                onClick={() => {
                                                  setChildrenData((prev) => ({
                                                    ...prev,
                                                    name: "",
                                                    dob: "",
                                                    gender: "",
                                                  }));
                                                  setSelectedValue("");
                                                  setErrorChildrenMessage(
                                                    (prevData) => ({
                                                      ...prevData,
                                                      name: "",
                                                      dob: "",
                                                      gender: "",
                                                    })
                                                  );

                                                  if (childrenList.length > 0) {
                                                    setShowAddChild(true);
                                                  } else {
                                                    setDontShowChildDiv(false);
                                                  }
                                                }}
                                                className="SaveChangesButton"
                                                id="SaveChangesButton-cancel"
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <div>
                                          <button
                                            className={
                                              activeAddChildBtn
                                                ? "AddmoreButton"
                                                : "AddmoreButton disabledElement"
                                            }
                                            style={{
                                              // opacity: activeAddChildBtn
                                              //   ? 1
                                              //   : 0.5,
                                              cursor: activeAddChildBtn
                                                ? "pointer"
                                                : "not-allowed",
                                            }}
                                            onClick={() => {
                                              if (activeAddChildBtn) {
                                                setChildrenData((prev) => ({
                                                  ...prev,
                                                  name: "",
                                                  dob: "",
                                                  gender: "",
                                                }));
                                                setEditData((prev) => ({
                                                  ...prev,
                                                  id: "",
                                                  name: "",
                                                  dob: "",
                                                  gender: "",
                                                }));
                                                setSelectedValue("");
                                                setErrorChildrenMessage(
                                                  (prevData) => ({
                                                    ...prevData,
                                                    name: "",
                                                    dob: "",
                                                    gender: "",
                                                  })
                                                );
                                                setUpdateDataErrors(
                                                  (prevData) => ({
                                                    ...prevData,
                                                    name: "",
                                                    dob: "",
                                                    gender: "",
                                                  })
                                                );
                                                setShowAddChild(false);
                                                setDataChanged((prev) => ({
                                                  ...prev,
                                                  dataUpdated: false,
                                                  dataUpdatedId: "",
                                                }));
                                              }
                                            }}
                                          >
                                            <span id="addMoreChildBtn">+</span>{" "}
                                            Add More
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {(showFeatures.referralCode ||
                            showFeatures.changePassword ||
                            showFeatures.deleteAccount) && (
                            <hr className="HrLineProfileinfoPage" />
                          )}
                          <div className="ContainerButtonforaccountandDeactivate">
                            {showFeatures.referralCode && (
                              <div className="ReferAndEarnContainer">
                                <div className="ButtonContainer">
                                  <li className="ChangePasswordline">
                                    {" "}
                                    Refer & Earn
                                  </li>
                                  <button
                                    onClick={() => {
                                      handleCopy();
                                    }}
                                    className="referandEarnButton"
                                  >
                                    {values.referral_code}
                                  </button>
                                </div>
                                <div>
                                  <li className="listStyleforChangePasswordAndDeactivateAccount">
                                    Our toys are meant to be shared. Refer
                                    friends and collect great rewards.
                                  </li>
                                </div>
                              </div>
                            )}
                            {showFeatures.changePassword && (
                              <div
                                className="ChangePasswordContainer"
                                style={{
                                  marginRight: "40px",
                                }}
                              >
                                <div className="ButtonContainer">
                                  <li className="ChangePasswordline">
                                    {" "}
                                    Password
                                  </li>
                                  <button
                                    onClick={() => {
                                      setEnableUpdatePassBtn(false);
                                      setShowUpdatePassword(false);
                                      setChangePassPopup(true);
                                      setInputTagDeisable(true);
                                    }}
                                    className="ChangePasswordButton"
                                    id="profileInfoChangePasswordBtn"
                                  >
                                    Change
                                  </button>
                                </div>
                                <div>
                                  <li className="listStyleforChangePasswordAndDeactivateAccount">
                                    Level up your password security! Click here
                                    to change your password.
                                  </li>
                                </div>
                              </div>
                            )}
                            {showFeatures.deleteAccount && (
                              <div
                                className="DeactivateAccountContainer"
                                id="DeactivateAccountContainer"
                              >
                                <div className="ButtonContainer">
                                  <li className="RemoveAccountText">
                                    {" "}
                                    Remove Account
                                  </li>
                                  <button
                                    onClick={() => {
                                      if (!disabledBtn.checkProfileStatus) {
                                        setDisabledBtn((prev) => ({
                                          ...prev,
                                          checkProfileStatus: true,
                                        }));
                                        checkProfileStatus();
                                      }
                                    }}
                                    // style={{
                                    //   opacity: disabledBtn.checkProfileStatus
                                    //     ? 0.5
                                    //     : 1,
                                    // }}
                                    className={
                                      disabledBtn.checkProfileStatus
                                        ? "disabledElement DeactivateAccountButton"
                                        : "DeactivateAccountButton"
                                    }
                                    id={"DeactivateAccountButton"}
                                  >
                                    Deactivate
                                  </button>
                                </div>
                                <div>
                                  <li
                                    className="listStyleforChangePasswordAndDeactivateAccount"
                                    id={"DeactivateAccountMessage"}
                                  >
                                    Once deleted, we might not be able to
                                    restore your account. To continue, kindly
                                    confirm your decision.
                                  </li>
                                </div>
                              </div>
                            )}

                            {changePassPopup || deleteAccountPopup ? (
                              <>
                                <div
                                  className="ModalContainer"
                                  onClick={(e) => {}}
                                >
                                  <div className="ModalContentDelete">
                                    {changePassPopup &&
                                    showFeatures.changePassword ? (
                                      <div
                                        className="Profile_Update_email_popup"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                        }}
                                      >
                                        <div
                                          className="crossbutton"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <button
                                            className="close-profile-popup-button"
                                            onClick={() => {
                                              setErrorMessages((prev) => ({
                                                ...prev,
                                                new_password: "",
                                                new_password_confirmation: "",
                                              }));
                                              setPass((prev) => ({
                                                ...prev,
                                                new_password: "",
                                                new_password_confirmation: "",
                                              }));
                                              setChangePassPopup(false);
                                              setInputTagDeisable(false);
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faXmark}
                                              style={{
                                                position: "relative",
                                                color: "#a6a6a6",
                                                width: "18px",
                                                height: "18px",
                                                top: "-20px",
                                              }}
                                              //id="profileInfoUpdatePasswordCloseModalBtn"
                                            />
                                          </button>
                                        </div>
                                        <h4 className="Updateheadingmessage">
                                          Update Your Password
                                        </h4>
                                        <div className="Profile_Update_popupContainer">
                                          <InputControl
                                            inputTagId={
                                              "profileInfoUpdatePasswordNewPassword"
                                            }
                                            label={
                                              <span>
                                                New Password
                                                <span className="required">
                                                  *
                                                </span>
                                              </span>
                                            }
                                            type={
                                              showUpdatePassword
                                                ? "text"
                                                : "password"
                                            }
                                            autoFocus
                                            value={pass.new_password}
                                            maxLength={16}
                                            onChange={(event) => {
                                              setErrorMessages((prev) => ({
                                                ...prev,
                                                new_password: "",
                                              }));
                                              setPass((prev) => ({
                                                ...prev,
                                                new_password:
                                                  event.target.value.trim(),
                                              }));
                                            }}
                                            error={errorMessages.new_password}
                                          />

                                          {/* Confirm Password Input */}
                                          <div className="password-line">
                                            <InputControl
                                              inputTagId={
                                                "profileInfoUpdatePasswordConfirmNewPassword"
                                              }
                                              label={
                                                <span>
                                                  Confirm New Password
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </span>
                                              }
                                              type={
                                                showUpdatePassword
                                                  ? "text"
                                                  : "password"
                                              }
                                              value={
                                                pass.new_password_confirmation
                                              }
                                              maxLength={16}
                                              onChange={(event) => {
                                                setErrorMessages((prev) => ({
                                                  ...prev,
                                                  new_password_confirmation: "",
                                                }));
                                                setPass((prev) => ({
                                                  ...prev,
                                                  new_password_confirmation:
                                                    event.target.value.trim(),
                                                }));
                                              }}
                                              onKeyPress={(event) => {
                                                if (
                                                  event.key === "Enter" ||
                                                  event.key === 13
                                                ) {
                                                  event.preventDefault();
                                                  if (
                                                    enableUpdatePassBtn &&
                                                    !disabledBtn.changePassword
                                                  ) {
                                                    setDisabledBtn((prev) => ({
                                                      ...prev,
                                                      changePassword: true,
                                                    }));
                                                    HandleChangePassword();
                                                  }
                                                }
                                              }}
                                              error={
                                                errorMessages.new_password_confirmation
                                              }
                                            />

                                            <span className="password-eye-icons-password">
                                              {showUpdatePassword ? (
                                                <FontAwesomeIcon
                                                  icon={faEye}
                                                  className="fontassowmicon"
                                                  style={{ color: "#CCCCCC" }}
                                                  onClick={() => {
                                                    setShowUpdatePassword(
                                                      false
                                                    );
                                                  }}
                                                />
                                              ) : (
                                                <FontAwesomeIcon
                                                  className="fontassowmicon"
                                                  icon={faEyeSlash}
                                                  style={{ color: "#CCCCCC" }}
                                                  onClick={() => {
                                                    setShowUpdatePassword(true);
                                                  }}
                                                />
                                              )}
                                            </span>
                                          </div>

                                          {/* Password Requirement Tooltip */}
                                          <div
                                            href="#!"
                                            className="password-requirements"
                                            id="UpdatePasswordRequirements"
                                          >
                                            Password requirements
                                            <div className="signup-tooltip">
                                              <ul>
                                                <li>At least one number</li>
                                                <li>At least 8 characters</li>
                                                <li>
                                                  At least one uppercase letter
                                                </li>
                                                <li>
                                                  At least one special character
                                                </li>
                                              </ul>
                                            </div>
                                          </div>

                                          {/* Update Password Button */}
                                          <div className="updateCancleBtn">
                                            <button
                                              type="button"
                                              className={
                                                enableUpdatePassBtn
                                                  ? "updateyesbutton"
                                                  : "updateyesbutton disabledElement"
                                              }
                                              id={
                                                "profileInfoUpdatePasswordBtn"
                                              }
                                              style={{
                                                // opacity: enableUpdatePassBtn
                                                //   ? "1"
                                                //   : "0.5",
                                                cursor: enableUpdatePassBtn
                                                  ? "pointer"
                                                  : "not-allowed",
                                              }}
                                              onClick={() => {
                                                if (
                                                  enableUpdatePassBtn &&
                                                  !disabledBtn.changePassword
                                                ) {
                                                  setDisabledBtn((prev) => ({
                                                    ...prev,
                                                    changePassword: true,
                                                  }));
                                                  HandleChangePassword();
                                                }
                                              }}
                                            >
                                              {disabledBtn.changePassword ? (
                                                <img
                                                  src={loadingImgPath}
                                                  alt="Loading"
                                                  className="disabledBtn-Loading"
                                                />
                                              ) : (
                                                "Update Password"
                                              )}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        {showFeatures.deleteAccount ? (
                                          <div
                                            className="popup-delete"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                            }}
                                          >
                                            <div
                                              className="Profile_crossbuttondelete"
                                              style={{
                                                cursor: "pointer",
                                                width: "max-content",
                                                position: "relative",
                                                float: "right",
                                              }}
                                              id="closeDeactivateModel"
                                              onClick={() => {
                                                setDeleteAccountPopup(false);
                                                setInputTagDeisable(false);
                                                resetTimer();
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                icon={faXmark}
                                                style={{
                                                  color: "gray",
                                                  width: "18px",
                                                  height: "18px",
                                                }}
                                              />
                                            </div>
                                            <div className="Delete-Box-Container">
                                              <div className="DeleteText-container">
                                                <h1 className="Profile-Delete-heading-tag">
                                                  When you deactivate the
                                                  account
                                                </h1>
                                                <li className="Profile-Delete-line-tag">
                                                  We will miss you.
                                                </li>{" "}
                                                <li className="Profile-Delete-line-tag">
                                                  You won't be able to access
                                                  your Cart.
                                                </li>{" "}
                                                <li className="Profile-Delete-line-tag">
                                                  You won't be able to access
                                                  your Wish list.
                                                </li>{" "}
                                                <li className="Profile-Delete-line-tag">
                                                  You will not get update about
                                                  the latest offeres.
                                                </li>{" "}
                                                <li className="Profile-Delete-line-tag">
                                                  You won't be able to check
                                                  your past Orders.
                                                </li>
                                              </div>
                                              <div className="Delete-Container-Box2">
                                                <h4 className="headingmessage">
                                                  Are you sure you want to
                                                  leave?
                                                </h4>

                                                <div className="select-wrapper">
                                                  <label
                                                    htmlFor="select-input"
                                                    className={`select-label ${
                                                      deleteSelectedValue
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                  >
                                                    Email Address or Phone
                                                    Number
                                                  </label>

                                                  <select
                                                    id="select-input-deactivate-account"
                                                    className="select-Tag"
                                                    value={
                                                      deleteSelectedValue ==
                                                      "Email"
                                                        ? values.email
                                                        : values.Phone
                                                    }
                                                    onChange={(e) => {
                                                      if (
                                                        !disabledBtn.requestOTPDeleteAccount
                                                      ) {
                                                        setDeactivateAccountError(
                                                          ""
                                                        );

                                                        let targetVal =
                                                          e.target.value;

                                                        if (
                                                          targetVal ==
                                                          values.email
                                                        ) {
                                                          setDeleteSelectedValue(
                                                            "Email"
                                                          );
                                                        } else {
                                                          setDeleteSelectedValue(
                                                            "Phone"
                                                          );
                                                        }
                                                      }
                                                    }}
                                                    style={{
                                                      backgroundColor: "white",
                                                      color: "black !important",
                                                    }}
                                                  >
                                                    {values.email && (
                                                      <option
                                                        // value="Email"
                                                        value={values.email}
                                                        style={{
                                                          color:
                                                            "black !important",
                                                        }}
                                                      >
                                                        {values.email}
                                                      </option>
                                                    )}

                                                    {values.Phone && (
                                                      <option
                                                        // value="Phone"
                                                        value={values.Phone}
                                                        style={{
                                                          color:
                                                            "black !important",
                                                        }}
                                                      >
                                                        {values.Phone}
                                                      </option>
                                                    )}
                                                  </select>
                                                  <li className="Profile_info_Delete_error_Message">
                                                    {deactivateAccountError}
                                                  </li>
                                                </div>

                                                <div className="delete-requestotp-container">
                                                  {isActive ? (
                                                    <button className="disabledBtn hover-pointer">
                                                      Request OTP in{" "}
                                                      {formatTime(seconds)}
                                                    </button>
                                                  ) : (
                                                    <button
                                                      className="Request-Otp-Button"
                                                      id="RequestOTPBtn"
                                                      onClick={() => {
                                                        if (
                                                          !disabledBtn.requestOTPDeleteAccount
                                                        ) {
                                                          setDisabledBtn(
                                                            (prev) => ({
                                                              ...prev,
                                                              requestOTPDeleteAccount: true,
                                                            })
                                                          );
                                                          setOtpValidationMessage(
                                                            ""
                                                          );
                                                          sendRequetOtpVerifation();
                                                        }
                                                      }}
                                                    >
                                                      {disabledBtn.requestOTPDeleteAccount ? (
                                                        <img
                                                          src={loadingImgPath}
                                                          alt="Loading"
                                                          className="disabledBtn-Loading"
                                                        />
                                                      ) : (
                                                        "Request OTP"
                                                      )}
                                                    </button>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <>
                                            {showDeleteEditEmailPopup ==
                                            true ? (
                                              <>
                                                <div
                                                  className="popup"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                  }}
                                                >
                                                  <div
                                                    className="crossbutton"
                                                    style={{
                                                      cursor: "pointer",
                                                      width: "max-content",
                                                      position: "relative",
                                                      float: "right",
                                                    }}
                                                    id="closeDeactivateVerifyOTPModel"
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faXmark}
                                                      style={{
                                                        color: "#b6b6b6",
                                                        width: "18px",
                                                        height: "18px",
                                                      }}
                                                      onClick={() => {
                                                        setShowDeleteEditEmailPopup(
                                                          false
                                                        );
                                                        setDeleteAccountPopup(
                                                          false
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                  <h1 className="Profile-Email-verify-otp">
                                                    Verify OTP
                                                  </h1>
                                                  <div className="Profile_DeleteAccount_container">
                                                    <InputControl
                                                      label={<span>Email</span>}
                                                      inputTagId="Email"
                                                      type="text"
                                                      value={values.email}
                                                      readOnly
                                                    />
                                                    <svg
                                                      onClick={() => {
                                                        setShowDeleteEditEmailPopup(
                                                          false
                                                        );
                                                        setShowFeatures(
                                                          (prev) => ({
                                                            ...prev,
                                                            deleteAccount: true,
                                                          })
                                                        );
                                                        setOtpValidationMessage(
                                                          ""
                                                        );

                                                        setDisabledBtn(
                                                          (prev) => ({
                                                            ...prev,
                                                            requestOTPDeleteAccount: false,
                                                          })
                                                        );

                                                        setDeleteAccountPopup(
                                                          true
                                                        );
                                                      }}
                                                      className="Profile_info_Delete_Account_svg"
                                                      width="18"
                                                      height="18"
                                                      viewBox="0 0 12 13"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M2.00432 11.0312C1.63242 11.0312 1.28491 10.9032 1.03494 10.6654C0.717916 10.3666 0.565499 9.91549 0.620369 9.42776L0.845947 7.45243C0.888624 7.08053 1.1142 6.5867 1.37636 6.31845L6.38175 1.02045C7.63157 -0.30253 8.93626 -0.339111 10.2592 0.910712C11.5822 2.16053 11.6188 3.46523 10.369 4.78821L5.3636 10.0862C5.10754 10.3606 4.63199 10.6166 4.26009 10.6776L2.29696 11.0129C2.19332 11.019 2.10187 11.0312 2.00432 11.0312ZM8.33879 0.904615C7.86934 0.904615 7.46086 1.19726 7.04629 1.63622L2.0409 6.94037C1.91896 7.0684 1.77874 7.37324 1.75435 7.55004L1.52878 9.52531C1.50439 9.7265 1.55316 9.89111 1.6629 9.99475C1.77264 10.0984 1.93725 10.135 2.13845 10.1045L4.10158 9.76923C4.27838 9.73875 4.57103 9.58018 4.69296 9.45215L9.69835 4.15415C10.4543 3.34939 10.7287 2.60559 9.62519 1.56915C9.13745 1.09971 8.71678 0.904615 8.33879 0.904615Z"
                                                        fill="#888888"
                                                      />
                                                      <path
                                                        d="M9.19847 5.80701C9.18628 5.80701 9.16799 5.80701 9.15579 5.80701C7.25362 5.61801 5.72336 4.17313 5.43071 2.28315C5.39413 2.03319 5.56484 1.80152 5.81481 1.75884C6.06477 1.72226 6.29644 1.89297 6.33912 2.14293C6.5708 3.61833 7.76575 4.75232 9.25334 4.89864C9.5033 4.92302 9.68621 5.1486 9.66182 5.39853C9.63133 5.63021 9.43014 5.80701 9.19847 5.80701Z"
                                                        fill="#888888"
                                                      />
                                                      <path
                                                        d="M11.4313 13.0004H0.457252C0.207288 13.0004 0 12.7932 0 12.5432C0 12.2932 0.207288 12.0859 0.457252 12.0859H11.4313C11.6813 12.0859 11.8885 12.2932 11.8885 12.5432C11.8885 12.7932 11.6813 13.0004 11.4313 13.0004Z"
                                                        fill="#888888"
                                                      />
                                                    </svg>
                                                  </div>
                                                  <InputControl
                                                    inputTagId="OTP"
                                                    label={
                                                      <span className="inputTagLabel">
                                                        OTP
                                                      </span>
                                                    }
                                                    autoFocus
                                                    name="otp"
                                                    value={otp}
                                                    maxLength={4}
                                                    type="text"
                                                    onKeyPress={(event) => {
                                                      if (
                                                        event.key === "Enter" ||
                                                        event.key === 13
                                                      ) {
                                                        event.preventDefault();
                                                        handelOtpSubmit();
                                                      }
                                                    }}
                                                    onChange={handelOtpChange}
                                                    error={otpValidationMessage}
                                                  />

                                                  <li className="deleteResendButtonemail">
                                                    <button
                                                      style={{
                                                        fontSize: "0.75rem",
                                                        margin: "0px",
                                                        color: "#3366cc",
                                                        cursor: isActive
                                                          ? "default"
                                                          : "pointer",
                                                      }}
                                                      className="tabIndexBtn"
                                                      onClick={() => {
                                                        if (!isActive) {
                                                          resendVerifyOtp();
                                                        }
                                                      }}
                                                      id="resendOTPBtnDeactivateAccount"
                                                    >
                                                      {isActive
                                                        ? `Resend OTP after ${" "}
                                                      ${formatTime(seconds)}`
                                                        : "Resend OTP"}
                                                    </button>
                                                  </li>

                                                  <button
                                                    className="EmailPhoneDeleteverifybutton"
                                                    id="EmailPhoneDeleteverifybutton"
                                                    onClick={() => {
                                                      if (
                                                        !disabledBtn.verifyOTPDeleteAccount
                                                      ) {
                                                        setDisabledBtn(
                                                          (prev) => ({
                                                            ...prev,
                                                            verifyOTPDeleteAccount: true,
                                                          })
                                                        );
                                                        handelOtpSubmit();
                                                      }
                                                    }}
                                                  >
                                                    {disabledBtn.verifyOTPDeleteAccount ? (
                                                      <img
                                                        src={loadingImgPath}
                                                        alt="Loading"
                                                        className="disabledBtn-Loading"
                                                      />
                                                    ) : (
                                                      "Confirm Deactivation"
                                                    )}
                                                  </button>
                                                  <li
                                                    className="letmestaybuttonforemail"
                                                    onClick={() => {
                                                      setShowDeleteEditEmailPopup(
                                                        false
                                                      );
                                                      setDeleteAccountPopup(
                                                        false
                                                      );
                                                    }}
                                                  >
                                                    {" "}
                                                    NO, LET ME STAY!
                                                  </li>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                {showDeleteEditMobilePopup && (
                                                  <>
                                                    <div
                                                      className="popup"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                      }}
                                                    >
                                                      <div
                                                        className="crossbutton"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={faXmark}
                                                          style={{
                                                            color: "#b6b6b6",
                                                            width: "18px",
                                                            height: "18px",
                                                          }}
                                                          onClick={() => {
                                                            setShowDeleteEditMobilePopup(
                                                              false
                                                            );
                                                            setDeleteAccountPopup(
                                                              false
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                      <h1 className="Profile-phone-verify-otp">
                                                        Verify OTP
                                                      </h1>
                                                      <div className="Profile_DeleteAccount_container">
                                                        <InputControl
                                                          inputTagId="MobileNumber"
                                                          label={
                                                            <span>
                                                              Mobile Number
                                                            </span>
                                                          }
                                                          type="text"
                                                          value={values.Phone}
                                                          readOnly
                                                        />

                                                        <svg
                                                          onClick={() => {
                                                            setShowDeleteEditMobilePopup(
                                                              false
                                                            );
                                                            setShowFeatures(
                                                              (prev) => ({
                                                                ...prev,
                                                                deleteAccount: true,
                                                              })
                                                            );

                                                            setOtpValidationMessage(
                                                              ""
                                                            );
                                                            setDeleteAccountPopup(
                                                              true
                                                            );
                                                          }}
                                                          className="Profile_info_Delete_Account_svg"
                                                          width="18"
                                                          height="18"
                                                          viewBox="0 0 12 13"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            d="M2.00432 11.0312C1.63242 11.0312 1.28491 10.9032 1.03494 10.6654C0.717916 10.3666 0.565499 9.91549 0.620369 9.42776L0.845947 7.45243C0.888624 7.08053 1.1142 6.5867 1.37636 6.31845L6.38175 1.02045C7.63157 -0.30253 8.93626 -0.339111 10.2592 0.910712C11.5822 2.16053 11.6188 3.46523 10.369 4.78821L5.3636 10.0862C5.10754 10.3606 4.63199 10.6166 4.26009 10.6776L2.29696 11.0129C2.19332 11.019 2.10187 11.0312 2.00432 11.0312ZM8.33879 0.904615C7.86934 0.904615 7.46086 1.19726 7.04629 1.63622L2.0409 6.94037C1.91896 7.0684 1.77874 7.37324 1.75435 7.55004L1.52878 9.52531C1.50439 9.7265 1.55316 9.89111 1.6629 9.99475C1.77264 10.0984 1.93725 10.135 2.13845 10.1045L4.10158 9.76923C4.27838 9.73875 4.57103 9.58018 4.69296 9.45215L9.69835 4.15415C10.4543 3.34939 10.7287 2.60559 9.62519 1.56915C9.13745 1.09971 8.71678 0.904615 8.33879 0.904615Z"
                                                            fill="#888888"
                                                          />
                                                          <path
                                                            d="M9.19847 5.80701C9.18628 5.80701 9.16799 5.80701 9.15579 5.80701C7.25362 5.61801 5.72336 4.17313 5.43071 2.28315C5.39413 2.03319 5.56484 1.80152 5.81481 1.75884C6.06477 1.72226 6.29644 1.89297 6.33912 2.14293C6.5708 3.61833 7.76575 4.75232 9.25334 4.89864C9.5033 4.92302 9.68621 5.1486 9.66182 5.39853C9.63133 5.63021 9.43014 5.80701 9.19847 5.80701Z"
                                                            fill="#888888"
                                                          />
                                                          <path
                                                            d="M11.4313 13.0004H0.457252C0.207288 13.0004 0 12.7932 0 12.5432C0 12.2932 0.207288 12.0859 0.457252 12.0859H11.4313C11.6813 12.0859 11.8885 12.2932 11.8885 12.5432C11.8885 12.7932 11.6813 13.0004 11.4313 13.0004Z"
                                                            fill="#888888"
                                                          />
                                                        </svg>
                                                      </div>
                                                      <InputControl
                                                        inputTagId="OTP"
                                                        label={
                                                          <span className="inputTagLabel">
                                                            OTP
                                                          </span>
                                                        }
                                                        autoFocus
                                                        value={otp}
                                                        maxLength={4}
                                                        type="text"
                                                        name="otp"
                                                        onKeyPress={(event) => {
                                                          if (
                                                            event.key ===
                                                              "Enter" ||
                                                            event.key === 13
                                                          ) {
                                                            event.preventDefault();
                                                            handelOtpSubmit();
                                                          }
                                                        }}
                                                        onChange={
                                                          handelOtpChange
                                                        }
                                                        error={
                                                          otpValidationMessage
                                                        }
                                                      />
                                                      <li className="deleteResendButtonemail">
                                                        <button
                                                          style={{
                                                            fontSize: "0.75rem",
                                                            margin: "0px",
                                                            color: "#3366cc",
                                                            cursor: isActive
                                                              ? "default"
                                                              : "pointer",
                                                          }}
                                                          className="tabIndexBtn"
                                                          onClick={() => {
                                                            if (!isActive) {
                                                              resendVerifyOtp();
                                                            }
                                                          }}
                                                        >
                                                          {isActive
                                                            ? `Resend OTP after ${" "}
                                                ${formatTime(seconds)}`
                                                            : "Resend OTP"}
                                                        </button>
                                                      </li>

                                                      <button
                                                        className="EmailPhoneDeleteverifybutton"
                                                        onClick={() => {
                                                          if (
                                                            !disabledBtn.verifyOTPDeleteAccount
                                                          ) {
                                                            setDisabledBtn(
                                                              (prev) => ({
                                                                ...prev,
                                                                verifyOTPDeleteAccount: true,
                                                              })
                                                            );
                                                            handelOtpSubmit();
                                                          }
                                                        }}
                                                      >
                                                        {disabledBtn.verifyOTPDeleteAccount ? (
                                                          <img
                                                            src={loadingImgPath}
                                                            alt="Loading"
                                                            className="disabledBtn-Loading"
                                                          />
                                                        ) : (
                                                          "Confirm Deactivation"
                                                        )}
                                                      </button>

                                                      <li
                                                        className="letMeStayButtonphonedelete"
                                                        onClick={() => {
                                                          setShowDeleteEditMobilePopup(
                                                            false
                                                          );
                                                          setDeleteAccountPopup(
                                                            false
                                                          );
                                                        }}
                                                      >
                                                        {" "}
                                                        NO, LET ME STAY!
                                                      </li>
                                                    </div>
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileInfo;
